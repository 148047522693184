import { googleAnalyticsService } from '../../service/google-analytics-service'
import { messageService } from '../../service/message-service'
import { metaPixelService } from '../../service/meta-pixel-service'
import { mixpanelService } from '../../service/mixpanel-service'
import { cacheUtil } from '../../util/cache-util'
import { EventBusActionType, EventBusEntityType, EventBusMessage, eventBusUtil } from '../../util/event-bus-util'
import { logger } from '../../util/logger'
import { Subscription } from 'rxjs'

export class EventBusController {
  protected _subscriptions: Subscription[] = []

  protected _subscribe(
    params: { entities?: EventBusEntityType[]; actions?: EventBusActionType[] },
    cb: (eventMessage: EventBusMessage) => Promise<void>,
  ): void {
    this._subscriptions.push(eventBusUtil().subscribe(params, cb))
  }

  public unsubscribe(): void {
    this._subscriptions.forEach((s) => s.unsubscribe())
    this._subscriptions = []
  }

  public register(): void {
    this._subscriptionSet()
    this._subscribeToEmailNotifications()
    this._unsubscribeFromEmailNotifications()
    this._checkoutInitiated()
    this._paywallOpened()
    this._videoPlaybackStarted()
    this._videoPlaybackEnded()
  }

  public _subscriptionSet(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.SUBSCRIPTION],
        actions: [EventBusActionType.CREATE],
      },
      async (eventMessage: EventBusMessage) => {
        try {
          googleAnalyticsService.sendSubscriptionSet({ message: eventMessage }).catch((error) => logger.error(error))
        } catch (error) {
          logger.error(error)
        }
        try {
          metaPixelService.sendSubscriptionSet({ message: eventMessage }).catch((error) => logger.error(error))
        } catch (error) {
          logger.error(error)
        }
        try {
          mixpanelService.sendSubscriptionSet({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  public _subscribeToEmailNotifications(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.MEMBERSHIP],
        actions: [EventBusActionType.CREATE],
      },
      async (_: EventBusMessage) => {
        try {
          messageService.subscribeToEmailNotifications()
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  public _unsubscribeFromEmailNotifications(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.MEMBERSHIP],
        actions: [EventBusActionType.REMOVE],
      },
      async (_: EventBusMessage) => {
        try {
          messageService.unsubscribeFromEmailNotifications()
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  protected _checkoutInitiated(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.CHECKOUT],
        actions: [EventBusActionType.CREATE],
      },
      async (eventMessage: EventBusMessage) => {
        try {
          googleAnalyticsService.sendCheckoutInitiated({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          metaPixelService.sendCheckoutInitiated({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          mixpanelService.sendCheckoutInitiated({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  protected _paywallOpened(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.PAYWALL],
        actions: [EventBusActionType.CREATE],
      },
      async (eventMessage: EventBusMessage) => {
        try {
          mixpanelService.paywallOpened({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  protected _videoPlaybackStarted(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.VIDEO_PLAYBACK],
        actions: [EventBusActionType.CREATE],
      },
      async (eventMessage: EventBusMessage) => {
        try {
          googleAnalyticsService.sendVideoPlaybackStarted({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          metaPixelService.sendVideoPlaybackStarted({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          mixpanelService.sendVideoPlaybackStarted({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }

  protected _videoPlaybackEnded(): void {
    this._subscribe(
      {
        entities: [EventBusEntityType.VIDEO_PLAYBACK],
        actions: [EventBusActionType.REMOVE],
      },
      async (eventMessage: EventBusMessage) => {
        try {
          googleAnalyticsService.sendVideoPlaybackEnded({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          metaPixelService.sendVideoPlaybackEnded({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
        try {
          mixpanelService.sendVideoPlaybackEnded({ message: eventMessage })
        } catch (error) {
          logger.error(error)
        }
      },
    )
  }
}

export const eventBusController = cacheUtil.singleton((): EventBusController => new EventBusController())

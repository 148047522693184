import { Price, Workout } from '../dao/core-dao'
import { EventBusMessage } from '../util/event-bus-util'
import { coreService } from './core-service'

export enum GoogleAnalyticsEventCategory {
  CHECKOUT = 'checkout',
  PURCHASE = 'purchase',
  WORKOUT_VIDEO_START = 'workout_video_start',
  WORKOUT_VIDEO_FINISH = 'workout_video_finish',
}

export enum GoogleAnalyticsEventAction {
  SUCCESS = 'success',
  INITIATED = 'initiated',
}

export type GoogleAnalyticsProperties = {
  category?: GoogleAnalyticsEventCategory
  action?: GoogleAnalyticsEventAction | string
  label?: string[]
}

export enum GoogleAnalyticsMethod {
  EVENT = 'event',
  CONFIG = 'config',
}

export enum GoogleAnalyticsEvent {
  LEAD = 'Lead',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PURCHASE = 'Purchase',
  WORKOUT_VIDEO_START = 'WorkoutVideoStarted',
  WORKOUT_VIDEO_FINISH = 'WorkoutVideoFinished',
}

export type GoogleAnalyticsMessage = {
  methodName: GoogleAnalyticsMethod
  eventName?: GoogleAnalyticsEvent
  properties?: GoogleAnalyticsProperties
  callback?: (response: any) => any
}

export const googleAnalyticsService = {
  _push: (params: { googleAnalyticsMessage: GoogleAnalyticsMessage }): void => {
    const { googleAnalyticsMessage } = params
    const { methodName, eventName, properties } = googleAnalyticsMessage
    const payload = [methodName, eventName, properties].filter(Boolean)
    window.gtag(...payload)
  },

  sendCheckoutInitiated: (params: { message: EventBusMessage }): void => {
    const { message } = params
    const { price }: { price: Price } = message.meta ?? {}
    const label = [price?.product.name].filter(Boolean)

    const googleAnalyticsMessage = {
      methodName: GoogleAnalyticsMethod.EVENT,
      eventName: GoogleAnalyticsEvent.INITIATE_CHECKOUT,
      properties: {
        category: GoogleAnalyticsEventCategory.CHECKOUT,
        action: GoogleAnalyticsEventAction.INITIATED,
        label: label,
      },
    }
    googleAnalyticsService._push({ googleAnalyticsMessage })
  },

  sendSubscriptionSet: async (_: { message: EventBusMessage }): Promise<void> => {
    const subscriptionItems = await coreService.getMyActiveOrTrialingSubscriptionItems()
    const subscriptionItem = subscriptionItems[0]

    if (subscriptionItem) {
      const { amount } = subscriptionItem.plan
      const { name: productName } = subscriptionItem.product

      const label = [productName, amount.toString()].filter(Boolean)

      const googleAnalyticsMessage = {
        methodName: GoogleAnalyticsMethod.EVENT,
        eventName: GoogleAnalyticsEvent.PURCHASE,
        properties: {
          category: GoogleAnalyticsEventCategory.PURCHASE,
          action: productName,
          label: label,
        },
      }
      googleAnalyticsService._push({ googleAnalyticsMessage })
    }
  },

  sendVideoPlaybackStarted: (params: { message: EventBusMessage }): void => {
    const { message } = params
    const { workout }: { workout: Workout } = message.meta
    const trainers = workout.trainers.map((t) => `${t.firstName} ${t.lastName}`).join(', ')
    const workoutTitle = workout.title

    const googleAnalyticsMessage = {
      methodName: GoogleAnalyticsMethod.EVENT,
      eventName: GoogleAnalyticsEvent.WORKOUT_VIDEO_START,
      properties: {
        category: GoogleAnalyticsEventCategory.WORKOUT_VIDEO_START,
        action: trainers,
        label: [workoutTitle],
      },
    }

    googleAnalyticsService._push({ googleAnalyticsMessage })
  },

  sendVideoPlaybackEnded: (params: { message: EventBusMessage }): void => {
    const { message } = params
    const { workout }: { workout: Workout } = message.meta
    const trainers = workout.trainers.map((t) => `${t.firstName} ${t.lastName}`).join(', ')
    const workoutTitle = workout.title

    const googleAnalyticsMessage = {
      methodName: GoogleAnalyticsMethod.EVENT,
      eventName: GoogleAnalyticsEvent.WORKOUT_VIDEO_FINISH,
      properties: {
        category: GoogleAnalyticsEventCategory.WORKOUT_VIDEO_FINISH,
        action: trainers,
        label: [workoutTitle],
      },
    }
    googleAnalyticsService._push({ googleAnalyticsMessage })
  },
}

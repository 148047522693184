import { ProfileNav } from '../../component/nav/profile-nav'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'
import { ProfileAwardsScreen } from 'src/screen/profile/awards/profile-awards-screen'
import { ProfileOverviewScreen } from 'src/screen/profile/overview/profile-overview-screen'
import { ProfilePersonalInfoScreen } from 'src/screen/profile/personal-info/profile-personal-info-screen'
import { ProfileWorkoutsScreen } from 'src/screen/profile/workouts/profile-workouts-screen'

export const ProfileScreen = (): React.ReactElement => {
  return (
    <PageContainer>
      <ProfileNav />
      <Switch>
        <Route exact path="/profile/overview" component={ProfileOverviewScreen} />
        <Route exact path="/profile/workouts" component={ProfileWorkoutsScreen} />
        <Route exact path="/profile/awards" component={ProfileAwardsScreen} />
        <Route exact path="/profile/personal" component={ProfilePersonalInfoScreen} />
        <Redirect to="/profile/overview" />
      </Switch>
    </PageContainer>
  )
}

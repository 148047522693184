import { authService } from '../../service/auth-service'
import { AxiosInstance } from 'axios'

export const logoutOnErrorInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      await authService.logout()
      return Promise.reject(error)
    },
  )
}

import { ProgrammeIntensityPill } from '../programme-intensity-pill/programme-intensity-pill'
import { InfoTabHeaderWithLock } from './info-tab-header-with-lock'
import { ProgrammeAvailability } from './programme-availability'
import { WorkoutCard } from './programme-workout-card'
import styles from './programme-workouts-container.module.scss'
import { RouterHistory } from '@sentry/react/dist/reactrouter'
import { Location } from '@sentry/react/dist/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CompletedProgrammeWorkout, HustlerProgramme, InfoTabItem, Intensity, ProgrammeWorkout } from 'src/dao/core-dao'
import { useProgrammeWorkoutsAvailability } from 'src/hook/use-programme-workouts-availability'
import { timeUtil } from 'src/util/time-util'
import { selectedWeekWorkouts } from 'src/util/week-workouts'

interface WorkoutsContainerProps {
  selectWorkout: (programmeWorkout: ProgrammeWorkout) => void
  programmeWorkouts: ProgrammeWorkout[]
  completedProgrammeWorkouts: CompletedProgrammeWorkout[]
  hustlerProgramme: HustlerProgramme
  programmeIntensities: Intensity[]
}

const getWeekFromURL = (location: Location): number | undefined => {
  const queryParams = new URLSearchParams(location.search)
  const weekParam = queryParams.get('week')
  if (weekParam) {
    const weekNumber = parseInt(weekParam, 10)
    if (!isNaN(weekNumber) && weekNumber > 0) {
      return weekNumber
    }
  }
  return
}

const setWeekInURL = (history: RouterHistory, location: Location, weekNumber: number): void => {
  const queryParams = new URLSearchParams(location.search)
  queryParams.set('week', weekNumber.toString())
  history.replace(`${location.pathname}?${queryParams.toString()}`)
}

export const WorkoutsContainer = ({
  selectWorkout,
  hustlerProgramme,
  programmeWorkouts,
  completedProgrammeWorkouts,
  programmeIntensities,
}: WorkoutsContainerProps): React.ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const { areProgrammeWorkoutsInWeekLocked, isWeekLocked } = useProgrammeWorkoutsAvailability(hustlerProgramme.programme.id)
  const startedAt = parseInt(hustlerProgramme.startedAt)
  const initialWeekNumber = getWeekFromURL(location)
  const initialWeek = hustlerProgramme.programme.weekDescription.find((w) => w.order === initialWeekNumber) ||
    hustlerProgramme.programme.weekDescription[0] || { description: '', order: 1, title: '' }
  const [selectedWeek, setSelectedWeek] = useState<InfoTabItem>(initialWeek)
  const selectedWorkouts = selectedWeekWorkouts(selectedWeek, programmeWorkouts)

  useEffect(() => {
    const weekNumber = getWeekFromURL(location)
    if (weekNumber && weekNumber !== selectedWeek.order) {
      const weekToSet = hustlerProgramme.programme.weekDescription.find((w) => w.order === weekNumber) || selectedWeek
      setSelectedWeek(weekToSet)
    }
  }, [location, hustlerProgramme.programme.weekDescription, selectedWeek])

  useEffect(() => {
    if (selectedWeek.order !== getWeekFromURL(location)) {
      setWeekInURL(history, location, selectedWeek.order)
    }
  }, [selectedWeek, history, location])

  const handleWeekChange = (newWeek: InfoTabItem): void => {
    setSelectedWeek(newWeek)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('week', newWeek.toString())
    history.replace(`${location.pathname}?${queryParams.toString()}`)
  }

  const getIntensity = (intensityId: string): Intensity | undefined => {
    return programmeIntensities.find((i) => i.id === intensityId)
  }

  const isProgrammeWorkoutCompleted = useCallback(
    (workoutId: string): boolean => {
      if (!completedProgrammeWorkouts) return false
      return completedProgrammeWorkouts
        .map((cpw) => cpw.completedWorkout.workout?.id)
        .filter(Boolean)
        .map((id) => id!)
        .includes(workoutId)
    },
    [completedProgrammeWorkouts],
  )

  const programCompletedText = useCallback((): string => {
    if (!selectedWorkouts) return '0/0'
    const completedCount = selectedWorkouts.filter((pw) =>
      completedProgrammeWorkouts?.map((cpw) => cpw.programmeWorkout.id).includes(pw.id),
    ).length
    return `${completedCount}/${selectedWorkouts.length}`
  }, [completedProgrammeWorkouts, selectedWorkouts])

  return (
    <div className={styles.container}>
      {hustlerProgramme?.programme?.weekDescription && (
        <InfoTabHeaderWithLock
          items={hustlerProgramme.programme.weekDescription}
          selectedItem={selectedWeek}
          onClick={handleWeekChange}
          isTabLocked={(item) => isWeekLocked(item.order)}
        />
      )}
      <div className={styles.workoutsDetail}>
        <div className={styles.completedWorkouts}>
          <span>{programCompletedText()}</span>
          <span>completed</span>
        </div>
        <ProgrammeAvailability
          className={styles.weekDate}
          startedAt={timeUtil.addWeeks(startedAt, selectedWeek?.order - 1).getTime()}
          numberOfWeeks={1}
        />
        <div className={styles.programmeIntensityPillContainer}>
          <ProgrammeIntensityPill intensity={getIntensity(hustlerProgramme.programme.intensityId)} />
        </div>
      </div>
      {programmeWorkouts && (
        <>
          {selectedWorkouts.map((item) => (
            <WorkoutCard
              key={item.id}
              workout={item.workout}
              isLocked={areProgrammeWorkoutsInWeekLocked(selectedWeek.order)}
              isCompleted={isProgrammeWorkoutCompleted(item.workout?.id)}
              selectWorkout={() => selectWorkout(item)}
            />
          ))}
        </>
      )}
    </div>
  )
}

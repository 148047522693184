import { errorUtil } from '../util/error-util'
import { HttpResponse } from '../util/http-util'
import { messageDaoFactory } from '@grndhouse/react-common'

const dao = messageDaoFactory({
  onError: async (error: Error) => {
    errorUtil.handleHttpError(error)
  },
})
export const messageDao = {
  ...dao,
  getMyTags: async (): Promise<string[]> => {
    const result = await dao._http.get<HttpResponse<string>>('/drip/me/tags')
    return result.data.data
  },
  setMyOptIn: async (params: { isOptingIn: boolean }): Promise<void> => {
    const { isOptingIn } = params
    await dao._http.put('/drip/me/opt-in', { isOptingIn })
  },
  getMyOptIn: async (): Promise<boolean> => {
    const result = await dao._http.get<HttpResponse<{ isOptingIn: boolean }>>('/drip/me/opt-in')
    return result.data.data[0].isOptingIn
  },
}

import { HttpResponse } from '../../util/http-util'
import { InterceptorFunction } from '../axios-dao-factory'
import { AxiosError, AxiosInstance } from 'axios'

export const exposeErrorInterceptor =
  ({ callback }: { callback: (error: Error) => Promise<void> }): InterceptorFunction =>
  (axiosInstance: AxiosInstance): void => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<HttpResponse<Error>> & { config?: { skipErrorInterceptor?: boolean } }) => {
        if (error.response && error.response.data && error.response.data.data && error.response.data.data[0]) {
          if (!error?.config?.skipErrorInterceptor) await callback(error.response.data.data[0])
          return Promise.reject(error.response.data.data[0])
        } else if (error.response && error.response.data && error.response.data instanceof ArrayBuffer) {
          if (!error?.config?.skipErrorInterceptor) {
            await callback(JSON.parse(Buffer.from(error.response.data).toString('utf-8')).data[0])
          }
        }
        return Promise.reject(error)
      },
    )
  }

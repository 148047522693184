import { TrainerProfile } from '../../component/trainer/trainer-profile'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'

export const TrainerScreen = (): React.ReactElement => {
  const { trainerId } = useParams<{ trainerId: string }>()

  return (
    <PageContainer>
      <TrainerProfile trainerId={trainerId!} />
    </PageContainer>
  )
}

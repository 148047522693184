import { ImagePublicFileId } from '../image/image-public-file-id'
import { ProgrammeDurationIntensityInfo } from '../programmes/programme-duration-intensity-info'
import styles from './programme-card.module.scss'
import React from 'react'
import { Intensity, ProgrammeDuration } from 'src/dao/core-dao'

export const ProgrammeCard = ({
  title,
  intensity,
  image,
  programmeDuration,
  onClick,
}: {
  title: string
  intensity?: Intensity
  image: string
  programmeDuration?: ProgrammeDuration
  onClick: () => void
}): React.ReactElement => {
  return (
    <>
      <div className={styles.programmeCardContainer} onClick={onClick}>
        <div className={styles.programmeCardImageContainer}>
          <ImagePublicFileId fileId={image} className={styles.programmeCardImage} />
        </div>
        <div className={`${styles.programmeCardContent} pl-2 pr-2`}>
          <p className={styles.programmeCardTitle}>{title}</p>
          <div>
            <ProgrammeDurationIntensityInfo duration={programmeDuration} intensity={intensity} />
          </div>
        </div>
      </div>
    </>
  )
}

import { coreService } from '../../service/core-service'
import { CarouselSection } from '../carousel-section/carousel-section'
import { ForwardArrowLink } from '../forward-arrow-link/forward-arrow-link'
import style from './popular-workouts.module.scss'
import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Workout } from 'src/dao/core-dao'

export const PopularWorkouts = (): React.ReactElement => {
  const [popularWorkouts, setPopularWorkouts] = useState<Workout[]>([])

  useEffect(() => {
    coreService.getWorkouts({ preset: 'popular' }).then((response) => {
      setPopularWorkouts(response)
    })
  }, [])

  return (
    <Container className={style.PopularWorkoutsContainer}>
      <Row className="d-flex justify-content-between align-items-center">
        <h2 className={style.title}>Popular Workouts</h2>
        <ForwardArrowLink text="See All" link="/workouts-preset/popular" />
      </Row>
      <Row>
        <CarouselSection workoutsList={popularWorkouts} />
      </Row>
    </Container>
  )
}

import styles from './workout-completed-checkmark.module.scss'
import React from 'react'
import { ProgrammeDayComplete } from 'src/component/util/images'

interface WorkoutCompletedCheckmarkProps {
  isVisible: boolean
}

export const WorkoutCompletedCheckmark = ({ isVisible }: WorkoutCompletedCheckmarkProps): React.ReactElement => {
  if (!isVisible) return <></>
  return (
    <div className={styles.container}>
      <span>Completed</span>
      <ProgrammeDayComplete width={11} height={11} />
    </div>
  )
}

import jwtDecode from 'jwt-decode'

export type JWTPayloadUser = {
  id: string
  name: string
  email: string
  phoneNumber?: string
  isEmailVerified: boolean
  isPhoneNumberVerified: boolean
}

export type JWTPayload = {
  user: JWTPayloadUser
  permissions: string[]
  modulePermissions: string[]
  meta: {
    legalAgreementVerified: boolean
  }
}

export const jwtService = {
  // TODO we should add token verification logic here, and we should use public key that we will get from auth service
  decode: (token: string): JWTPayload => {
    try {
      return jwtDecode(token) as JWTPayload
    } catch (err) {
      throw new Error('Failed to decode token')
    }
  },
}

import React from 'react'
import { timeUtil } from 'src/util/time-util'

interface ProgrammeAvailabilityProps {
  className?: any
  startedAt: number
  numberOfWeeks: number
}

export const ProgrammeAvailability = ({
  className,
  startedAt,
  numberOfWeeks,
}: ProgrammeAvailabilityProps): React.ReactElement => {
  return (
    <div className={className}>
      {timeUtil.dateToDateFormatWithShortMonth(startedAt)}
      {' - '}
      {timeUtil.dateToDateFormatWithShortMonth(timeUtil.addDays(timeUtil.addWeeks(startedAt, numberOfWeeks), -1))}
    </div>
  )
}

import { coreService } from '../../service/core-service'
import { ExpressWorkoutHomeCardList } from '../express-workout-home-card-list/express-workout-home-card-list'
import { ForwardArrowLink } from '../forward-arrow-link/forward-arrow-link'
import style from './express-workouts-home-section.module.scss'
import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Workout } from 'src/dao/core-dao'

export const ExpressWorkoutsSection = (): React.ReactElement => {
  const [workouts, setWorkouts] = useState<Workout[]>([])

  useEffect(() => {
    coreService.getWorkouts({ preset: 'trending_express' }).then((workouts) => {
      setWorkouts(workouts)
    })
  }, [])

  if (!workouts || workouts.length < 1) return <p>Loading...</p>

  return (
    <Container className={style.ExpressWorkoutsContainer}>
      <Row className="d-flex justify-content-between align-items-center">
        <h2 className={style.title}>Trending Express Workouts</h2>
        <ForwardArrowLink text="See All" link="/workouts-preset/trending_express" />
      </Row>
      <ExpressWorkoutHomeCardList workouts={workouts} />
    </Container>
  )
}

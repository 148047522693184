import GRATITUDE_IMAGE from '../../../asset/image/grndhouse-gratitude.jpg'
import PROFILE_COMMUNITY_IMAGE from '../../../asset/image/profile/profile-community.jpg'
import styles from './profile-overview-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { ProfileStatsItem } from 'src/component/profile/profile-stats-item'
import { ProgrammeCard } from 'src/component/programme-card/programme-card'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { ProfileArrowSvgIcon } from 'src/component/util/images'
import { WorkoutCarouselCard } from 'src/component/workout-carousel-card/workout-carousel-card'
import { HustlerProgramme, Intensity, Programme, ProgrammeDuration, Workout, WorkoutStats } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'
import { programmeUtil } from 'src/util/programme-util'
import { timeUtil } from 'src/util/time-util'

export const ProfileOverviewScreen = (): React.ReactElement => {
  const [activeProgrammes, setActiveProgrammes] = useState<HustlerProgramme[]>()
  const [programmeIntensities, setProgrammeIntensities] = useState<Intensity[]>([])
  const [durations, setDurations] = useState<Map<string, ProgrammeDuration>>(new Map())
  const [programmes, setProgrammes] = useState<Programme[]>()
  const [myCurrentWorkoutStats, setMyCurrentWorkoutsStats] = useState<WorkoutStats[]>([])
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)
  const [favoriteWorkouts, setFavoriteWorkouts] = useState<Workout[]>()
  const history = useHistory()

  useEffect(() => {
    coreService.getMyStartedProgrammes().then((response) => {
      setActiveProgrammes(response)
    })
    coreService.listProgrammeIntensities().then((response) => {
      setProgrammeIntensities(response)
    })
    coreService.getProgrammes().then((response) => {
      setProgrammes(response)
    })
    coreService.getMyCurrentMonthWorkoutStats().then((response) => {
      setMyCurrentWorkoutsStats(response)
    })
  }, [])

  useEffect(() => {
    if (!programmes?.length) return

    programmeUtil.getProgrammeDurations(programmes).then((durationResults) => {
      setDurations(programmeUtil.programmeIdDurationsToMap(durationResults))
    })
  }, [programmes])

  useEffect(() => {
    coreService.getFavoriteWorkouts().then((response) => {
      const favorites = response.map(({ workout }) => workout)
      setFavoriteWorkouts(favorites)
    })
  }, [selectedWorkout])

  const getIntensity = (intensityId: string): Intensity | undefined => {
    return programmeIntensities.find((i) => i.id === intensityId)
  }

  const selectProgramme = (id: string): void => {
    history.push(`/programmes/${id}`)
    return
  }

  const getDuration = (programmeId: string | undefined): ProgrammeDuration | undefined => {
    if (!programmeId) {
      return undefined
    }
    return durations.get(programmeId)
  }

  const currentMonth = timeUtil.dateToMonthYear(new Date())

  return (
    <div className={styles.container}>
      <div className={styles.profileStatsContainer}>
        <h2 className={`mt-5 ${styles.title}`}>{currentMonth}</h2>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <ProfileStatsItem statsCount={myCurrentWorkoutStats[0]?.completedWorkoutsCount ?? 0} statsType="workouts" />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <ProfileStatsItem statsCount={myCurrentWorkoutStats[0]?.totalCalories ?? 0} statsType="calories" />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <ProfileStatsItem statsCount={myCurrentWorkoutStats[0]?.totalHustle ?? 0} statsType="hustle" />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <ProfileStatsItem statsCount={myCurrentWorkoutStats[0]?.awardsCount ?? 0} statsType="awards" />
          </Col>
        </Row>
      </div>
      <div className={styles.profileFavoritesContainer}>
        <div className={styles.titleContainer}>
          <h2 className={`mt-5 ${styles.title}`}>Favourites</h2>
          {favoriteWorkouts && favoriteWorkouts.length > 3 && (
            <Link to="/favorite-workouts">
              See All <ProfileArrowSvgIcon />
            </Link>
          )}
        </div>
        <Row>
          {favoriteWorkouts ? (
            <FavoriteWorkoutsSection favoriteWorkouts={favoriteWorkouts} selectWorkout={setSelectedWorkout} />
          ) : (
            <p className="pl-3 mb-5">Loading</p>
          )}
          <WorkoutPopup
            setOnClose={() => setSelectedWorkout(undefined)}
            selectedWorkout={selectedWorkout}
            startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout.id}` : undefined}
          />
        </Row>
      </div>
      <div>
        <h2 className={`mt-5 ${styles.title}`}>Active programmes</h2>
        <Row>
          {activeProgrammes ? (
            <ActivatedProgrammesSection
              activeProgrammes={activeProgrammes}
              getIntensity={getIntensity}
              getDuration={getDuration}
              selectProgramme={selectProgramme}
            />
          ) : (
            <p className="pl-3 mb-5">Loading</p>
          )}
        </Row>
      </div>
      <div className={styles.connectContainer}>
        <Image src={PROFILE_COMMUNITY_IMAGE} alt="" className={styles.connectContainerImage} />
        <div>
          <h3>
            Working out is
            <br />
            better together
          </h3>
          <a
            href="https://m.facebook.com/groups/1516167752096283/?multi_permalinks=1528443200868738%2C[…]&notif_t=group_activity&notif_id=1640426760963987&ref=m_notif"
            target="_blank"
            className={styles.connectBtn}
            rel="noreferrer"
          >
            Connect to Facebook
          </a>
        </div>
      </div>
      <div className={`${styles.referralGratitudeContainer} mb-5`}>
        <div className={`${styles.connectContainer} mt-0 mb-0`}>
          <Image src={GRATITUDE_IMAGE} alt="Grndhouse Gratitude" className={styles.connectContainerImage} />
          <a href="https://www.grndhouse.com/benefits" target="_blank" className={styles.connectBtn} rel="noreferrer">
            Grndhouse gratitude
          </a>
        </div>
      </div>
    </div>
  )
}

const FavoriteWorkoutsSection = ({
  favoriteWorkouts,
  selectWorkout,
}: {
  favoriteWorkouts: Workout[]
  selectWorkout?: (workout: Workout) => void
}): React.ReactElement => {
  if (favoriteWorkouts.length === 0) {
    return <span className={`${styles.notFound} pl-3 mb-5`}>Currently there are no favorite workouts</span>
  }

  return (
    <>
      {favoriteWorkouts?.slice(0, 3).map((workout: Workout) => (
        <Col key={workout.id} sm={12} lg={6} xl={4}>
          <WorkoutCarouselCard workout={workout} selectWorkout={selectWorkout} showPopup={true} isNew={false} hideDate={true} />
        </Col>
      ))}
    </>
  )
}

const ActivatedProgrammesSection = ({
  activeProgrammes,
  getIntensity,
  getDuration,
  selectProgramme,
}: {
  activeProgrammes: HustlerProgramme[]
  getIntensity: (intensityId: string) => Intensity | undefined
  selectProgramme: (id: string) => void
  getDuration: (id: string) => ProgrammeDuration | undefined
}): React.ReactElement => {
  if (activeProgrammes.length === 0) {
    return <span className={`${styles.notFound} pl-3 mb-5`}>Currently there are no active programmes</span>
  }

  return (
    <>
      {activeProgrammes.map((p) => (
        <Col key={p.id} sm={12} lg={6} xl={4}>
          <div className="d-flex justify-content-center py-2">
            <ProgrammeCard
              title={p.programme.title}
              intensity={getIntensity(p.programme.intensityId)}
              image={p.programme.imagePublicFileId}
              programmeDuration={getDuration(p.programme.id)}
              onClick={() => selectProgramme(p.programme.id)}
            />
          </div>
        </Col>
      ))}
    </>
  )
}

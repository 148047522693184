import { LockedSvgIcon } from '../util/images'
import { ProgrammeAvailability } from './programme-availability'
import { ProgrammeWeekPill } from './programme-week-pill'
import styles from './programme-week-section.module.scss'
import React from 'react'
import { HustlerProgramme, ProgrammeWorkout } from 'src/dao/core-dao'
import { timeUtil } from 'src/util/time-util'

interface ProgrammeWeekSectionProps {
  onWeek: number
  hustlerProgramme: HustlerProgramme
  workouts: ProgrammeWorkout[]
  isLocked: boolean
}

export const ProgrammeWeekSection = ({
  onWeek,
  hustlerProgramme,
  workouts,
  isLocked,
}: ProgrammeWeekSectionProps): React.ReactElement => {
  const startedAt = parseInt(hustlerProgramme?.startedAt.toString())

  return (
    <div className={styles.container}>
      <div className={styles.lockedContainer}>{isLocked ? <LockedSvgIcon width={13} height={16} /> : <></>}</div>
      <div className={styles.weekInfoContainer}>
        <div className={styles.weekHeader}>
          <h4 className={`${styles.weekTitle} ${isLocked && styles.weekTitleLocked}`}>Week {onWeek}</h4>
          <ProgrammeAvailability
            className={`${styles.weekDate} ${isLocked && styles.weekDateLocked}`}
            startedAt={timeUtil.addWeeks(startedAt, onWeek - 1).getTime()}
            numberOfWeeks={1}
          />
        </div>
        {isLocked ? (
          <></>
        ) : (
          <div className={styles.weekPills}>
            {workouts.map((w: ProgrammeWorkout) => {
              return <ProgrammeWeekPill order={w.order} isCompleted={w.isCompleted} key={w.order} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

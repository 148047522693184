import { Gender, HeightUnit, Hustler, HustlerProfile, WeightUnit } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { logger } from '../../util/logger'
import { timeUtil } from '../../util/time-util'
import { GenderEnumOptions } from '../enum-option/gender-enum-options'
import { HeightUnitEnumOptions } from '../enum-option/height-unit-enum-options'
import { WeightUnitEnumOptions } from '../enum-option/weight-unit-enum-options'
import { ManageEmailNotifications } from '../user/manage-email-notifications'
import { ErrorMessageHint } from '../util/error-message-hint'
import { OverlaySpinner } from '../util/spinner/overlay-spinner'
import styles from './personal-info-form.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

export const updateHustlerProfileSchema = Joi.object<HustlerProfile>().keys({
  gender: Joi.string()
    .valid(...Object.values(Gender), null)
    .empty('')
    .default(null),
  dateOfBirth: Joi.date().iso().empty('').allow(null).default(null).label('date of birth'),
  height: Joi.object({
    value: Joi.number().positive().allow('', null).empty('').default(null).label('Height'),
    unit: Joi.string()
      .valid(...Object.values(HeightUnit), null)
      .empty('')
      .default(null)
      .when('value', { not: null, then: Joi.required() })
      .label('height unit'),
  }).and('value', 'unit'),
  weight: Joi.object({
    value: Joi.number().positive().allow('', null).empty('').default(null).label('Weight'),
    unit: Joi.string()
      .valid(...Object.values(WeightUnit), null)
      .empty('')
      .default(null)
      .when('value', { not: null, then: Joi.required() })
      .label('weight unit'),
  }).and('value', 'unit'),
  profilePhotoFileId: Joi.string(),
})

type FormValues = {
  gender?: Gender | null
  dateOfBirth?: Date | null
  weight?: { value: number | null; unit: WeightUnit | null } | null | any // TODO added any because useForm is complaining on error.weight because of `| null` which is needed to remove value from database
  height?: { value: number | null; unit: HeightUnit | null } | null | any
}

export const PersonalInfoForm = (props: { hustler: Hustler; onSave?: () => void }): React.ReactElement => {
  const { hustler, onSave } = props
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    register,
    handleSubmit,
    control,
    reset: resetFormData,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: joiResolver(updateHustlerProfileSchema),
    defaultValues: {
      gender: null,
      dateOfBirth: null,
      weight: {
        value: null,
        unit: null,
      },
      height: {
        value: null,
        unit: null,
      },
    },
  })

  useEffect(() => {
    if (!hustler?.profile) return
    const { gender, dateOfBirth, height, weight } = hustler.profile

    resetFormData({
      ...(gender && { gender }),
      ...(dateOfBirth && { dateOfBirth: timeUtil.stringToDate(dateOfBirth.toString()) }),
      ...(height && { height: { ...height } }),
      ...(weight && { weight: { ...weight } }),
    })
  }, [hustler])

  const onSubmit = async (data: FormValues): Promise<void> => {
    try {
      if (setIsLoading) setIsLoading(true)
      setErrorMessage(undefined)
      const { gender, height, weight, dateOfBirth } = data
      await coreService.updateMe({
        profile: {
          gender,
          height: null,
          ...(height?.value && height?.unit && { height }),
          weight: null,
          ...(weight?.value && weight?.unit && { weight }),
          dateOfBirth,
        },
      })
      if (onSave) onSave()
    } catch (err: any) {
      logger.error(err)
      setErrorMessage(err.message)
    } finally {
      if (setIsLoading) setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={`pt-3 mt-4 ${styles.container}`}>
      <Form.Group className={`m-0 pb-0 ${styles.personalFormGroupContainer}`}>
        <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.personalFormGroup}`}>
          <Form.Label column sm="12" className={`p-0 mt-0 ${styles.personalFormGroupLabel}`}>
            Gender
          </Form.Label>
          <Col sm="12" className={styles.personalFormGroupCol}>
            <Form.Control as="select" {...register('gender')} className={`${errors.gender ? 'is-invalid' : ''}`}>
              <GenderEnumOptions />
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.gender && <p>{errors.gender.message}</p>}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.personalFormGroup}`}>
          <Form.Label column sm="12" className={`p-0 mt-0 ${styles.personalFormGroupLabel}`}>
            Date of Birth
          </Form.Label>
          <Col sm="12" className={styles.personalFormGroupCol}>
            <Controller
              control={control}
              name="dateOfBirth"
              render={(props: any) => {
                let value = props.field.value
                if (typeof value === 'string') {
                  value = Date.parse(value)
                  value = new Date(value)
                }
                return (
                  <DatePicker
                    {...props}
                    onChange={(v) => {
                      props.field.onChange(v instanceof Date ? timeUtil.dateTimeToDateString(v) : v)
                    }}
                    dateFormat="dd / MM / yyyy"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    dropdownMode="select"
                    isClearable={true}
                    className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                    selected={value}
                  />
                )
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.dateOfBirth && <p>{errors.dateOfBirth.message}</p>}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className={`${styles.weightHeightContainer} mx-0 pt-0 mb-0`}>
          <Form.Group as={Col} sm="6" className="p-0">
            <Form.Group as={Col} sm="11" className={`mb-2 thin p-0 ${styles.personalFormGroup}`}>
              <Form.Label column sm="12" className={`p-0 mt-0 ${styles.personalFormGroupLabel}`}>
                Height
              </Form.Label>
              <Col sm="12" className={styles.personalFormGroupCol}>
                <InputGroup className={`${errors.height ? `is-invalid ${styles.isInvalidReset}` : ''}`}>
                  <Form.Control
                    type="text"
                    {...register('height.value')}
                    className={`${errors.height?.value ? 'is-invalid' : ''}`}
                  />
                  <Form.Control as="select" {...register('height.unit')} className={`${errors.height?.unit ? 'is-invalid' : ''}`}>
                    <HeightUnitEnumOptions />
                  </Form.Control>
                </InputGroup>
                <Form.Control.Feedback type="invalid" className={styles.feedbackReset}>
                  {errors.height?.value && <p>{errors.height.value.message}</p>}
                  {errors.height?.unit && <p>{errors.height.unit.message}</p>}
                  {errors.height && <p>{errors.height.message}</p>}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form.Group>
          <Form.Group as={Col} sm="6" className="p-0">
            <Form.Group as={Col} sm="12" className={`mb-2 thin p-0 ${styles.personalFormGroup}`}>
              <Form.Label column sm="12" className={`p-0 mt-0 ${styles.personalFormGroupLabel}`}>
                Weight
              </Form.Label>
              <Col sm="12" className={styles.personalFormGroupCol}>
                <InputGroup className={`${errors.weight ? `is-invalid ${styles.isInvalidReset}` : ''}`}>
                  <Form.Control
                    type="text"
                    {...register('weight.value')}
                    className={`${errors.weight?.value ? 'is-invalid' : ''}`}
                  />
                  <Form.Control as="select" {...register('weight.unit')} className={`${errors.weight?.unit ? 'is-invalid' : ''}`}>
                    <WeightUnitEnumOptions />
                  </Form.Control>
                </InputGroup>
                <Form.Control.Feedback type="invalid" className={styles.feedbackReset}>
                  {errors.weight?.value && <p>{errors.weight.value.message}</p>}
                  {errors.weight?.unit && <p>{errors.weight.unit.message}</p>}
                  {errors.weight && <p>{errors.weight.message}</p>}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form.Group>
        </Form.Group>
        <ErrorMessageHint message={errorMessage} />
      </Form.Group>
      <Form.Group className="form-group pt-0 pb-0 mb-2">
        <Button type="submit" className={`p-0 ${styles.personalInfoSaveBtn}`}>
          Save
        </Button>
      </Form.Group>
      <Form.Group>
        <Form.Group as={Row} className={`m-0 py-4 ${styles.notificationsContainer}`}>
          <ManageEmailNotifications />
        </Form.Group>
        <Form.Group as={Row} className={`mb-3 py-4 mx-0 ${styles.linksContainer}`}>
          <Col xs="6" className="p-0">
            <Link to="/privacy-policy">Privacy policy</Link>
          </Col>
          <Col xs="6" className="p-0">
            <Link to="/terms-conditions">Terms & conditions</Link>
          </Col>
        </Form.Group>
      </Form.Group>
      {isLoading && <OverlaySpinner />}
    </Form>
  )
}

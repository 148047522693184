import { InterceptorFunction } from '../axios/axios-dao-factory'
import { authorizationHeaderInterceptor } from '../axios/interceptor/authorization-header-interceptor'
import { refreshTokenInterceptor } from '../axios/interceptor/refresh-token-interceptor'
import { BackendType } from '../enum/backend-type'
import { CommonDao, commonDaoFactory } from './common-dao-factory'

export type MessageDao = CommonDao & {
  subscribeDevice: ({ token, oldToken, clientGuid }: { token: string; oldToken?: string; clientGuid: string }) => Promise<void>
  unsubscribeDevice: ({ token }: { token: string }) => Promise<void>
}

export const messageDaoFactory = ({
  onError,
  interceptors = [],
}: {
  onError?: (error: Error) => Promise<void>
  interceptors?: InterceptorFunction[]
} = {}): MessageDao => {
  const dao = commonDaoFactory({
    backendType: BackendType.MESSAGE,
    onError,
    interceptors: [...interceptors, authorizationHeaderInterceptor, refreshTokenInterceptor],
  })
  return {
    ...dao,
    subscribeDevice: async ({
      token,
      oldToken,
      clientGuid,
    }: {
      token: string
      oldToken?: string
      clientGuid: string
    }): Promise<void> => {
      await dao._http.post('/notifications/devices/subscribe', { token, oldToken, clientGuid })
    },
    unsubscribeDevice: async ({ token }: { token: string }): Promise<void> => {
      await dao._http.post('/notifications/devices/unsubscribe', { token })
    },
  }
}

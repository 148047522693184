import { Store } from '../../dao/core-dao'
import styles from './store-subscription-info.module.scss'
import React from 'react'

export const StoreSubscriptionInfo = (props: { store?: Store }): React.ReactElement => {
  const { store } = props

  const getStoreName = (store?: Store): string => {
    switch (store) {
      case 'android':
        return 'Google Play Store'
      case 'ios':
        return 'Apple App Store'
      default:
        return 'GRNDHOUSE Web app'
    }
  }

  return (
    <div className={styles.container}>
      <h2>Your subscription was purchased on another device</h2>
      <p>You have a valid subscription but it has been purchased via {getStoreName(store)}.</p>
      <p>Please go to the {getStoreName(store)} to manage your subscription.</p>
    </div>
  )
}

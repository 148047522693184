import { PrivacyPolicy } from '../../component/legal/privacy-policy'
import { TermsAndConditions } from '../../component/legal/terms-and-conditions'
import styles from './settings-legal-screen.module.scss'
import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'

export const SettingsLegalScreen = (): React.ReactElement => {
  return (
    <Row>
      <Col>
        <Tab.Container id="left-tabs-example" defaultActiveKey="privacy">
          <Row>
            <Col>
              <Nav fill className="grnd-tab">
                <Nav.Item>
                  <Nav.Link eventKey="privacy">Privacy Policy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="terms">Terms & Conditions</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="privacy">
                  <div className={styles.tabContainer}>
                    <PrivacyPolicy />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="terms">
                  <div className={styles.tabContainer}>
                    <TermsAndConditions />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>
  )
}

import { Subscription } from '../../dao/core-dao'
import styles from './subscription-list.module.scss'
import React from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'

export const SubscriptionList = (props: { mySubscriptions: Subscription[] }): React.ReactElement => {
  const timestampToString = (timestamp: number): string => new Date(timestamp * 1000).toLocaleDateString()

  return (
    <Row xs={1} md={2} className="g-4">
      {props.mySubscriptions.map((sub, index) => (
        <Col className={styles.container}>
          <Card key={index} className={styles.card}>
            <Card.Body>
              <Card.Title>{sub.canceledAt ? 'Cancelled' : 'Active'} subscription</Card.Title>
              <Card.Text>
                {sub.cancelAt ? (
                  <Alert className={styles.alert}>Subscription will be active until {timestampToString(sub.cancelAt)}</Alert>
                ) : (
                  ''
                )}
              </Card.Text>
              {sub.items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Card.Text>
                      <b>Plan</b>: {item.product.name}
                      <br />
                      <b>Price</b>: {item.plan.amount} {item.plan.currency}, every {item.price.recurring?.intervalCount}{' '}
                      {item.price.recurring?.interval}(s)
                      <br />
                      <b>Valid until</b>: {timestampToString(sub.currentPeriodEnd)}
                    </Card.Text>
                  </React.Fragment>
                )
              })}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

import { Workout } from '../../dao/core-dao'
import style from './workout-description.module.scss'
import React from 'react'

export const WorkoutDescription = ({ workout }: { workout: Workout }): React.ReactElement => {
  return (
    <div className="py-3">
      <h1 className={style.title}>Description</h1>
      <p className={style.description}>{workout.description}</p>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useLocalPathName = (): [string] => {
  const [pathName, setPathName] = useState<string>(window.location.pathname)
  const location = useLocation()

  useEffect(() => {
    setPathName(location.pathname)
  }, [location])

  return [pathName]
}

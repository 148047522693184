import { messageDao } from '../dao/message-dao'

export const messageService = {
  subscribeToEmailNotifications: (): void => {
    messageDao.setMyOptIn({ isOptingIn: true })
  },

  unsubscribeFromEmailNotifications: (): void => {
    messageDao.setMyOptIn({ isOptingIn: false })
  },
}

import { CarouselSection } from '../carousel-section/carousel-section'
import { ForwardArrowLink } from '../forward-arrow-link/forward-arrow-link'
import { LoadingSpinner } from '../util/spinner/loading-spinner'
import style from './workout-themed-section.module.scss'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Workout } from 'src/dao/core-dao'
import { WorkoutFilter, coreService } from 'src/service/core-service'

export const WorkoutThemedSection = ({
  theme,
  label,
  seeAllLink,
  workoutFilter,
}: {
  theme: 'dark' | 'light'
  seeAllLink: string
  label: string
  workoutFilter: WorkoutFilter
}): React.ReactElement => {
  const [workouts, setWorkouts] = useState<Workout[]>()

  useEffect(() => {
    coreService.getWorkouts({ ...workoutFilter, page: 1, pageSize: 6 }).then((response) => {
      setWorkouts(response)
    })
  }, [workoutFilter])

  return (
    <Container className={`${style.workoutStyleSectionContainer} ${style[theme]}`}>
      <Row noGutters={true}>
        <Col className="d-flex justify-content-between align-items-center">
          <h2 className={style.title}>{label}</h2>
          <div className={style.seeAll}>
            <ForwardArrowLink text="See All" link={seeAllLink} />
          </div>
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col>
          <LoadingSpinner isLoading={!workouts}>
            {(workouts?.length ?? 0) > 0 ? (
              <CarouselSection workoutsList={workouts ?? []} isNew={false} />
            ) : (
              <p className={style.noWorkouts}>No {label} workouts found</p>
            )}
          </LoadingSpinner>
        </Col>
      </Row>
    </Container>
  )
}

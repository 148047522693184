import styles from './programme-workout-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { WorkoutVideo } from 'src/component/workout/workout-video'
import { Workout } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const ProgrammeWorkoutScreen = (): React.ReactElement => {
  const { programmeId, programmeWorkoutId, workoutId } =
    useParams<{ programmeId: string; programmeWorkoutId: string; workoutId: string }>()
  const [workout, setWorkout] = useState<Workout>()

  useEffect(() => {
    coreService.getWorkoutById({ workoutId }).then((response) => {
      setWorkout(response)
    })
  }, [workoutId])

  if (!workout) {
    return <div>Loading...</div>
  }

  return (
    <PageContainer>
      <div className={styles.container}>
        <Row>
          <Col>
            <BackArrow />
          </Col>
        </Row>
        <Row>
          <Col>
            <WorkoutVideo workout={workout} programmeId={programmeId} programmeWorkoutId={programmeWorkoutId} />
          </Col>
        </Row>
      </div>
    </PageContainer>
  )
}

import { useFilterContext } from '../../context/filter-context'
import style from './workouts-filter-result-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { WorkoutCarouselCard } from 'src/component/workout-carousel-card/workout-carousel-card'
import { Workout } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

type WorkoutFilterResultList = {
  workouts: Workout[]
  type: 'result' | 'fallback'
}
export const WorkoutsFilterResultScreen = (): React.ReactElement => {
  const { submittedWorkoutFilter: filters, submittedFilterCount, clear } = useFilterContext()
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)
  const location = useLocation()
  const history = useHistory()
  const [result, setResult] = useState<WorkoutFilterResultList>()

  const subSectionLabel = new URLSearchParams(location.search).get('label')
  useEffect(() => {
    if (!filters || submittedFilterCount < 1) {
      history.goBack()
      return
    }
    setResult(undefined)
    coreService
      .getWorkouts(filters)
      .then<WorkoutFilterResultList>((workouts) => {
        if (workouts.length) {
          return { workouts, type: 'result' }
        }
        return coreService
          .getWorkouts({
            page: 1,
            pageSize: 21,
          })
          .then((w) => ({ workouts: w, type: 'fallback' }))
      })
      .then((res) => setResult(res))
  }, [filters, submittedFilterCount, history])

  if (!result) return <p>Loading...</p>

  const { type, workouts } = result
  return (
    <PageContainer>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-end mb-5 flex-wrap">
          <div>
            <span className={style.noWorkoutsFoundTitle}>{type === 'fallback' ? `HAVEN'T FOUND ANY RESULTS` : ''}</span>
            <h1 className={style.allWorkoutsTitle}>
              {type === 'result' ? 'FILTER RESULTS' : 'DID YOU MEAN THESE'}
              {subSectionLabel ? ` (${subSectionLabel})` : ''}
            </h1>
          </div>
          <Button onClick={() => clear(true)} size={'sm'} variant="outline-secondary">
            Clear Filters
          </Button>
        </div>
        <Row>
          {workouts.map((w) => (
            <Col className="px-1" key={w.id} md={6} lg={4}>
              <div className="d-flex justify-content-center py-2">
                <WorkoutCarouselCard workout={w} isNew={false} selectWorkout={() => setSelectedWorkout(w)} showPopup={true} />
              </div>
            </Col>
          ))}
          <WorkoutPopup
            setOnClose={() => setSelectedWorkout(undefined)}
            selectedWorkout={selectedWorkout}
            startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
          />
        </Row>
      </div>
    </PageContainer>
  )
}

import { Intensity, Workout, coreDao } from '../../dao/core-dao'
import { timeUtil } from '../../util/time-util'
import { ImagePublicFileId } from '../image/image-public-file-id'
import { ProgrammeInfoPill } from '../programme-info-pill/programme-info-pill'
import { ProgrammeIntensityPill } from '../programme-intensity-pill/programme-intensity-pill'
import { WorkoutPopup } from '../programmes/programme-workout-popup'
import style from './todays-workouts.module.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Carousel, Container } from 'react-bootstrap'
import { SessionContext } from 'src/context/session-context'

interface TodaysWorkoutSlideProps {
  workout: Workout
  intensity?: Intensity
  selectWorkout?: (workout: Workout) => void
}

const TodaysWorkoutSlide = ({ workout, intensity, selectWorkout }: TodaysWorkoutSlideProps): React.ReactElement => {
  const {
    getFirstTrainerFromWorkout,
    hustlerConfig: { showWorkoutDates },
  } = useContext(SessionContext)
  const trainer = getFirstTrainerFromWorkout(workout)

  const availableAt = timeUtil.timestampToDateFormatWithNumericMonth(parseInt(workout.availableAt))

  return (
    <Container className={style.todaysWorkoutSlideContainer} onClick={() => selectWorkout?.(workout)}>
      <ImagePublicFileId fileId={workout.imagePublicFileId} className={style.todaysWorkoutSlideImage} />
      <div className={style.slideContent}>
        <div className={style.empty}></div>
        <h2 className={style.slideTitle}>Today's Workout</h2>
        <div className={`flex-column ${style.workoutInfo}`}>
          <div className="mb-3">
            <h3>
              {workout.title} {showWorkoutDates ? availableAt.toString() : ''}
            </h3>
          </div>
          <div className="d-flex align-items-center">
            <h4 className="mr-4">{trainer?.firstName}</h4>
            <div className="mr-4">
              <ProgrammeInfoPill text={`${workout.duration} min`} />
            </div>
            <ProgrammeIntensityPill intensity={intensity} showColorIcon={true} />
          </div>
        </div>
      </div>
    </Container>
  )
}

type WorkoutData = {
  workouts: Workout[]
  intensities: Intensity[]
}

export const TodaysWorkouts = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [workouts, setWorkouts] = useState<Workout[]>([])
  const [workoutIntensities, setWorkoutIntensities] = useState<Intensity[]>()
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  useEffect(() => {
    const getData = async (): Promise<WorkoutData> => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const workouts = await coreDao.getWorkouts({ preset: 'today' })
      const intensities = await coreDao.listWorkoutIntensities()
      return {
        workouts,
        intensities,
      }
    }

    setIsLoading(true)
    getData()
      .then((data: WorkoutData) => {
        setWorkouts(data.workouts)
        setWorkoutIntensities(data.intensities)
      })
      .finally(() => setIsLoading(false))
  }, [])

  if (isLoading || !workoutIntensities) return <p>Loading...</p>

  const getIntensity = (intensityId: string | undefined): Intensity | undefined => {
    if (!intensityId) {
      return undefined
    }
    return workoutIntensities.find((i) => i.id === intensityId)
  }

  return (
    <>
      <style type={'text/css'}>
        {`
          .carousel-indicators {
            position: absolute;
            left: unset;
            z-index: 200;
            bottom: 40px;
            right: 40px;
            margin: 0px;
          }

          .carousel-indicators li {
            background-color: #565656;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            z-index: 200;
            margin-inline: 10px;
          }

          .carousel-indicators li.active {
            background-color: #fff;
            z-index: 200;
            width: 12px;
            height: 12px;
          }
      `}
      </style>

      {workouts.length > 0 && (
        <Carousel controls={false} indicators={true} className={style.carousel}>
          {workouts.map((w: Workout) => (
            <Carousel.Item key={w.id} interval={2000} className="mt-4 mb-4">
              <TodaysWorkoutSlide
                workout={w}
                intensity={getIntensity(w.intensityId)}
                selectWorkout={() => setSelectedWorkout(w)}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )}
      <WorkoutPopup
        setOnClose={() => setSelectedWorkout(undefined)}
        selectedWorkout={selectedWorkout}
        startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
      />
    </>
  )
}

import { axiosUtil } from '../axios-util'
import { AxiosInstance } from 'axios'

export const authorizationHeaderInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        ...axiosUtil.getAuthorizationHeaderKey(),
      }
      return config
    },
    (error) => error,
  )
}

import { HeightUnit } from '../../dao/core-dao'
import React from 'react'

export const HeightUnitEnumOptions = (): React.ReactElement => {
  const readableHeightUnitKeys = (value: string): string => {
    switch (value) {
      case HeightUnit.CM:
        return 'cm'
      case HeightUnit.FT:
        return 'ft'
      default:
        throw new Error(`Unknown height unit type [${value}]`)
    }
  }

  return (
    <>
      {Object.values(HeightUnit).map((val, ix) => (
        <option key={ix} value={val}>
          {readableHeightUnitKeys(val)}
        </option>
      ))}
    </>
  )
}

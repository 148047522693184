import { WeightUnit } from '../../dao/core-dao'
import React from 'react'

export const WeightUnitEnumOptions = (): React.ReactElement => {
  const readableWeightUnitKeys = (value: string): string => {
    switch (value) {
      case WeightUnit.KG:
        return 'kg'
      case WeightUnit.LB:
        return 'lb'
      default:
        throw new Error(`Unknown weight unit type [${value}]`)
    }
  }

  return (
    <>
      {Object.values(WeightUnit).map((val, ix) => (
        <option key={ix} value={val}>
          {readableWeightUnitKeys(val)}
        </option>
      ))}
    </>
  )
}

import { useFilterContext } from '../../context/filter-context'
import { SessionContext } from '../../context/session-context'
import { BodyPartFilter } from './body-part/body-part-filter'
import { DurationFilter } from './duration-filter'
import { EquipmentFilter } from './equipment-filter'
import { FilterSection } from './filter-section'
import styles from './filter-sidebar.module.scss'
import { IntensityFilter } from './intensity-filter'
import { TrainerFilter } from './trainer-filter'
import { WorkoutStyleFilter } from './workout-style-filter'
import { WorkoutTypeFilter } from './workout-type-filter'
import { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

export const FilterSidebar = ({ navElement }: { navElement: HTMLElement | null }): ReactElement => {
  const { trainers } = useContext(SessionContext)
  const {
    workoutFilter,
    submittedWorkoutFilter,
    pendingFilterCount,
    isSidebarOpen,
    setIsSidebarOpen,
    submittedFilterCount,
    submitRedirectPath,
    clear,
    submit,
  } = useFilterContext()
  const history = useHistory()
  const [topOffset, setTopOffset] = useState(0)
  const sidebarScrollElement = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!navElement) return
    const resizeObserver = new ResizeObserver((el) => {
      const element = el[0]
      if (!element) {
        return
      }
      const borderBox = element.borderBoxSize
      const elHeight = borderBox?.[0].blockSize ?? element.target.getBoundingClientRect().bottom
      setTopOffset(elHeight)
    })
    resizeObserver.observe(navElement)
    return () => {
      resizeObserver.disconnect()
    }
  }, [navElement])
  useEffect(() => {
    if (isSidebarOpen && sidebarScrollElement.current) {
      sidebarScrollElement.current.scrollTop = 0
    }
  }, [isSidebarOpen])

  useEffect(() => {
    setIsSidebarOpen(false)
    if (pathname !== '/workouts-filter-result' && pathname.indexOf('/workouts/') < 0) {
      clear(true)
    }
  }, [pathname, clear, setIsSidebarOpen])

  const submitFilters = (): void => {
    submit()
    if (pathname !== '/workouts-filter-result') {
      history.push(submitRedirectPath)
    }
  }

  const isClearDisabled = pendingFilterCount < 1
  const isSubmitDisabled = isClearDisabled || workoutFilter === submittedWorkoutFilter
  return (
    <div
      className={`${styles.sidebarContainer} ${!isSidebarOpen && styles.sidebarContainerHidden}`}
      style={{
        top: topOffset,
      }}
    >
      <div className={`d-flex flex-column justify-content-between ${styles.sidebar}`}>
        <FilterSection withSeparator={false}>
          <div className={`d-flex flex-fill justify-content-between ${styles.sidebarHeading}`}>
            <span className={styles.sidebarHeading}>Filters</span>
            {submittedFilterCount ? (
              <Button disabled={isClearDisabled} onClick={() => clear(false)} size={'sm'} variant="outline-secondary">
                Clear Filters
              </Button>
            ) : (
              <></>
            )}
          </div>
        </FilterSection>
        <div className={`flex-shrink-1 ${styles.sidebarFiltersContainer}`} ref={sidebarScrollElement}>
          <TrainerFilter trainers={trainers ?? []} />
          <DurationFilter />
          <IntensityFilter />
          <WorkoutTypeFilter />
          <WorkoutStyleFilter />
          <EquipmentFilter />
          <BodyPartFilter />
        </div>
        <div className={`d-flex justify-content-center ${styles.submitFiltersContainer}`}>
          <Button disabled={isSubmitDisabled} onClick={submitFilters} size={'sm'} variant={'outline-primary'}>
            Search Workouts
          </Button>
        </div>
      </div>
    </div>
  )
}

export const guidUtil = {
  generate: (): string => {
    const { _s4: s4 } = guidUtil
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
  },
  _s4: (): string => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  },
}

import style from './programme-preview-modal.module.scss'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap'
import { ImagePublicFileId } from 'src/component/image/image-public-file-id'
import { ProgrammeInfoPill } from 'src/component/programme-info-pill/programme-info-pill'
import { ProgrammeIntensityPill } from 'src/component/programme-intensity-pill/programme-intensity-pill'
import { WorkoutDurationSvgIcon } from 'src/component/util/images'
import { Intensity, Programme, ProgrammeDuration, WorkoutEquipmentPiece } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

type WeekDescription = {
  order: number
  title: string
  description: string
}

const WeekTab = ({ eventKey, text }: { eventKey: string; text: string }): React.ReactElement => {
  return (
    <Nav.Item className="programmeNavItem">
      <Nav.Link eventKey={eventKey}>{text}</Nav.Link>
    </Nav.Item>
  )
}

const WeekPane = ({ eventKey, text }: { eventKey: string; text: string }): React.ReactElement => {
  return (
    <Tab.Pane eventKey={eventKey}>
      <div className={style.tabContainer}>
        <p>{text}</p>
      </div>
    </Tab.Pane>
  )
}

const ProgrammeInfoTabs = ({
  description,
  weekDescriptions,
}: {
  description: string
  weekDescriptions: WeekDescription[]
}): React.ReactElement => {
  return (
    <div className={style.programmeInfoTabs}>
      <Tab.Container id="programme-info-tab" defaultActiveKey="description">
        <Nav fill className={style.programmeInfoTab}>
          <Nav.Item className="programmeNavItem">
            <Nav.Link eventKey="description">General Info</Nav.Link>
          </Nav.Item>
          {weekDescriptions.map((w) => (
            <WeekTab eventKey={`programme-tab-${w.order}`} text={`Week ${w.order}`} key={`week-tab-${w.order}`} />
          ))}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="description">
            <div className={style.tabContainer}>
              <p>{description}</p>
            </div>
          </Tab.Pane>
          {weekDescriptions.map((w) => (
            <WeekPane eventKey={`programme-tab-${w.order}`} text={w.description} key={`week-description-${w.order}`} />
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

const ProgrammeEquipmentBlock = ({ equipmentList }: { equipmentList?: WorkoutEquipmentPiece[] }): React.ReactElement => {
  const equipment = (): string => {
    if (equipmentList && equipmentList.length > 0) {
      return equipmentList.map((e) => e.label).join(', ')
    }
    return 'No equipment needed.'
  }

  return (
    <div className={style.infoBlock}>
      <p className={style.subtitle}>Equipment</p>
      <p className={style.description}>{equipment()}</p>
    </div>
  )
}

const ProgrammePreviewModalBody = ({
  programme,
  programmeDuration,
  intensity,
  equipmentList,
}: {
  programme: Programme
  programmeDuration?: ProgrammeDuration
  intensity?: Intensity
  equipmentList?: WorkoutEquipmentPiece[]
}): React.ReactElement => {
  const programmeDurationText = (): string => {
    if (programmeDuration) {
      const { shortestDuration, longestDuration } = programmeDuration.classes
      if (shortestDuration === longestDuration) return longestDuration
      return `${shortestDuration} - ${longestDuration}`
    }
    return 'Loading'
  }
  return (
    <div className={style.ProgrammePreviewModalBody}>
      <div className={style.programmeModalHeader}>
        <ImagePublicFileId fileId={programme.imagePublicFileId} className={style.programmeModalHeaderImage} />
        <div className={style.programmeHeaderContent}>
          <p className={style.programmeCardTitle}>{programme.title}</p>
          <div className={style.programmeInfo}>
            <ProgrammeInfoPill text={programmeDuration ? `${programmeDuration.weeks.count} weeks` : '?'} />
            <ProgrammeInfoPill text={programmeDuration ? `${programmeDuration.classes.count} classes` : '?'} />
            <ProgrammeIntensityPill intensity={intensity} />
          </div>
        </div>
      </div>
      <div className={style.programmeModalContent}>
        <div className={style.basicInfo}>
          <div className={style.infoBlocksContainer}>
            <ProgrammeEquipmentBlock equipmentList={equipmentList} />
            <div className={style.infoBlock}>
              <p className={style.subtitle}>Designed for</p>
              <p className={style.description}>{programme.shortDescription}</p>
            </div>
          </div>
          <div className={style.averageDuration}>
            <WorkoutDurationSvgIcon className={style.durationIcon} />
            <p>{programmeDurationText()} min workouts</p>
          </div>
        </div>
        <ProgrammeInfoTabs description={programme.description} weekDescriptions={programme.weekDescription} />
      </div>
    </div>
  )
}

export const ProgrammePreviewModal = ({
  programme,
  toggle,
  intensity,
  programmeDuration,
  onStartProgramme,
}: {
  programme?: Programme
  toggle: () => void
  intensity?: Intensity
  programmeDuration?: ProgrammeDuration
  onStartProgramme: () => void
}): React.ReactElement => {
  const [programmeEquipment, setProgrammeEquipment] = useState<WorkoutEquipmentPiece[]>([])

  const previewedProgrammeId = programme?.id ?? ''

  useEffect(() => {
    setProgrammeEquipment([])
    if (previewedProgrammeId) {
      coreService.getProgrammeEquipmentList({ programmeId: previewedProgrammeId }).then((response) => {
        setProgrammeEquipment(response)
      })
    }
  }, [previewedProgrammeId])

  const startProgramme = (): void => {
    onStartProgramme()
  }

  return (
    <Modal
      dialogClassName={style.modalDialog}
      contentClassName={style.modal}
      show={!!previewedProgrammeId}
      onHide={() => toggle()}
      centered
    >
      <Modal.Body className={style.modalBody}>
        {programme && (
          <ProgrammePreviewModalBody
            programme={programme}
            intensity={intensity}
            programmeDuration={programmeDuration}
            equipmentList={programmeEquipment}
          />
        )}
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Button variant="outline-primary" onClick={startProgramme} className={style.modalButton}>
          Start Programme
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

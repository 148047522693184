import { WorkoutIntensity } from '../../dao/core-dao'
import React from 'react'

export const IntensityIcon = (props: { intensity: WorkoutIntensity; active: boolean }): React.ReactElement => {
  const { intensity, active } = props

  const renderIntensity = (intensity: WorkoutIntensity): React.ReactElement => {
    switch (intensity) {
      case WorkoutIntensity.LOW:
        return (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.1218 23.309C37.1218 26.2627 36.0342 29.1271 34.0522 31.3898H34.0523L33.4369 32.0925L32.7131 32.9193L35.3017 35L36.0255 34.1732L34.9039 33.2717L35.5193 32.5689C37.4433 30.3723 38.6319 27.6808 38.9502 24.8514L40.4214 24.9498L40.5 23.8733L39.0288 23.7749C39.0338 23.6199 39.0369 23.4645 39.0369 23.3089C39.0369 19.6158 37.5847 16.2477 35.1987 13.6982L36.2611 12.7151L35.4787 11.939L34.4161 12.9221C31.8398 10.5502 28.3837 9.04895 24.5633 8.91407V7.5H23.437V8.91407C19.6166 9.04896 16.1606 10.5503 13.5842 12.9221L12.5216 11.939L11.7392 12.7153L12.8016 13.6982C10.4155 16.2477 8.96341 19.6158 8.96341 23.3089C8.96341 23.4645 8.96604 23.6199 8.97121 23.7749L7.5 23.8735L7.57861 24.9501L9.04983 24.8514C9.3681 27.6808 10.5567 30.3723 12.4807 32.569L13.0961 33.2717L14.5631 32.0925L11.9747 34.1736L12.6987 35L15.2871 32.9189L14.5631 32.0925L13.9477 31.3898C11.9657 29.1271 10.8781 26.2627 10.8781 23.309C10.8781 16.367 16.7539 10.7375 23.9999 10.7375C31.2458 10.7375 37.1218 16.3668 37.1218 23.309ZM27.7923 18.8788L25.4372 21.1523C24.4132 20.64 23.1403 20.7402 22.1959 21.5112C21.1153 22.3934 20.809 23.8724 21.3645 25.084L14.7972 31.4238L22.3232 26.2584C23.3991 27.0453 24.9096 27.0413 25.9902 26.1591C26.9347 25.3881 27.2877 24.1609 26.9906 23.0551L29.6895 21.2027L27.7923 18.8788Z"
              fill="white"
            />
          </>
        )
      case WorkoutIntensity.MEDIUM:
        return (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.1218 23.309C37.1218 26.2627 36.0342 29.1271 34.0522 31.3898H34.0523L33.4369 32.0925L32.7131 32.9193L35.3017 35L36.0255 34.1732L34.9039 33.2717L35.5193 32.5689C37.4433 30.3723 38.6319 27.6808 38.9502 24.8514L40.4214 24.9498L40.5 23.8733L39.0288 23.7749C39.0338 23.6199 39.0369 23.4645 39.0369 23.3089C39.0369 19.6158 37.5847 16.2477 35.1987 13.6982L36.2611 12.7151L35.4787 11.939L34.4161 12.9221C31.8398 10.5502 28.3837 9.04895 24.5633 8.91407V7.5H23.437V8.91407C19.6166 9.04896 16.1606 10.5503 13.5842 12.9221L12.5216 11.939L11.7392 12.7153L12.8016 13.6982C10.4155 16.2477 8.96341 19.6158 8.96341 23.3089C8.96341 23.4645 8.96604 23.6199 8.97121 23.7749L7.5 23.8735L7.57861 24.9501L9.04983 24.8514C9.3681 27.6808 10.5567 30.3723 12.4807 32.569L13.0961 33.2717L14.5631 32.0925L11.9747 34.1736L12.6987 35L15.2871 32.9189L14.5631 32.0925L13.9477 31.3898C11.9657 29.1271 10.8781 26.2627 10.8781 23.309C10.8781 16.367 16.7539 10.7375 23.9999 10.7375C31.2458 10.7375 37.1218 16.3668 37.1218 23.309ZM22.5 30L22.7718 26.7379C21.7274 26.2686 21 25.2193 21 24C21 22.605 21.9521 21.4324 23.242 21.0966L24 12L24.758 21.0966C26.0479 21.4324 27 22.605 27 24C27 25.2193 26.2726 26.2686 25.2282 26.7379L25.5 30H22.5Z"
              fill="white"
            />
          </>
        )
      case WorkoutIntensity.HIGH:
        return (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.1218 23.309C37.1218 26.2627 36.0342 29.1271 34.0522 31.3898H34.0523L33.4369 32.0925L32.7131 32.9193L35.3017 35L36.0255 34.1732L34.9039 33.2717L35.5193 32.5689C37.4433 30.3723 38.6319 27.6808 38.9502 24.8514L40.4214 24.9498L40.5 23.8733L39.0288 23.7749C39.0338 23.6199 39.0369 23.4645 39.0369 23.3089C39.0369 19.6158 37.5847 16.2477 35.1987 13.6982L36.2611 12.7151L35.4787 11.939L34.4161 12.9221C31.8398 10.5502 28.3837 9.04895 24.5633 8.91407V7.5H23.437V8.91407C19.6166 9.04896 16.1606 10.5503 13.5842 12.9221L12.5216 11.939L11.7392 12.7153L12.8016 13.6982C10.4155 16.2477 8.96341 19.6158 8.96341 23.3089C8.96341 23.4645 8.96604 23.6199 8.97121 23.7749L7.5 23.8735L7.57861 24.9501L9.04983 24.8514C9.3681 27.6808 10.5567 30.3723 12.4807 32.569L13.0961 33.2717L14.5631 32.0925L11.9747 34.1736L12.6987 35L15.2871 32.9189L14.5631 32.0925L13.9477 31.3898C11.9657 29.1271 10.8781 26.2627 10.8781 23.309C10.8781 16.367 16.7539 10.7375 23.9999 10.7375C31.2458 10.7375 37.1218 16.3668 37.1218 23.309ZM18.4606 21.2027L20.3578 18.8788L22.7129 21.1523C23.7369 20.64 25.0097 20.7402 25.9542 21.5112C27.0348 22.3934 27.341 23.8724 26.7856 25.084L33.3529 31.4238L25.8268 26.2584C24.751 27.0453 23.2405 27.0413 22.1598 26.1591C21.2153 25.3881 20.8624 24.1609 21.1594 23.0551L18.4606 21.2027Z"
              fill="white"
            />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <svg
      className={active ? 'active' : ''}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_918_8404)">
        <circle cx="24" cy="24" r="22.5" fill="#C4C4C4" fillOpacity="0.2" />
      </g>
      {renderIntensity(intensity)}
      <circle cx="24" cy="24" r="23" stroke="white" strokeWidth="2" />
      <defs>
        <filter id="filter0_b_918_8404" x="-3.71739" y="-3.71739" width="55.4348" height="55.4348" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="2.6087" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_918_8404" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_918_8404" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

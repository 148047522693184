import { Trainer } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { LoadingSpinner } from '../util/spinner/loading-spinner'
import { TrainerJumbotron, TrainerJumbotronType } from './trainer-jumbotron'
import styles from './trainer-profile.module.scss'
import React, { useEffect, useState } from 'react'

export const TrainerProfile = (props: { trainerId: string }): React.ReactElement => {
  const { trainerId } = props

  const [trainer, setTrainer] = useState<Trainer>()

  useEffect(() => {
    coreService
      .getTrainer({ trainerId })
      .then((trainerResponse) => {
        setTrainer(trainerResponse)
      })
      .catch(() => setTrainer(undefined))
  }, [trainerId])

  return (
    <LoadingSpinner isLoading={!trainer}>
      {trainer && (
        <div className="d-flex flex-column align-items-center">
          <TrainerJumbotron trainer={trainer} type={TrainerJumbotronType.PROFILE} displayInstagramLink={true} />
          <div className={styles.bio}>
            {trainer.description.split('\n').map((sentence, ix) => (
              <p key={ix}>{sentence}</p>
            ))}
          </div>
        </div>
      )}
    </LoadingSpinner>
  )
}

import styles from './filter-item-button.module.scss'
import React, { ReactElement, ReactNode } from 'react'

export type FilterItemButtonProps = {
  isActive: boolean
  className?: string
  fullWidth?: boolean
  onClick: () => void
  children: ReactNode
}

export const FilterItemButton = ({
  isActive,
  className = '',
  fullWidth = false,
  children,
  onClick,
}: FilterItemButtonProps): ReactElement => {
  return (
    <span
      onClick={onClick}
      className={`${className} ${styles.filterItemButton} ${fullWidth && styles.fullWidth} ${
        isActive && styles.filterItemActiveButton
      }`}
    >
      {children}
    </span>
  )
}

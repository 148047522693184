import { JoiErrorCode, joiUtil } from '../util/joi-util'
import Joi from 'joi'
import * as yup from 'yup'

export const passwordService = {
  passwordValidationObject: yup
    .string()
    .trim()
    .min(8, 'Minimum password length is 8 characters.')
    .max(128, 'Maximum password length is 128 characters.')
    .required('Password is required.'),
  joiPasswordValidationObject: Joi.string()
    .trim()
    .min(8)
    .max(128)
    .error(
      joiUtil.errorMessageMap({
        [JoiErrorCode.STRING_MIN]: 'Minimum password length is 8 characters.',
        [JoiErrorCode.STRING_MAX]: 'Maximum password length is 128 characters.',
      }),
    ),
}

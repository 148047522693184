import { Gender } from '../../dao/core-dao'
import React from 'react'

export const GenderEnumOptions = (): React.ReactElement => {
  const readableGenderKeys = (value: string): string => {
    switch (value) {
      case Gender.N_A:
        return 'N/A'
      case Gender.MALE:
        return 'Male'
      case Gender.FEMALE:
        return 'Female'
      case Gender.NON_BINARY:
        return 'Non binary'
      default:
        throw new Error(`Unknown gender type [${value}]`)
    }
  }

  return (
    <>
      {Object.values(Gender).map((val, ix) => (
        <option key={ix} value={val}>
          {readableGenderKeys(val)}
        </option>
      ))}
    </>
  )
}

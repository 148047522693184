import {
  Hustler,
  HustlerConfig,
  Trainer,
  Workout,
  WorkoutDuration,
  WorkoutEquipment,
  WorkoutEquipmentPiece,
} from '../dao/core-dao'
import { coreService } from '../service/core-service'
import { logger } from '../util/logger'
import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react'

export type SessionData = {
  me?: Hustler
  hustlerConfig: HustlerConfig
  setMe: React.Dispatch<React.SetStateAction<Hustler | undefined>>
  refreshMyData: () => Promise<void>
  hasActiveSubscription: boolean
  toggleSubscriptionModal: () => void
  showSubscriptionModal: boolean
  trainers?: Trainer[]
  getFirstTrainerFromWorkout: (item: Workout) => Trainer | undefined
  trainerById: (id: string) => Trainer | undefined
  renderDuration: (id: string) => string | undefined
  renderEquipmentPieces: (workoutEquipment: WorkoutEquipment[]) => string
  completedWorkoutIds: Set<string>
  completeWorkout: (w: Workout) => void
}

export const SessionContext = createContext<SessionData>({} as SessionData)

export const SessionContextProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [me, setMe] = useState<Hustler>()
  const [trainers, setTrainers] = useState<Trainer[]>()
  const [durations, setDurations] = useState<WorkoutDuration[]>()
  const [equipment, setEquipment] = useState<WorkoutEquipmentPiece[]>()
  const [hustlerConfig, setHustlerConfig] = useState<HustlerConfig>({
    showWorkoutDates: true,
    showNewMonthlyWorkouts: false,
    newWorkoutsTitle: `This Week's Programme`,
  })
  const [completedWorkoutIds, setCompletedWorkoutIds] = useState(new Set<string>())
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)

  const equipmentPiecesLabels = useMemo(() => {
    return (equipment ?? []).reduce((map, piece) => {
      map.set(piece.id, piece.label ?? '')
      return map
    }, new Map<string, string>())
  }, [equipment])
  const trainerById = useCallback(
    (trainerId: string): Trainer | undefined => {
      if (!trainers) return undefined
      return trainers.filter((trainer: Trainer) => trainerId === trainer.id)[0]
    },
    [trainers],
  )

  const getFirstTrainerFromWorkout = useCallback(
    (item: Workout): Trainer | undefined => {
      if ((item.trainers ?? []).length === 0) return undefined
      return (item.trainers[0].id && trainerById(item.trainers[0].id)) || undefined
    },
    [trainerById],
  )

  const renderDuration = useCallback(
    (durationId: string): string | undefined => {
      if (!durations) return undefined
      const duration = durations.filter((duration: WorkoutDuration) => durationId === duration.id)[0]
      return `${duration.durationValue} ${duration.durationUnit.slice(0, 3)}`
    },
    [durations],
  )

  const renderEquipmentPieces = (workoutEquipment: WorkoutEquipment[]): string => {
    if (!equipment) return 'No equipment required'
    return (
      workoutEquipment
        .filter((eq) => !!eq.equipmentPieceId)
        .map((eq) => equipmentPiecesLabels.get(eq.equipmentPieceId))
        .join(', ') || 'No equipment required'
    )
  }

  const toggleSubscriptionModal = useCallback((): void => {
    setShowSubscriptionModal((modalShown) => !modalShown)
  }, [])

  const refreshMyData = async (): Promise<void> => {
    const myInfo = await coreService.getMe()
    setMe(myInfo)

    const response = await coreService.getMySubscriptions()
    setHasActiveSubscription(!!response)
    setShowSubscriptionModal(!response)

    const trainerResponse = await coreService.getTrainers()
    setTrainers(trainerResponse)

    const durationResponse = await coreService.listWorkoutDurations()
    setDurations(durationResponse)

    const hustlerConfig = await coreService.getConfig()
    setHustlerConfig(hustlerConfig)

    const equipment = await coreService.getWorkoutEquipmentPieces()
    setEquipment(equipment)

    const completedWorkouts = await coreService.getCompletedWorkouts()
    setCompletedWorkoutIds(
      completedWorkouts.reduce((s, cw) => {
        s.add(cw.workout.id)
        return s
      }, new Set<string>()),
    )
  }

  useEffect(() => {
    refreshMyData().catch((err) => logger.error(err))
  }, [])

  const completeWorkout = (w: Workout): void => {
    setCompletedWorkoutIds((prev) => {
      const st = new Set(prev)
      st.add(w.id)
      return st
    })
  }
  return (
    <SessionContext.Provider
      value={{
        me,
        hustlerConfig,
        setMe,
        refreshMyData,
        hasActiveSubscription,
        trainers,
        getFirstTrainerFromWorkout,
        trainerById,
        toggleSubscriptionModal,
        showSubscriptionModal,
        renderDuration,
        renderEquipmentPieces,
        completedWorkoutIds,
        completeWorkout,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

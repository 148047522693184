import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { ProgrammeInfoContainer } from 'src/component/programmes/programme-info-container'
import { ProgressReport } from 'src/component/programmes/programme-progress-report'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { WorkoutsContainer } from 'src/component/programmes/programme-workouts-container'
import { CompletedProgrammeWorkout, HustlerProgramme, Intensity, ProgrammeWorkout, coreDao } from 'src/dao/core-dao'

export const ProgrammeOverviewScreen = (): React.ReactElement => {
  const { programmeId } = useParams<{ programmeId: string }>()
  const [programmeIntensities, setProgrammeIntensities] = useState<Intensity[]>([])
  const [hustlerProgramme, setHustlerProgramme] = useState<HustlerProgramme>()
  const [showProgressReport, onShowProgressReport] = useState<boolean>(false)
  const [selectedWorkout, setSelectedWorkout] = useState<ProgrammeWorkout | undefined>(undefined)
  const [programmeWorkouts, setProgrammeWorkouts] = useState<ProgrammeWorkout[]>([])
  const [completedProgrammeWorkouts, setCompletedProgrammeWorkouts] = useState<CompletedProgrammeWorkout[]>([])

  const history = useHistory()

  useEffect(() => {
    coreDao.listProgrammeIntensities().then((response) => {
      setProgrammeIntensities(response)
    })
  }, [])

  useEffect(() => {
    coreDao
      .getHustlerProgramme({ programmeId })
      .then((response) => {
        setHustlerProgramme(response)
      })
      .catch(() => history.replace(`/programmes`))

    coreDao.getProgrammeWorkouts(programmeId).then((response) => {
      setProgrammeWorkouts(response)
    })

    coreDao.getMyCompletedProgrammeWorkoutsFromProgramme(programmeId).then((response) => {
      setCompletedProgrammeWorkouts(response)
    })
  }, [programmeId, history])
  const selectWorkout = (programmeWorkout: ProgrammeWorkout): void => {
    coreDao.getWorkoutById(programmeWorkout.workoutId).then((response) => {
      setSelectedWorkout({ ...programmeWorkout, workout: response })
    })
  }

  if (!hustlerProgramme) {
    return <div>Loading...</div>
  }

  return (
    <PageContainer>
      <div className={'mt-4'}>
        <BackArrow />
        <div className={`p-0 mt-4`}>
          <Row>
            <Col xl={6}>
              <ProgrammeInfoContainer
                showProgressReport={showProgressReport}
                onShowProgressReport={onShowProgressReport}
                hustlerProgramme={hustlerProgramme}
              />
            </Col>
            <Col xl={6}>
              {!showProgressReport ? (
                <WorkoutsContainer
                  selectWorkout={selectWorkout}
                  hustlerProgramme={hustlerProgramme}
                  programmeWorkouts={programmeWorkouts}
                  completedProgrammeWorkouts={completedProgrammeWorkouts}
                  programmeIntensities={programmeIntensities}
                />
              ) : (
                <ProgressReport
                  hustlerProgramme={hustlerProgramme}
                  programmeWorkouts={programmeWorkouts}
                  completedProgrammeWorkouts={completedProgrammeWorkouts}
                />
              )}
            </Col>
          </Row>
        </div>
        <WorkoutPopup
          setOnClose={() => setSelectedWorkout(undefined)}
          selectedWorkout={selectedWorkout?.workout}
          startWorkoutLink={
            selectedWorkout
              ? `/programmes/${programmeId}/programme-workouts/${selectedWorkout.id}/workouts/${selectedWorkout.workoutId}`
              : undefined
          }
        />
      </div>
    </PageContainer>
  )
}

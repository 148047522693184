import styles from './programme-progress-report-button.module.scss'
import React from 'react'
import { ProgrammeLineChartSvgIcon } from 'src/component/util/images'

interface ProgressReportButtonProps {
  showProgressReport: boolean
  onShowProgressReport: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProgressReportButton = ({
  showProgressReport,
  onShowProgressReport,
}: ProgressReportButtonProps): React.ReactElement => {
  return (
    <div
      className={`${styles.container} ${showProgressReport && styles.active}`}
      onClick={() => onShowProgressReport((prev) => !prev)}
    >
      <ProgrammeLineChartSvgIcon width={15} height={14} />
      Progress Report
    </div>
  )
}

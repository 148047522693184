import { SessionContext } from '../../context/session-context'
import { Workout, coreDao } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { fileService } from '../../service/file-service'
import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import { logger } from '../../util/logger'
import style from './workout-video.module.scss'
import React, { ForwardedRef, RefObject, forwardRef, memo, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap'
import ReactHlsPlayer from 'react-hls-player'

export const WorkoutVideo = ({
  workout,
  programmeId,
  programmeWorkoutId,
  onVideoCompleted,
}: {
  workout: Workout
  programmeId?: string
  programmeWorkoutId?: string
  onVideoCompleted?: () => void
}): React.ReactElement => {
  const VIDEO_COMPLETED_PERCENT = 0.75
  const { hasActiveSubscription, toggleSubscriptionModal, completeWorkout } = useContext(SessionContext)

  const playerRef = useRef<HTMLVideoElement>(null)
  const isCompletedRef = useRef(false)
  const isCompletedWorkoutRequestSentRef = useRef(false)
  const isVideoPlayingRef = useRef(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleVideoStart = (): void => {
    if (!hasActiveSubscription && playerRef.current) {
      playerRef.current.pause()
      toggleSubscriptionModal()
    }
    logger.debug('handleVideoStart')
    if (!isVideoPlayingRef.current) {
      eventBusUtil().emit({
        action: EventBusActionType.CREATE,
        entity: EventBusEntityType.VIDEO_PLAYBACK,
        meta: { workout },
      })
    }
    isVideoPlayingRef.current = true
  }

  const handleVideoEnd = (): void => {
    logger.debug('handleVideoEnd')
    setShowConfirmationModal(isCompletedRef.current)
    isVideoPlayingRef.current = false
  }
  const workoutCompletedRequest = (): Promise<unknown> => {
    if (programmeId && programmeWorkoutId) {
      return coreDao.completeProgrammeWorkout({
        programmeId,
        programmeWorkoutId,
        maxHeartRate: 0,
        calories: 0,
        averageHeartRate: 0,
        hustleScore: 0,
      })
    } else {
      return coreService.completeWorkout({ workoutId: workout.id })
    }
  }
  const handleProgress = (_event: any): void => {
    if (!playerRef.current) return

    if (playerRef.current.duration * VIDEO_COMPLETED_PERCENT < playerRef.current.currentTime) {
      if (!isCompletedRef.current) {
        // To notify outside of this component that workout is completed via eventBus
        isCompletedRef.current = true
        eventBusUtil().emit({
          action: EventBusActionType.REMOVE,
          entity: EventBusEntityType.VIDEO_PLAYBACK,
          meta: { workout },
        })

        if (onVideoCompleted) onVideoCompleted()
      }

      if (!isCompletedWorkoutRequestSentRef.current) {
        // To notify our server that workout is completed
        // We had a problem that this request failed, and we were sending to mixpanel multiple times
        // See More https://hurricanestudio.atlassian.net/browse/GRND-1417
        isCompletedWorkoutRequestSentRef.current = true
        workoutCompletedRequest()
          .then(() => {
            logger.debug('completeWorkout')
            completeWorkout(workout)
          })
          .catch((_error) => {
            isCompletedWorkoutRequestSentRef.current = false
          })
      }
    }
  }

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener('play', handleVideoStart)
      playerRef.current.addEventListener('ended', handleVideoEnd)
      playerRef.current.addEventListener('timeupdate', handleProgress)
    }
  }, [])
  if (!workout.videoUrl) {
    return <></>
  }
  return (
    <Row className="align-items-center text-center">
      <Col>
        {hasActiveSubscription ? (
          <VideoPlayer videoUrl={workout.videoUrl} imagePublicFileId={workout.imagePublicFileId} ref={playerRef} />
        ) : (
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault()
              toggleSubscriptionModal()
            }}
          >
            <Image src={fileService.publicUrlFromFileId(workout.imagePublicFileId)} fluid />
          </a>
        )}
        <div className={style.workoutTitle}>{workout.title}</div>
        <Modal
          show={showConfirmationModal}
          centered={true}
          contentClassName={style.modal}
          className="align-items-center text-center"
        >
          <div className={style.modalTitle}>Great Work!</div>
          <div className={style.modalText}>
            Well done with your workout! To see your stats and create a community post please download our app.
          </div>
          <div>
            <Button onClick={() => setShowConfirmationModal(false)}>Finish</Button>
          </div>
        </Modal>
      </Col>
    </Row>
  )
}
type VideoPlayerProps = {
  videoUrl: string
  imagePublicFileId: string
}
const VideoPlayer = memo(
  forwardRef((props: VideoPlayerProps, ref: ForwardedRef<HTMLVideoElement>) => {
    const DEFAULT_QUALITY_LEVEL = 2
    return (
      <ReactHlsPlayer
        src={props.videoUrl}
        autoPlay={false}
        controls={true}
        playerRef={ref as RefObject<HTMLVideoElement>}
        width="100%"
        height="auto"
        poster={fileService.publicUrlFromFileId(props.imagePublicFileId)}
        hlsConfig={{
          startLevel: DEFAULT_QUALITY_LEVEL,
        }}
        className={style.videoPlayer}
      />
    )
  }),
)

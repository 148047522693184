import { accessTokenService, firebaseTokenService, refreshTokenService } from './token'

export const tokenService = {
  clear: async (): Promise<void> => {
    await firebaseTokenService.clear().catch(console.error) // eslint-disable-line no-console
    accessTokenService.clear()
    refreshTokenService.clear()
  },
  store: (params: { accessToken?: string; refreshToken?: string; firebaseToken?: string }): void => {
    const { accessToken, refreshToken, firebaseToken } = params
    if (accessToken) accessTokenService.store(accessToken)
    if (refreshToken) refreshTokenService.store(refreshToken)
    if (firebaseToken) firebaseTokenService.store(firebaseToken).catch(console.error) // eslint-disable-line no-console
  },
  hasAccessToken: (): boolean => {
    try {
      return !!accessTokenService.get()
    } catch (e) {
      return false
    }
  },
}

import { Spinner } from './spinner'
import React, { ReactNode } from 'react'

type Props = {
  isLoading: boolean
  children: ReactNode | ReactNode[]
}

export const LoadingSpinner = ({ isLoading, children }: Props): React.ReactElement => {
  return isLoading ? <Spinner /> : <>{children}</>
}

import DownloadFromAppStoreImage from '../asset/image/install-app/download-from-app-store.png'
import DownloadFromGooglePlayImage from '../asset/image/install-app/download-from-google-play.png'
import InstallAppMobileFrameImage from '../asset/image/install-app/install-app-mobile-frame.png'
import InstallAppMobileImage from '../asset/image/install-app/install-app-mobile.png'
import styles from './install-mobil-app-popover.module.scss'
import React, { useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export enum InstallMobileMessage {
  ACTION_NOT_SUPPORTED = 'action-not-supported',
  CREATE_ACCOUNT = 'create-account',
}

export const InstallMobilAppPopover = (props: {
  messageVariant?: InstallMobileMessage
  onDismiss?: () => void
}): React.ReactElement => {
  const { messageVariant = InstallMobileMessage.ACTION_NOT_SUPPORTED, onDismiss } = props

  const message = useMemo((): string => {
    switch (messageVariant) {
      case InstallMobileMessage.CREATE_ACCOUNT:
        return 'To create an account download our app on the App Store or Play Store.'
      case InstallMobileMessage.ACTION_NOT_SUPPORTED:
      default:
        return 'This action is not supported on the current platform. Please install the app using your mobile device.'
    }
  }, [messageVariant])

  const renderMessage = (): React.ReactElement => {
    return (
      <>
        <h1>INSTALL APP</h1>
        <p>{message}</p>
        <a href="https://apps.apple.com/us/app/grndhouse-strength-workouts/id1610574426">
          <img className={styles.downloadButton} src={DownloadFromAppStoreImage} alt="App Store Download" />
        </a>
        <br />
        <a href="https://play.google.com/store/apps/details?id=com.grndhouse">
          <img className={styles.downloadButton} src={DownloadFromGooglePlayImage} alt="Google Play Download" />
        </a>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Container fluid="xl" className="h-100">
        <Row className={`h-100 ${styles.rowMinHeight}`}>
          <Col className="h-100 position-relative d-none d-lg-block">
            <img className={`${styles.center} ${styles.installAppImage}`} src={InstallAppMobileImage} alt="Install mobile app" />
          </Col>
          <Col className="h-100  d-none d-lg-block">
            <div className={`${styles.center} ${styles.largeMessageContainer}`}>{renderMessage()}</div>
          </Col>
          <Col className="h-100  d-block d-lg-none">
            <div
              className={`${styles.center} ${styles.mobileFrame}`}
              style={{ backgroundImage: `url(${InstallAppMobileFrameImage})` }}
            >
              <div className={`${styles.center} ${styles.smallMessageContainer}`}>{renderMessage()}</div>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className={styles.dismissButton}
        onClick={() => {
          if (onDismiss) onDismiss()
        }}
      >
        X
      </div>
    </div>
  )
}

import { coreService } from '../../service/core-service'
import { RunningSeriesCard } from './running-series-card'
import style from './training-series-list.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RunningSeries } from 'src/dao/core-dao'

export const RunningSeriesList = (): React.ReactElement => {
  const [trainingSeries, setTrainingSeries] = useState<RunningSeries[]>()

  useEffect(() => {
    coreService.listRunningSeries().then((response) => {
      setTrainingSeries(response)
    })
  }, [])
  const cardsInRow = (trainingSeries?.length ?? 0) % 3 === 0 ? 3 : 2
  return (
    <Row className={`justify-content-between mx-auto w-100 mb-5 ${style.trainingSeriesListContainer}`}>
      {!trainingSeries && <p className={style.noTrainingSeries}>Loading...</p>}
      {trainingSeries?.length === 0 && <p className={style.noTrainingSeries}>No training series to show</p>}
      {(trainingSeries?.length ?? 0) > 0 &&
        trainingSeries?.map((ts) => (
          <Col key={ts.id} className={`${style.trainingSeriesCol} p-1`} md={12 / cardsInRow}>
            <RunningSeriesCard runningSeries={ts} />
          </Col>
        ))}
    </Row>
  )
}

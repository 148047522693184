import { SessionContext } from '../../context/session-context'
import { Workout } from '../../dao/core-dao'
import { WorkoutDuration } from '../../dao/core-dao-v1'
import { renderUtil } from '../../util/render-util'
import { ImagePublicFileId } from '../image/image-public-file-id'
import { WorkoutPopup } from '../programmes/programme-workout-popup'
import { ChevronSvgIcon } from '../util/images'
import { DurationIcon } from '../workout-filter/duration-icon'
import style from './workout-list.module.scss'
import React, { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

export const WorkoutList = ({ workouts, showWorkouts }: { workouts: Workout[]; showWorkouts: boolean }): React.ReactElement => {
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  const { getFirstTrainerFromWorkout } = useContext(SessionContext)

  if (showWorkouts && workouts.length === 0) {
    return <div className={style.noWorkouts}>No workouts found</div>
  }

  return (
    <div className={style.container}>
      {showWorkouts &&
        workouts.map((workout) => {
          const trainer = getFirstTrainerFromWorkout(workout)
          return (
            <React.Fragment key={workout.id}>
              <div key={workout.id} onClick={() => setSelectedWorkout(workout)} className={style.pointer}>
                <Row className="align-items-center">
                  <Col xs={3}>
                    <ImagePublicFileId fileId={workout.imagePublicFileId} className={style.thumbnail} />
                    {workout.duration && (
                      <div className={style.durationIcon}>
                        <DurationIcon duration={workout.duration as unknown as WorkoutDuration} active={false} />
                      </div>
                    )}
                  </Col>
                  <Col>
                    <div className={style.titleText}>{workout.title}</div>
                    {trainer && (
                      <div className={style.trainerText}>
                        {trainer.firstName} {trainer.lastName}
                      </div>
                    )}
                    <div className={style.workoutDetailsText}>{renderUtil.renderIntensity(workout.intensity)} Intensity</div>
                    <div className={style.workoutDetailsText}>{renderUtil.renderEquipment(workout.equipment)}</div>
                  </Col>
                  <Col xs={1}>
                    <ChevronSvgIcon />
                  </Col>
                </Row>
              </div>
              <div className={style.itemRow} />
            </React.Fragment>
          )
        })}
      <WorkoutPopup
        setOnClose={() => setSelectedWorkout(undefined)}
        selectedWorkout={selectedWorkout}
        startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
      />
    </div>
  )
}

import { ImagePublicFileId } from '../image/image-public-file-id'
import { ProgrammeInfoPill } from '../programme-info-pill/programme-info-pill'
import { WorkoutPopup } from '../programmes/programme-workout-popup'
import { WorkoutCompletedCheckmark } from '../programmes/workout-completed-checkmark'
import style from './express-workout-home-card-list.module.scss'
import React, { useContext, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { SessionContext } from 'src/context/session-context'
import { Workout } from 'src/dao/core-dao'

const ExpressWorkoutHomeCard = ({
  workout,
  isLarge,
  selectWorkout,
}: {
  workout: Workout
  isLarge?: boolean
  selectWorkout?: () => void
}): React.ReactElement => {
  const {
    getFirstTrainerFromWorkout,
    hustlerConfig: { showWorkoutDates },
    completedWorkoutIds,
  } = useContext(SessionContext)

  const trainer = useMemo(() => {
    return getFirstTrainerFromWorkout(workout)
  }, [workout, getFirstTrainerFromWorkout])

  const onCardClick = (): void => {
    selectWorkout?.()
  }

  return (
    <div onClick={onCardClick}>
      <div className={`${style.workoutCard} ${isLarge ? style.largeCard : style.smallCard}`}>
        <div className={style.checkmarkContainer}>
          <WorkoutCompletedCheckmark isVisible={completedWorkoutIds.has(workout.id)} />
        </div>
        <ImagePublicFileId fileId={workout.imagePublicFileId} className={style.workoutCardImage} />
        <div className={`p-2 ${style.workoutCardContent}`}>
          <h5 className={style.workoutCardTitle}>{workout.title}</h5>
          <div className={style.workoutCardFooter}>
            <p className={style.trainerName}>{trainer?.firstName}</p>
            {showWorkoutDates && <ProgrammeInfoPill text={`${workout.duration} min`} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ExpressWorkoutHomeCardList = ({ workouts }: { workouts: Workout[] }): React.ReactElement => {
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  const verticalCard = workouts[0]
  const gridCards = workouts.slice(1)

  return (
    <Container fluid className="px-0">
      <Row className="noGutters">
        <Col xs={12} lg={3} className="px-1 py-1 px-lg-1 py-lg-1">
          <ExpressWorkoutHomeCard workout={verticalCard} isLarge={true} selectWorkout={() => setSelectedWorkout(verticalCard)} />
        </Col>
        <Col xs={12} lg={9} className="pl-lg-3">
          <Row>
            {gridCards.map((card, index) => (
              <Col key={index} xs={12} lg={4} className="px-1 py-1">
                <ExpressWorkoutHomeCard workout={card} isLarge={false} selectWorkout={() => setSelectedWorkout(card)} />
              </Col>
            ))}
          </Row>
        </Col>
        <WorkoutPopup
          setOnClose={() => setSelectedWorkout(undefined)}
          selectedWorkout={selectedWorkout}
          startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
        />
      </Row>
    </Container>
  )
}

import { localStorageDao } from '../dao'

export enum StringLocalStorage {
  ACCESS_TOKEN = 'access-token',
  REFRESH_TOKEN = 'refresh-token',
  CLIENT_GUID = 'client-guid',
  FIREBASE_TOKEN = 'firebase-token',
}

export const localStorageService = {
  getString: (key: StringLocalStorage): string => {
    return localStorageDao.get(key)
  },
  setString: (key: StringLocalStorage, value: string): void => {
    localStorageDao.set(key, value)
  },
  remove: (key: StringLocalStorage | string): void => {
    localStorageDao.remove(key)
  },
  isSet: (key: StringLocalStorage | string): boolean => {
    return localStorageDao.isSet(key)
  },
}

import { ChevronSvgIcon } from '../../util/images'
import { FilterItemButton } from '../filter-item-button'
import styles from './body-part-filter-tree.module.css'
import { BodyPartFilterModel } from './body-part-util'
import React, { useState } from 'react'

export function BodyPartFilterTree(props: {
  root: BodyPartFilterModel
  selectedParts: string[]
  handleOnToggle: (id: string) => void
  treeDepth: number
}): React.ReactElement {
  const { root, selectedParts, handleOnToggle, treeDepth } = props

  const getBodyPartFilterChild = (child: BodyPartFilterModel): React.ReactElement => {
    return (
      <BodyPartFilterChild
        key={child.id}
        root={child}
        selectedParts={selectedParts}
        handleOnToggle={handleOnToggle}
        treeDepth={treeDepth}
        level={1}
      />
    )
  }

  return (
    <div>
      <FilterItemButton isActive={selectedParts.includes(root.id)} onClick={() => handleOnToggle(root.id)}>
        {root.label}
      </FilterItemButton>
      {/*      <div
        className={`${filterStyles.filterItemButton} ${styles.rootButton} ${
          selectedParts.includes(root.id) ? filterStyles.filterItemActiveButton : ''
        }`}
        onClick={() => handleOnToggle(root.id)}
      >
      </div>*/}
      <div className={styles.subFiltersContainer}>{root.subFilters.map((child) => getBodyPartFilterChild(child))}</div>
    </div>
  )
}

export function BodyPartFilterChild(props: {
  root: BodyPartFilterModel
  selectedParts: string[]
  handleOnToggle: (id: string) => void
  treeDepth: number
  level: number
}): React.ReactElement {
  const { root, selectedParts, handleOnToggle, treeDepth, level } = props
  const [isOpen, setOpen] = useState(false)
  const handleToggleOpen = (): void => {
    if (root.subFilters.length < 1) return
    setOpen((open) => !open)
  }

  const getBodyPartFilterChild = (child: BodyPartFilterModel): React.ReactElement => {
    return (
      <BodyPartFilterChild
        key={child.id}
        root={child}
        selectedParts={selectedParts}
        handleOnToggle={handleOnToggle}
        treeDepth={treeDepth}
        level={level + 1}
      />
    )
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={selectedParts.includes(root.id) ?? false}
            onChange={() => handleOnToggle(root.id)}
            id={`checkbox-${root.id}`}
          />
          <label className="form-check-label" htmlFor={`checkbox-${root.id}`}>
            {root.label}
          </label>
        </div>
        {level < treeDepth && (
          <span onClick={handleToggleOpen}>
            <ChevronSvgIcon
              className={`${isOpen ? styles.chevronOpen : ''} ${root.subFilters.length ? '' : styles.chevronDisabled}`}
            />
          </span>
        )}
      </div>
      {isOpen && (
        <div className={styles.subFiltersContainer}>{root.subFilters.map((child) => getBodyPartFilterChild(child))}</div>
      )}
    </div>
  )
}

import { useLocalPathName } from '../../hook/use-local-path-name'
import styles from './profile-nav.module.scss'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ProfileNav = (): React.ReactElement => {
  const [pathName] = useLocalPathName()

  const renderItem = (params: { title: string; path: string }): React.ReactElement => {
    const { title, path } = params
    return (
      <Nav.Item className={path === pathName ? styles.active : ''}>
        <Nav.Link as={Link} to={path}>
          {title}
        </Nav.Link>
      </Nav.Item>
    )
  }

  return (
    <div className={`text-center border-bottom border-tertiary-grey ${styles.container}`}>
      <div className={styles.navbar}>
        {renderItem({ title: 'Overview', path: '/profile/overview' })}
        {renderItem({ title: 'Workouts', path: '/profile/workouts' })}
        {renderItem({ title: 'Awards', path: '/profile/awards' })}
        {renderItem({ title: 'Personal info', path: '/profile/personal' })}
      </div>
    </div>
  )
}

import { ProfileActiveSubscription } from '../../component/profile/profile-active-subscription'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const SettingsMembershipScreen = (): React.ReactElement => {
  return (
    <Row>
      <Col>
        <ProfileActiveSubscription />
      </Col>
    </Row>
  )
}

import { useEffect, useState } from 'react'
import { ProgrammeWorkoutsAvailability, coreDao } from 'src/dao/core-dao'

export const useProgrammeWorkoutsAvailability = (programmeId: string): any => {
  const [programmeWorkoutsAvailability, setProgrammeWorkoutsAvailability] = useState<ProgrammeWorkoutsAvailability[]>()

  useEffect(() => {
    coreDao.getProgrammeWorkoutsAvailability({ programmeId }).then((response) => {
      setProgrammeWorkoutsAvailability(response)
    })
  }, [programmeId])

  const areProgrammeWorkoutsInWeekLocked = (onWeek: number): boolean | undefined => {
    const onWeekAvailability = programmeWorkoutsAvailability?.find((pwa) => pwa.onWeek === onWeek)
    return onWeekAvailability?.areProgrammeWorkoutsInWeekLocked
  }

  const isWeekLocked = (onWeek: number): boolean | undefined => {
    const onWeekAvailability = programmeWorkoutsAvailability?.find((pwa) => pwa.onWeek === onWeek)
    return onWeekAvailability?.isWeekLocked
  }

  return { areProgrammeWorkoutsInWeekLocked, isWeekLocked }
}

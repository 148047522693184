import PasswordHideEyeIcon from '../../asset/image/icon/password-hide-eye-icon.png'
import PasswordShowEyeIcon from '../../asset/image/icon/password-show-eye-icon.png'
import style from './password-toggle.module.scss'
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

export type PasswordToggleParams = {
  children: React.ReactNode
  onToggle?: (show: boolean) => void
  isVisible?: boolean
}

export type PasswordToggleRefObject = {
  toggle: (show?: boolean) => void
}

export const PasswordToggle = forwardRef(
  ({ children, onToggle, isVisible }: PasswordToggleParams, ref: Ref<PasswordToggleRefObject>) => {
    const [passwordShown, setPasswordShown] = useState(false)

    useImperativeHandle(ref, () => ({
      toggle: handleToggle,
    }))

    const handleToggle = (show?: boolean): void => {
      setPasswordShown((prev) => show ?? !prev)
    }

    useEffect((): void => {
      if (onToggle) onToggle(passwordShown)
    }, [passwordShown])

    useEffect((): void => {
      if (isVisible === undefined) return
      handleToggle(isVisible)
    }, [isVisible])

    return (
      <div className={style.container}>
        {children}
        <div className={style.showHideButton} onClick={() => handleToggle()}>
          {passwordShown ? <img src={PasswordHideEyeIcon} /> : <img src={PasswordShowEyeIcon} />}
        </div>
      </div>
    )

    return (
      <div className={style.container}>
        <div className={style.showHideButton} onClick={() => handleToggle()}>
          {passwordShown ? <img src={PasswordHideEyeIcon} /> : <img src={PasswordShowEyeIcon} />}
        </div>
      </div>
    )
  },
)

import { WorkoutListPage } from '../../component/workout-list-page/workout-list-page'
import { useFilterContext } from '../../context/filter-context'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Workout, WorkoutSection } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const AllWorkoutsBySectionScreen = (): React.ReactElement => {
  const { sectionId } = useParams<{ sectionId: string }>()
  const history = useHistory()
  const [section, setSection] = useState<WorkoutSection>()
  const [workouts, setWorkouts] = useState<Workout[]>()
  const { setSubFilterData } = useFilterContext()
  const location = useLocation()

  useEffect(() => {
    coreService.listWorkoutSections().then((response) => {
      const screenSection = response.find((s) => s.id === sectionId)
      if (!screenSection) {
        history.push('/workouts')
      }
      setSection(screenSection)
    })
    coreService.getWorkouts({ sections: [sectionId] }).then((res) => {
      setWorkouts(res)
    })
  }, [sectionId, history])

  useEffect(() => {
    if (section) {
      setSubFilterData({ filter: { sections: [section.id] }, label: section.label })
    }
    return () => setSubFilterData(undefined)
  }, [section, location, setSubFilterData])

  return <WorkoutListPage workouts={workouts} title={section?.label} />
}

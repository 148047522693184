import { EventBus as EventBusModule } from '../service/event-bus'
import { cacheUtil } from './cache-util'
import { logger } from './logger'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

export enum EventBusEntityType {
  USER = 'user',
  MEMBERSHIP = 'membership',
  SUBSCRIPTION = 'subscription',
  PAYWALL = 'paywall',
  CHECKOUT = 'checkout',
  VIDEO_PLAYBACK = 'video-playback',
}

export enum EventBusActionType {
  CREATE = 'create',
  EDIT = 'edit',
  REMOVE = 'remove',
  CANCEL = 'cancel',
}

export type EventBusMessage = {
  entity: EventBusEntityType
  action: EventBusActionType
  meta: any
}

export class EventBus extends EventBusModule<EventBusMessage> {
  public subscribe(
    params: { entities?: EventBusEntityType[]; actions?: EventBusActionType[] },
    cb: (eventMessage: EventBusMessage) => Promise<void>,
  ): Subscription {
    const { actions, entities } = params

    return this.Observable.pipe(
      filter((eventMessage) => {
        const isEntityMatch = !entities ? true : entities.includes(eventMessage.entity)
        const isActionMatch = !actions ? true : actions.includes(eventMessage.action)
        return isEntityMatch && isActionMatch
      }),
    ).subscribe((eventMessage) => {
      cb(eventMessage).catch((err) => logger.error(err))
    })
  }
}

export const eventBusUtil = cacheUtil.singleton((): EventBus => new EventBus())

import { programmeUtil } from '../../util/programme-util'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ProgrammeCard } from 'src/component/programme-card/programme-card'
import { ProgrammePreviewModal } from 'src/component/programme-preview-modal/programme-preview-modal'
import { HustlerProgramme, Intensity, Programme, ProgrammeDuration } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const ProgrammeList = ({
  programmes,
  startedProgrammes,
}: {
  programmes: Programme[]
  startedProgrammes: HustlerProgramme[]
}): React.ReactElement => {
  const [programmeIntensities, setProgrammeIntensities] = useState<Intensity[]>([])
  const [previewProgramme, setPreviewProgramme] = useState<Programme>()

  const [durations, setDurations] = useState<Map<string, ProgrammeDuration>>(new Map())
  const history = useHistory()

  useEffect(() => {
    coreService.listProgrammeIntensities().then((response) => {
      setProgrammeIntensities(response)
    })
  }, [])
  useEffect(() => {
    if (!programmes.length) return

    programmeUtil.getProgrammeDurations(programmes).then((durationResults) => {
      setDurations(programmeUtil.programmeIdDurationsToMap(durationResults))
    })
  }, [programmes])

  if (!programmes || !programmeIntensities) {
    return <div>Loading...</div>
  }

  const getIntensity = (intensityId: string | undefined): Intensity | undefined => {
    if (!intensityId) {
      return undefined
    }
    return programmeIntensities.find((i) => i.id === intensityId)
  }
  const getDuration = (programmeId: string | undefined): ProgrammeDuration | undefined => {
    if (!programmeId) {
      return undefined
    }
    return durations.get(programmeId)
  }
  const selectProgramme = (p: Programme): void => {
    if (programmeUtil.isProgrammeStarted(p.id, startedProgrammes)) {
      history.push(`/programmes/${p.id}`)
      return
    }
    setPreviewProgramme(p)
  }

  const onStartProgramme = (): void => {
    if (!previewProgramme) {
      return
    }
    coreService.startProgramme(previewProgramme.id).then(() => history.push(`/programmes/${previewProgramme.id}`))
  }

  return (
    <>
      <ProgrammePreviewModal
        programme={previewProgramme}
        toggle={() => setPreviewProgramme(undefined)}
        intensity={getIntensity(previewProgramme?.intensityId)}
        programmeDuration={getDuration(previewProgramme?.id)}
        onStartProgramme={onStartProgramme}
      />
      <Row>
        {programmes.map((p) => (
          <Col key={p.id} md={6} lg={4}>
            <div className="d-flex justify-content-center py-2">
              <ProgrammeCard
                title={p.title}
                intensity={getIntensity(p.intensityId)}
                image={p.imagePublicFileId}
                programmeDuration={durations.get(p.id)}
                onClick={() => selectProgramme(p)}
              />
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

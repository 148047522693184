import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './programme-award-icon.module.scss'
import React from 'react'
import { Award } from 'src/dao/core-dao'

interface ProgrammeAwardIconProps {
  award: Award
  isCompleted: boolean
}

export const ProgrammeAwardIcon = ({ award, isCompleted = false }: ProgrammeAwardIconProps): React.ReactElement => {
  return (
    <div className={styles.awardIconContainer}>
      <ImagePublicFileId fileId={award.imagePublicFileId} className={`${styles.awardIcon} ${!isCompleted && styles.disabled}`} />
      <h4 className={`${!isCompleted && styles.disabled}`}>{award.name}</h4>
    </div>
  )
}

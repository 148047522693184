import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import { toasterUtil } from '../../util/toaster-util'
import styles from './manage-email-notifications.module.scss'
import React, { useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import { messageDao } from 'src/dao/message-dao'

export const ManageEmailNotifications = (): React.ReactElement => {
  const [isEmailNotificationChecked, setIsEmailNotificationChecked] = useState<boolean>()

  useEffect(() => {
    messageDao.getMyOptIn().then((result) => setIsEmailNotificationChecked(result))
  }, [])

  const manageEmailNotifications = (): void => {
    if (isEmailNotificationChecked) {
      eventBusUtil().emit({
        action: EventBusActionType.REMOVE,
        entity: EventBusEntityType.MEMBERSHIP,
        meta: {},
      })
      toasterUtil.success('You have sent a request to unsubscribe from Email Notifications')
    } else {
      eventBusUtil().emit({
        action: EventBusActionType.CREATE,
        entity: EventBusEntityType.MEMBERSHIP,
        meta: {},
      })
      toasterUtil.success('You have sent a request to subscribe from Email Notifications')
    }
    setIsEmailNotificationChecked((prev) => !prev)
  }

  return (
    <FormCheck
      checked={isEmailNotificationChecked}
      onClick={() => manageEmailNotifications()}
      label="Email Notifications"
      className={styles.formCheckReset}
    />
  )
}

import { useLocalPathName } from '../../hook/use-local-path-name'
import { HomeNavSvgIcon, ProgrammesNavSvgIcon, WorkoutsNavSvgIcon } from '../util/images'
import { Logo } from '../util/logo'
import { HeartOutlinedButtonIcon } from './heart-outlined-button-icon'
import { MainNavItem, PathNameMatchType } from './main-nav-item'
import styles from './main-nav.module.scss'
import { NavFilterButton } from './nav-filter-button'
import { ProfileNavAvatarContainer } from './profile-nav-avatar-container'
import React, { forwardRef, useState } from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const MainNav = forwardRef<HTMLElement>((props: unknown, ref): React.ReactElement => {
  const [pathName] = useLocalPathName()
  const [navExpanded, setNavExpanded] = useState(false)

  return (
    <Navbar
      expand="lg"
      className={`py-3 bg-black border-bottom border-tertiary-grey ${styles.halfSizeNavSM}`}
      sticky="top"
      onToggle={setNavExpanded}
      ref={ref}
      expanded={navExpanded}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles.navbarToggleIcon} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <MainNavItem
              currentPath={pathName}
              title="Home"
              path="/"
              icon={<HomeNavSvgIcon />}
              machType={PathNameMatchType.EXACT}
              onClick={() => setNavExpanded(false)}
            />
            <MainNavItem
              currentPath={pathName}
              title="Workouts"
              path="/workouts"
              icon={<WorkoutsNavSvgIcon />}
              onClick={() => setNavExpanded(false)}
            />
            <MainNavItem
              currentPath={pathName}
              title="Programmes"
              path="/programmes"
              icon={<ProgrammesNavSvgIcon />}
              onClick={() => setNavExpanded(false)}
            />
          </Nav>
          <Nav className="ml-auto">
            <div className={`${styles.favoriteButtonContainer} ml-2 my-2 pl-0 ml-lg-0 my-lg-0 pl-lg-0 mr-lg-2`}>
              <Button variant={'outline-secondary'} className={`${styles.favoriteButton}`} href="/favorite-workouts">
                <HeartOutlinedButtonIcon />
              </Button>
            </div>

            <NavFilterButton />
            <ProfileNavAvatarContainer currentPath={pathName} path="/profile" onClick={() => setNavExpanded(false)} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
})

import { Hustler } from '../../dao/core-dao'
import style from './hustler-avatar.module.scss'
import React from 'react'

export enum ProfileAvatarSize {
  S = 's',
  M = 'm',
}

export const HustlerAvatar = (props?: { size?: ProfileAvatarSize; hustler?: Hustler }): React.ReactElement => {
  const { hustler, size = ProfileAvatarSize.S } = props ?? {}

  const renderAvatar = (): React.ReactElement => {
    return <div className={`${style.container} ${style[size]}`}>{renderInitials()}</div>
  }

  const renderInitials = (): React.ReactElement => {
    const { name } = hustler ?? { name: '?' }
    const initials = [name] // [firstName, lastName]
      .filter(Boolean)
      .map((n) => n!.slice(0, 1))
      .join('')
      .toUpperCase()
    return (
      <div className={style.initialsContainer}>
        <span className={style.initials}>{initials}</span>
      </div>
    )
  }

  return <>{renderAvatar()}</>
}

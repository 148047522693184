import { authorizationHeaderInterceptor } from '../../axios/interceptor/authorization-header-interceptor'
import { logoutOnErrorInterceptor } from '../../axios/interceptor/logout-on-error-interceptor'
import { commonDaoFactory } from '../../dao/common-dao-factory'
import { BackendType } from '../../enum/backend-type'
import { HttpResponse } from '../../util/http-util'
import { clientGuidService } from '../client-guid-service'
import { StringLocalStorage, localStorageService } from '../local-storage-service'
import { tokenService } from '../token-service'
import { refreshTokenService } from './refresh-token-service'
import { singleRequestPromiseFactory } from '@beecode/msh-node-util/lib/cache/single-request-promise'

export type AccessTokens = { accessToken: string; refreshToken: string }

export const accessTokenService = {
  store: (token: string): void => {
    localStorageService.setString(StringLocalStorage.ACCESS_TOKEN, token)
  },
  clear: (): void => {
    localStorageService.remove(StringLocalStorage.ACCESS_TOKEN)
  },
  get: (): string => {
    return localStorageService.getString(StringLocalStorage.ACCESS_TOKEN)
  },
  refresh: async (): Promise<AccessTokens> => {
    return _singleRefreshPromise.promise()
  },
}

export const _singleRefreshPromise = singleRequestPromiseFactory(async () => {
  const data = {
    clientGuid: clientGuidService.getOrCreate(),
    refreshToken: refreshTokenService.get(),
  }
  const dao = commonDaoFactory({
    backendType: BackendType.AUTH,
    interceptors: [logoutOnErrorInterceptor, authorizationHeaderInterceptor],
  })
  const result = await dao._http.post<HttpResponse<AccessTokens>>('/refresh-token', data, {
    headers: { 'grant-type': 'refresh-token' },
  })
  const accessTokens = result.data.data[0]
  tokenService.store(accessTokens)
  return accessTokens
})

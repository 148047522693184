import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './filter-icon.module.scss'
import React from 'react'

export type FilterIconModel = {
  filterActiveIconPublicFileId?: string
  filterIconPublicFileId?: string
  label: string
  name: string
}
export const FilterIcon = (props: { filterEntry: FilterIconModel; active: boolean; height?: number }): React.ReactElement => {
  const { filterEntry, active } = props
  const iconFileId = active ? filterEntry.filterActiveIconPublicFileId : filterEntry.filterIconPublicFileId

  return (
    <div className={`${styles.container} ${active ? 'active' : ''}`}>
      {iconFileId && <ImagePublicFileId height={props.height ?? 36} alt={filterEntry.label} fileId={iconFileId} />}
      {!iconFileId && <b>{filterEntry.name}</b>}
    </div>
  )
}

import styles from './legal-agreement-check-screen.module.scss'
import { browserUtil } from '@grndhouse/react-common'
import React, { useEffect } from 'react'

export const LegalAgreementCheckScreen = (): React.ReactElement => {
  const redirectToAcceptLegalsScreen = (): void => {
    browserUtil.redirect('/auth/legal-agreement')
  }

  useEffect(() => {
    redirectToAcceptLegalsScreen()
  }, [])

  return (
    <div className={styles.modal}>
      <h1>legal agreement not verified</h1>
    </div>
  )
}

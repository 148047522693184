import styles from './filter-section.module.scss'
import { ReactElement, ReactNode } from 'react'

export type FilterSectionProps = {
  children: ReactNode
  withSeparator?: boolean
}
export const FilterSection = ({ children, withSeparator = true }: FilterSectionProps): ReactElement => {
  return <div className={`${styles.filterSection} ${withSeparator && styles.withSeparator}`}>{children}</div>
}

import { useFilterContext } from '../../context/filter-context'
import { Intensity } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { FilterIcon } from './filter-icon'
import styles from './intensity-filter.module.scss'
import { SidebarFilterSection } from './sidebar-filter-section'
import React, { useEffect, useState } from 'react'

export const IntensityFilter = (): React.ReactElement => {
  const { workoutFilter, toggleFilterEntry } = useFilterContext()
  const [availableIntensities, setAvailableIntensities] = useState<Intensity[]>([])

  useEffect(() => {
    coreService.listWorkoutIntensities().then((intensities) => setAvailableIntensities(intensities))
  }, [])

  const handleToggleSelectedIntensity = ({ id }: Intensity): void => {
    toggleFilterEntry('intensities', id)
  }

  const renderIntensity = (intensity: Intensity, additionalClass?: string): React.ReactElement => {
    const isActive = workoutFilter?.intensities?.includes(intensity.id) ?? false
    return (
      <span
        className={`${styles.intensityItemContainer} ${additionalClass}`}
        onClick={() => handleToggleSelectedIntensity(intensity)}
      >
        <FilterIcon filterEntry={intensity} active={isActive} />
        <span className={styles.intensityLabelContainer}>{intensity.label}</span>
      </span>
    )
  }
  return <SidebarFilterSection title={'Intensity'} items={availableIntensities} renderItem={renderIntensity} />
}

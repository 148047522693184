import { InstallMobilAppPopover } from '../component/install-mobil-app-popover'
import { MainNav } from '../component/nav/main-nav'
import { ChoosePlanModal } from '../component/profile/choose-plan-modal'
import { LoadingSpinner } from '../component/util/spinner/loading-spinner'
import { FilterSidebar } from '../component/workout-filter/filter-sidebar'
import { FilterContextProvider } from '../context/filter-context'
import { SessionContextProvider } from '../context/session-context'
import { Router } from '../screen/router'
import { authService } from '../service/auth-service'
import { logger } from '../util/logger'
import { WebApp } from './web-app'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

export const App = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [webApp] = useState(new WebApp())
  const history = useHistory()
  const [navElement, setNavElement] = useState<HTMLElement | null>(null)

  const [isInstallAppPopoverDismissed, setIsInstallAppPopoverDismissed] = useState(false)

  useEffect(() => {
    if (!isLoading && !authService.hasUserAcceptedLegalAgreements()) {
      history.push('/legal-agreement-check')
    }
  }, [history, isLoading])

  useEffect(() => {
    setIsLoading(true)
    webApp
      .initiate()
      .then(() => {
        setIsLoading(false)
      })
      .catch((err) => logger.error(err))
  }, [webApp])

  return (
    <LoadingSpinner isLoading={isLoading}>
      <div className="App">
        <SessionContextProvider>
          <FilterContextProvider>
            <MainNav ref={setNavElement} />
            <Container fluid>
              <Router />
              <ChoosePlanModal />
            </Container>
            <FilterSidebar navElement={navElement} />
          </FilterContextProvider>
        </SessionContextProvider>
        <ToastContainer />
        {isMobile && !isInstallAppPopoverDismissed && (
          <InstallMobilAppPopover onDismiss={() => setIsInstallAppPopoverDismissed(true)} />
        )}
      </div>
    </LoadingSpinner>
  )
}

import { Trainer, Workout, coreDao } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { filterUtil } from '../../util/filter-util'
import { LoadingSpinner } from '../util/spinner/loading-spinner'
import { WorkoutList } from '../workout/workout-list'
import { TrainerJumbotron, TrainerJumbotronType } from './trainer-jumbotron'
import React, { useEffect, useState } from 'react'

export const TrainerWorkoutList = (props: { trainerId: string }): React.ReactElement => {
  const { trainerId } = props
  const [workouts, setWorkouts] = useState<Workout[]>([])
  const [trainer, setTrainer] = useState<Trainer>()
  const [showWorkouts, setShowWorkouts] = useState<boolean>(false)

  useEffect(() => {
    coreService
      .getTrainer({ trainerId })
      .then((trainerResponse) => {
        setTrainer(trainerResponse)
      })
      .catch(() => setTrainer(undefined))

    const filter = { trainerIds: [trainerId] }

    coreDao.getWorkouts(filter).then((workouts) => {
      setWorkouts(workouts)
    })

    const isFiltered = filterUtil.isWorkoutFilterApplied(filter)
    setShowWorkouts(isFiltered)
  }, [trainerId])

  return (
    <LoadingSpinner isLoading={!trainer}>
      {trainer && (
        <>
          <TrainerJumbotron trainer={trainer} type={TrainerJumbotronType.TRAINER_CLASSES} />
          <WorkoutList workouts={workouts} showWorkouts={showWorkouts} />
        </>
      )}
    </LoadingSpinner>
  )
}

import { logger } from '../../util/logger'
import { Initiate } from '@beecode/msh-node-app'
import { browserUtil, tokenService } from '@grndhouse/react-common'

export class AuthInitiate extends Initiate {
  public constructor() {
    super()
    this.Logger = logger
  }

  public get Name(): string {
    return 'Auth'
  }

  protected _redirectIfTokenNotPresent(): void {
    if (!tokenService.hasAccessToken()) {
      const currentUrl = browserUtil.location()
      const redirectSearchParams = new URLSearchParams({ redirect: currentUrl })
      browserUtil.redirect(`/auth/login?${redirectSearchParams.toString()}`)
      const errorMessage = 'User is not authorised, redirecting to authorisation page'
      throw Error(errorMessage)
    }
  }

  protected async _destroyFn(): Promise<void> {
    return Promise.resolve(undefined)
  }

  protected async _initFn(): Promise<void> {
    this._redirectIfTokenNotPresent()
  }
}

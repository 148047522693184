import styles from './programme-back-arrow.module.scss'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ProgremmeBackArrowSvgIcon } from 'src/component/util/images'

interface BackArrowProps {
  link: string
  text: string
}
export const BackArrow = ({ link, text }: Partial<BackArrowProps>): React.ReactElement => {
  const history = useHistory()

  return (
    <>
      {link ? (
        <Link to={link} className={styles.container}>
          <BackArrowContent text={text} />
        </Link>
      ) : (
        <div onClick={() => history.goBack()} className={styles.container}>
          <BackArrowContent text={text} />
        </div>
      )}
    </>
  )
}
const BackArrowContent = ({ text }: { text?: string }): React.ReactElement => {
  return (
    <>
      <ProgremmeBackArrowSvgIcon />
      <span className="pl-2">{text ?? 'Back'}</span>
    </>
  )
}

export enum JoiErrorCode {
  ANY_ONLY = 'any.only',
  ANY_EMPTY = 'any.empty',
  STRING_MIN = 'string.min',
  STRING_MAX = 'string.max',
}

export const joiUtil = {
  errorMessageMap: (messageMap: { [key in keyof typeof JoiErrorCode | string]: string }): ((error: any) => any) => {
    return (errors) => {
      errors.forEach((err: any) => {
        const message = messageMap[err.code]
        if (message) err.message = message
      })
      return errors
    }
  },
}

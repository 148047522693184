import { ReactElement } from 'react'

export const HeartOutlinedButtonIcon = (): ReactElement => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.39321 15L2.27403 8.55146C-1.5951 4.68234 4.09251 -2.74638 9.39321 3.26366C14.6939 -2.74638 20.3557 4.70813 16.5124 8.55146L9.39321 15Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export const constant = Object.freeze({
  COLORS: {
    PRIMARY_ORANGE: '#C76633',
    SECONDARY_ORANGE: '#FFA057',
    PRIMARY_GREY: '#1F1F1F',
    SECONDARY_GREY: '#2F2F2F',
    TERTIARY_GREY: '#565656',
    MUSHROOM: '#8B8A85',
    MIST: '#E0E0E0',
    IVORY: '#F2F1EC',
    MONEY_GREY: '#313131',
    ERROR: '#F11A1A',
    WHITE: '#FFFFFF',
    MODAL_LIGHT_BLUE: '#007AFF',
    MODAL_BLUE: '#1181FF',
    MODAL_GREY: '#C3C3C3',
  },
})

import { coreService } from '../../service/core-service'
import styles from './manage-billing.module.scss'
import { browserUtil } from '@grndhouse/react-common'
import React from 'react'

export const ManageBilling = (): React.ReactElement => {
  return (
    <div className={styles.container}>
      <h2>Manage subscription</h2>
      <button
        className={`p-0 mb-0 mt-3 ${styles.manageBtn}`}
        onClick={async () => {
          browserUtil.redirect(await coreService.createPortalSession())
        }}
      >
        Manage
      </button>
    </div>
  )
}

import { useFilterContext } from '../../../context/filter-context'
import { coreService } from '../../../service/core-service'
import { SidebarFilterSection } from '../sidebar-filter-section'
import { BodyPartFilterTree } from './body-part-filter-tree'
import styles from './body-part-filter.module.scss'
import { BodyPartFilterModel, bodyPartUtil } from './body-part-util'
import React, { useEffect, useState } from 'react'

export const BodyPartFilter = (): React.ReactElement => {
  const { workoutFilter, setFilterEntry } = useFilterContext()

  const [bodyParts, setBodyParts] = useState<BodyPartFilterModel[]>([])
  const [rootDepth, setRootDepth] = useState<Record<string, number>>({})

  useEffect(() => {
    coreService.getBodyParts().then((bodyPartsList) => {
      const roots = bodyPartUtil.toGraph(bodyPartsList)
      setBodyParts(roots)
      const depthsById = roots.reduce((acc, root) => {
        acc[root.id] = bodyPartUtil.getFiltersDepth(root)
        return acc
      }, {} as Record<string, number>)
      setRootDepth(depthsById)
    })
  }, [])

  const handleOnBodyPartsToggle = (id: string): void => {
    const prevState = workoutFilter?.bodyParts ?? []

    const selectedBodyParts = bodyPartUtil.getSelectedFilters(bodyParts, prevState, id)

    const selectedPartIds = Object.entries(selectedBodyParts)
      .filter(([_, selected]) => selected)
      .map(([id, _]) => id)

    setFilterEntry('bodyParts', selectedPartIds)
  }

  const renderBodyPartRoot = (root: BodyPartFilterModel, additionalClass?: string): React.ReactElement => {
    return (
      <div className={`${styles.treeContainer} ${additionalClass}`}>
        <BodyPartFilterTree
          root={root}
          selectedParts={workoutFilter?.bodyParts ?? []}
          handleOnToggle={handleOnBodyPartsToggle}
          treeDepth={rootDepth[root.id]}
        />
      </div>
    )
  }

  return <SidebarFilterSection title={'Body Part'} items={bodyParts} lineLimit={1} renderItem={renderBodyPartRoot} />
}

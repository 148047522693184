import styles from './info-tab-header-with-lock.module.scss'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { LockedSvgIcon } from 'src/component/util/images'
import { InfoTabItem } from 'src/dao/core-dao'

interface InfoTabHeaderWithLockProps {
  items: InfoTabItem[]
  selectedItem?: InfoTabItem
  isTabLocked: (item: InfoTabItem) => boolean
  onClick: (item: InfoTabItem) => void
}

export const InfoTabHeaderWithLock = ({
  items,
  selectedItem,
  isTabLocked,
  onClick,
}: InfoTabHeaderWithLockProps): React.ReactElement => {
  const renderInfoTabItem = (item: InfoTabItem): React.ReactElement => {
    const selected = item.order === selectedItem?.order
    return (
      <Row
        onClick={() => onClick(item)}
        className={`${styles.infoTabItemContainer} ${selected && styles.activeTab} ${isTabLocked(item) && styles.isLocked}`}
      >
        {isTabLocked(item) && <LockedSvgIcon width={11} height={14} />}
        <span>Week {item.order}</span>
      </Row>
    )
  }

  return (
    <Container className={styles.container}>
      {items?.map((item) => (
        <Col className={styles.containerItem} key={item.order}>
          {renderInfoTabItem(item)}
        </Col>
      ))}
    </Container>
  )
}

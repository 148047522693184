import { AwardsByProgrammeList } from './awards-by-programme-list'
import { ProgrammeAvailability } from './programme-availability'
import styles from './programme-progress-report.module.scss'
import { ProgrammeWeekSection } from './programme-week-section'
import React, { useCallback } from 'react'
import { CompletedProgrammeWorkout, HustlerProgramme, ProgrammeWorkout } from 'src/dao/core-dao'
import { useProgrammeWorkoutsAvailability } from 'src/hook/use-programme-workouts-availability'

interface ProgressReportProps {
  hustlerProgramme: HustlerProgramme
  programmeWorkouts: ProgrammeWorkout[]
  completedProgrammeWorkouts: CompletedProgrammeWorkout[]
}

export const ProgressReport = ({
  hustlerProgramme,
  programmeWorkouts,
  completedProgrammeWorkouts,
}: ProgressReportProps): React.ReactElement => {
  const { isWeekLocked } = useProgrammeWorkoutsAvailability(hustlerProgramme.programme.id)
  const startedAt = parseInt(hustlerProgramme?.startedAt.toString())

  const completedWorkouts = useCallback((): number => {
    const completedCount = programmeWorkouts.filter((pw) =>
      completedProgrammeWorkouts.map((cpw) => cpw.programmeWorkout.id).includes(pw.id),
    ).length
    return completedCount ?? 0
  }, [completedProgrammeWorkouts, programmeWorkouts])

  const calculateCompletedPercentage = useCallback((): number => {
    const completed = completedWorkouts()
    if (!programmeWorkouts || !completed) return 0
    const percentage = ((completed / programmeWorkouts.length) * 100).toFixed(2) || 0
    return Number(percentage)
  }, [completedWorkouts, programmeWorkouts])

  const completedPercentage = calculateCompletedPercentage()
  const infoTabItems = hustlerProgramme?.programme?.weekDescription

  const programmeWorkoutsPerWeek = (): Record<number, ProgrammeWorkout[]> => {
    if (!infoTabItems) return []
    if (!programmeWorkouts.length) return []

    const myProgrammeWorkouts = programmeWorkouts.map(
      (pw) =>
        ({
          ...pw,
          isCompleted: completedProgrammeWorkouts.map((cpw) => cpw.programmeWorkout.id).includes(pw.id),
        } as ProgrammeWorkout),
    )
    myProgrammeWorkouts.sort((e1, e2) => e1.order - e2.order)

    return myProgrammeWorkouts?.reduce((acc, cur) => {
      acc[cur.onWeek] = acc[cur.onWeek] ?? []
      acc[cur.onWeek].push(cur)
      return acc
    }, {} as Record<number, ProgrammeWorkout[]>)
  }

  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        <span>Progress Report</span>
        <span style={{ fontWeight: 400 }}>/</span>
        <ProgrammeAvailability startedAt={startedAt} numberOfWeeks={hustlerProgramme.programme.weekDescription.length} />
      </div>
      <div className={styles.progressBar}>
        <h4>
          {completedWorkouts()}/{programmeWorkouts.length} DONE
        </h4>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBorders} />
          <div className={styles.progressFullLine}>
            <div className={styles.progressCompletedLine} style={{ width: `${completedPercentage}%` }} />
          </div>
        </div>
      </div>
      <AwardsByProgrammeList programmeId={hustlerProgramme.programme.id} />
      <div>
        {Object.entries(programmeWorkoutsPerWeek()).map(([onWeek, programmeWorkouts]) => {
          return (
            <ProgrammeWeekSection
              key={onWeek}
              onWeek={+onWeek}
              hustlerProgramme={hustlerProgramme}
              workouts={programmeWorkouts}
              isLocked={isWeekLocked(+onWeek)}
            />
          )
        })}
      </div>
    </div>
  )
}

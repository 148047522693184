import { HiddenDivider } from '../../component/divider/hidden-divider'
import { ProfileSettingsNav } from '../../component/nav/profile-settings-nav'
import { ThisMonthStats } from '../../component/this-month-stats/this-month-stats'
import { SettingsLegalScreen } from './settings-legal-screen'
import { SettingsMembershipScreen } from './settings-membership-screen'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'

export const ProfileSettingsScreen = (): React.ReactElement => {
  return (
    <PageContainer>
      <Row className="profile-top-margin">
        <Col xl={3}>
          <Row>
            <Col>
              <ProfileSettingsNav />
            </Col>
          </Row>
          <Row>
            <Col>
              <HiddenDivider />
            </Col>
          </Row>
          <Row>
            <Col>
              <ThisMonthStats />
            </Col>
          </Row>
        </Col>
        <Col>
          <Switch>
            {/* TODO: Removed until page is implemented */}
            {/*<Route exact path="/profile-settings/connect" component={SettingsConnectScreen} />*/}
            <Route exact path="/profile-settings/membership/:membershipStatus?" component={SettingsMembershipScreen} />
            {/* TODO: Removed until page is implemented */}
            {/*<Route exact path="/profile-settings/notifications" component={SettingsNotificationsScreen} />*/}
            {/*<Route exact path="/profile-settings/get-rewarded" component={SettingsGetRewardedScreen} />*/}
            <Route exact path="/profile-settings/legal" component={SettingsLegalScreen} />
            <Redirect to="/profile-settings/profile" />
          </Switch>
        </Col>
      </Row>
    </PageContainer>
  )
}

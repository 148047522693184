/* eslint-disable import/order */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { App } from './app'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import './asset/scss/global.scss'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import { config } from './util/config'

/* eslint-enable import/order */

if (config.sentry)
  Sentry.init({
    environment: window.location.host,
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: config.sentry.sampleRate,
  })

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

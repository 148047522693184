import { mixpanelService } from '../../service/mixpanel-service'
import { logger } from '../../util/logger'
import { Initiate } from '@beecode/msh-node-app'

export class MixpanelInitiate extends Initiate {
  public constructor() {
    super()
    this.Logger = logger
  }

  public get Name(): string {
    return 'Mixpanel'
  }

  protected _destroyFn(): Promise<void> {
    return Promise.resolve(undefined)
  }

  protected async _initFn(): Promise<void> {
    await mixpanelService.init()
  }
}

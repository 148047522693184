import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './profile-programme-awards-card.module.scss'
import React from 'react'
import { Col } from 'react-bootstrap'
import { AwardDto } from 'src/dao/core-dao'

export const ProfileProgrammeAwardsCard = ({ programmeAward }: { programmeAward: AwardDto }): React.ReactElement => {
  return (
    <Col xs={12} sm={6} xl={4}>
      <div className={styles.container}>
        <div className={`${styles.score} ${styles[programmeAward?.award.name]}`}>{programmeAward.count ?? 0}</div>
        <ImagePublicFileId fileId={programmeAward?.award.imagePublicFileId} className={styles.badgeImage} />
        <h3>{programmeAward?.award.name}</h3>
      </div>
    </Col>
  )
}

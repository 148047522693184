import educationImage from '../../asset/image/louis-barbell-grnd-101.jpg'
import { SessionContext } from '../../context/session-context'
import style from './education.module.scss'
import { useContext, useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { WorkoutCard } from 'src/component/programmes/programme-workout-card'
import { EducationHubCategory } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const EducationScreen = (): React.ReactElement => {
  const { completedWorkoutIds } = useContext(SessionContext)
  const [educationCategory, setEducationCategory] = useState<EducationHubCategory>()

  useEffect(() => {
    coreService.listEducationHubCategories().then((response) => {
      setEducationCategory(response)
    })
  }, [])

  return (
    <PageContainer>
      <Row className={`${style.educationHeader} justify-content-between mx-auto my-3 w-100`}>
        <Image src={educationImage} className={`${style.educationImage} p-2 w-100`} />
        <div className={`${style.educationHeaderContent} p-5 justify-content-between d-flex flex-column`}>
          <BackArrow />
          <div>
            <h1>education grnd 101</h1>
            {!educationCategory && <p>Loading...</p>}
            <p>{educationCategory && educationCategory.title}</p>
          </div>
        </div>
      </Row>
      <Row className={`justify-content-between mx-auto w-100 mb-5`}>
        {!educationCategory && <p>Loading</p>}
        {educationCategory &&
          educationCategory.workouts.map((w) => (
            <Col key={w.id} md={6} lg={4} className="p-0">
              <Link to={`/workouts/${w.id}`}>
                <div className="d-flex justify-content-center p-2 h-100">
                  <WorkoutCard workout={w} isCompleted={completedWorkoutIds.has(w.id)} isLocked={false} size="small" />
                </div>
              </Link>
            </Col>
          ))}
      </Row>
    </PageContainer>
  )
}

import { WorkoutFilter } from '../service/core-service'

export const filterUtil = {
  isWorkoutFilterApplied: (filter: WorkoutFilter): boolean => {
    return Object.values(filter)
      .filter(Array.isArray)
      .some((val) => {
        return val.length !== 0
      })
  },
}

import styles from './overlay-spinner.module.scss'
import { Spinner } from './spinner'
import React from 'react'

export const OverlaySpinner: React.FC = () => {
  return (
    <div className={styles.overlaySpinner}>
      <Spinner />
    </div>
  )
}

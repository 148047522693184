import { LoadingSpinner } from '../util/spinner/loading-spinner'
import style from './workout-list-page.module.scss'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { WorkoutCarouselCard } from 'src/component/workout-carousel-card/workout-carousel-card'
import { Workout } from 'src/dao/core-dao'

export type WorkoutListPageProps = {
  title?: string
  workouts?: Workout[]
}
export const WorkoutListPage = ({ title, workouts }: WorkoutListPageProps): React.ReactElement => {
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  return (
    <PageContainer>
      <div className="mt-5">
        <LoadingSpinner isLoading={!title}>
          <Row className="justify-content-between align-items-center mb-5">
            <BackArrow />
            <h1 className={style.allWorkoutsTitle}>{title}</h1>
            <div />
          </Row>
        </LoadingSpinner>
        <LoadingSpinner isLoading={!workouts}>
          <Row>
            {workouts && workouts.length < 1 && <p className={style.noWorkoutsLabel}>No Workouts Found</p>}
            {workouts &&
              workouts.length > 0 &&
              workouts.map((w) => (
                <Col className="px-1" key={w.id} md={6} lg={4}>
                  <div className="d-flex justify-content-center py-2">
                    <WorkoutCarouselCard workout={w} isNew={false} selectWorkout={() => setSelectedWorkout(w)} showPopup={true} />
                  </div>
                </Col>
              ))}
          </Row>
        </LoadingSpinner>
      </div>
      <WorkoutPopup
        setOnClose={() => setSelectedWorkout(undefined)}
        selectedWorkout={selectedWorkout}
        startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
      />
    </PageContainer>
  )
}

import { SessionContext } from '../../context/session-context'
import { coreService } from '../../service/core-service'
import { CarouselSection } from '../carousel-section/carousel-section'
import { ForwardArrowLink } from '../forward-arrow-link/forward-arrow-link'
import style from './new-workouts-section.module.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Workout } from 'src/dao/core-dao'

export const NewWorkouts = (): React.ReactElement => {
  const [newWorkouts, setNewWorkouts] = useState<Workout[]>()
  const {
    hustlerConfig: { newWorkoutsTitle, showNewMonthlyWorkouts },
  } = useContext(SessionContext)

  useEffect(() => {
    coreService.getWorkouts({ preset: 'new-workouts' }).then((response) => {
      setNewWorkouts(response)
    })
  }, [])

  return (
    <>
      {newWorkouts ? (
        <NewWorkoutsSection
          newWorkouts={newWorkouts}
          newWorkoutsTitle={newWorkoutsTitle}
          showNewMonthlyWorkouts={showNewMonthlyWorkouts}
        />
      ) : (
        <p className="text-black">Loading</p>
      )}
    </>
  )
}

const NewWorkoutsSection = ({
  newWorkouts,
  newWorkoutsTitle,
  showNewMonthlyWorkouts,
}: {
  newWorkouts: Workout[]
  newWorkoutsTitle: string
  showNewMonthlyWorkouts: boolean
}): React.ReactElement => (
  <Container className={style.newWorkoutsContainer}>
    <Row className="d-flex justify-content-between align-items-center">
      <h2 className={style.title}>{newWorkoutsTitle ?? 'New Workouts'}</h2>
      {showNewMonthlyWorkouts && <ForwardArrowLink text="See All" link="/workouts-preset/new-workouts-expanded" />}
    </Row>
    <Row>
      {newWorkouts.length < 1 ? (
        <p className="my-3 text-black font-weight-bold text-center">No new workouts found</p>
      ) : (
        <CarouselSection workoutsList={newWorkouts} isNew={true} />
      )}
    </Row>
  </Container>
)

import { ForwardArrowSvgIcon } from '../util/images'
import styles from './forward-arrow-link.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'

interface ForwardArrowLinkProps {
  link: string
  text: string
}

export const ForwardArrowLink = ({ link, text }: ForwardArrowLinkProps): React.ReactElement => {
  return (
    <Link to={link} className={styles.container}>
      <span>{text}</span>
      <ForwardArrowSvgIcon className={styles.arrow} />
    </Link>
  )
}

export type AnyFunctionNoParams<R = any> = () => R

export const cacheUtil = {
  singleton: <R = any>(fun: AnyFunctionNoParams<R>): AnyFunctionNoParams<R> => {
    const cache: { [k: string]: R } = {}
    const key = 'singleton'

    return (): R => {
      if (key in cache) return cache[key]

      const result = fun()
      cache[key] = result
      return result
    }
  },
}

import style from './page-container.module.scss'
import { Container } from 'react-bootstrap'

export const PageContainer = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => {
  return (
    <Container fluid className={style.pageContainer}>
      {children}
    </Container>
  )
}

import { AwardsStatsIcon } from '../../asset/image/icon/stats/awards-stats-icon'
import { ClassesStatsIcon } from '../../asset/image/icon/stats/classes-stats-icon'
import { CompletedChallenge, CompletedWorkout } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { logger } from '../../util/logger'
import { constant } from '../util/constant'
import { OverlaySpinner } from '../util/spinner/overlay-spinner'
import styles from './this-month-stats.module.scss'
import { endOfMonth, startOfMonth } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'

export const ThisMonthStats = (): React.ReactElement => {
  const [completedChallenges, setCompletedChallenges] = useState<CompletedChallenge[]>([])
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkout[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const today = new Date()

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      coreService.fetchCompletedChallenges().then((c) => setCompletedChallenges(c)),
      coreService
        .getCompletedWorkouts({
          filter: {
            completedAtAfter: startOfMonth(today).getTime(),
            completedAtBefore: endOfMonth(today).getTime(),
          },
        })
        .then((c) => setCompletedWorkouts(c)),
    ])
      .then(() => {
        setIsLoading(false)
      })
      .catch((err) => {
        logger.error(err)
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <h1 className={styles.header}>This month</h1>
      <div className={`panel panel-radius ${styles.panel}`}>
        <Table borderless>
          <tbody>
            <tr>
              <td className="align-middle text-right">
                <ClassesStatsIcon width="32px" height="32px" color={constant.COLORS.PRIMARY_ORANGE} />
              </td>
              <td className={`align-middle text-left ${styles.text}`}>Classes</td>
              <td className={`align-middle text-left ${styles.number}`}>{completedWorkouts.length}</td>
            </tr>
            <tr>
              <td className="align-middle text-right">
                <AwardsStatsIcon width="32px" height="32px" color={constant.COLORS.PRIMARY_ORANGE} />
              </td>
              <td className={`align-middle text-left ${styles.text}`}>Awards</td>
              <td className={`align-middle text-left ${styles.number}`}>{completedChallenges.length}</td>
            </tr>
          </tbody>
        </Table>
        {isLoading && <OverlaySpinner />}
      </div>
    </>
  )
}

import { HustlerAvatar } from '../profile/hustler-avatar'
import styles from './profile-nav-avatar-container.module.scss'
import { useContext } from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SessionContext } from 'src/context/session-context'

interface ProfileNavAvatar {
  path: string
  currentPath?: string | null
  onClick?: () => void
}

export const ProfileNavAvatarContainer = ({ path, currentPath, onClick }: ProfileNavAvatar): React.ReactElement => {
  const { me } = useContext(SessionContext)

  return (
    <Nav.Link
      as={Link}
      to={path}
      className={`p-0 d-flex justify-content-center align-items-center ml-2 my-2 ml-lg-0 my-lg-0 ${styles.container} ${
        currentPath?.startsWith(path) && styles.active
      }`}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <HustlerAvatar hustler={me} />
    </Nav.Link>
  )
}

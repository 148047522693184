import { AllWorkoutsBySectionScreen } from './all-workouts-by-section/all-workouts-by-section-screen'
import { AwardsScreen } from './awards-screen'
import { CheckoutScreen } from './checkout-screen'
import { EducationScreen } from './education/education'
import { HomeScreen } from './home-screen'
import { LegalAgreementCheckScreen } from './legal-agreement-check-screen'
import { ProfileSettingsScreen } from './profile-settings/profile-settings-screen'
import { FavoriteWorkoutsScreen } from './profile/overview/favorite-workouts-screen'
import { PrivacyPolicyScreen } from './profile/personal-info/privacy-policy'
import { TermsConditionsScreen } from './profile/personal-info/terms-conditions'
import { ProfileScreen } from './profile/profile-screen'
import { ProgrammeWorkoutScreen } from './programme-workout/programme-workout-screen'
import { ProgrammeListScreen } from './programme/programme-list-screen'
import { ProgrammeOverviewScreen } from './programme/programme-overview-screen'
import { SignOutScreen } from './sign-out-screen'
import { TrainerScreen } from './trainer/trainer-screen'
import { TrainerWorkoutsScreen } from './trainer/trainer-workouts-screen'
import { TrainingSeriesListScreen } from './training-series-list-screen/training-series-list-screen'
import { TrainingSeriesOverviewScreen } from './training-series-overview/training-series-overview'
import { WorkoutPresetScreen } from './workout-preset-screen/workout-preset-screen'
import { WorkoutListScreen } from './workout/workout-list-screen'
import { WorkoutScreen } from './workout/workout-screen'
import { WorkoutsFilterResultScreen } from './workouts-filter-result/workouts-filter-result-screen'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// TODO feature-flag-community
// import { CommunityScreen } from './community-screen'

export const Router = (): React.ReactElement => (
  <Switch>
    <Route exact path="/" component={HomeScreen} />
    <Route exact path="/workouts" component={WorkoutListScreen} />
    <Route exact path="/workouts/:workoutId" component={WorkoutScreen} />
    <Route exact path="/workouts-preset/:preset" component={WorkoutPresetScreen} />
    <Route exact path="/workouts/section/:sectionId" component={AllWorkoutsBySectionScreen} />
    <Route exact path="/workouts-filter-result" component={WorkoutsFilterResultScreen} />
    <Route exact path="/trainers/:trainerId" component={TrainerScreen} />
    <Route exact path="/trainers/:trainerId/workouts" component={TrainerWorkoutsScreen} />
    <Route exact path="/awards" component={AwardsScreen} />
    <Route exact path="/programmes" component={ProgrammeListScreen} />
    <Route exact path="/programmes/:programmeId" component={ProgrammeOverviewScreen} />
    <Route exact path="/education" component={EducationScreen} />
    <Route
      exact
      path="/programmes/:programmeId/programme-workouts/:programmeWorkoutId/workouts/:workoutId"
      component={ProgrammeWorkoutScreen}
    />
    {/* TODO feature-flag-community: uncomment community when we turn on community */}
    {/*<Route exact path="/community" component={CommunityScreen} />*/}
    <Route exact path="/training-series" component={TrainingSeriesListScreen} />
    <Route exact path="/training-series/:trainingSeriesId" component={TrainingSeriesOverviewScreen} />
    <Route path="/profile-settings" component={ProfileSettingsScreen} />
    <Route path="/favorite-workouts" component={FavoriteWorkoutsScreen} />
    <Route path="/profile" component={ProfileScreen} />
    <Route path="/checkout" component={CheckoutScreen} />
    <Route exact path="/sign-out" component={SignOutScreen} />
    <Route exact path="/legal-agreement-check" component={LegalAgreementCheckScreen} />
    <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
    <Route exact path="/terms-conditions" component={TermsConditionsScreen} />
    <Redirect to="/" />
  </Switch>
)

import { accessTokenService } from '../service/token'

export const axiosUtil = {
  getAuthorizationHeaderKey: (): { Authorization: string } | undefined => {
    try {
      const token = accessTokenService.get()
      return { Authorization: `Bearer ${token}` }
    } catch (e) {
      return undefined
    }
  },
}

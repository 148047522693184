import { InterceptorFunction } from '../axios'
import { authorizationHeaderInterceptor } from '../axios/interceptor/authorization-header-interceptor'
import { refreshTokenInterceptor } from '../axios/interceptor/refresh-token-interceptor'
import { BackendType } from '../enum/backend-type'
import { CommonDao, commonDaoFactory } from './common-dao-factory'

export const fileDaoFactory = ({
  onError,
  interceptors = [],
}: {
  onError?: (error: Error) => Promise<void>
  interceptors?: InterceptorFunction[]
} = {}): CommonDao =>
  commonDaoFactory({
    backendType: BackendType.FILE,
    onError,
    interceptors: [...interceptors, authorizationHeaderInterceptor, refreshTokenInterceptor],
  })

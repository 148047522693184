import { useFilterContext } from '../../context/filter-context'
import { Trainer } from '../../dao/core-dao'
import { SidebarFilterSection } from './sidebar-filter-section'
import { TrainerIcon } from './trainer-icon'
import React from 'react'

export const TrainerFilter = (props: { trainers: Trainer[] }): React.ReactElement => {
  const { trainers } = props
  const { workoutFilter, toggleFilterEntry } = useFilterContext()

  const handleToggleSelectedTrainerId = (toggleValue: string): void => {
    toggleFilterEntry('trainerIds', toggleValue)
  }

  const renderTrainer = (trainer: Trainer, additionalClass?: string): React.ReactElement => {
    return (
      <span className={additionalClass} onClick={() => handleToggleSelectedTrainerId(trainer.id)}>
        <TrainerIcon trainer={trainer} active={workoutFilter?.trainerIds?.includes(trainer.id) ?? false} />
      </span>
    )
  }
  return <SidebarFilterSection title={'Trainer'} items={trainers} renderItem={renderTrainer} />
}

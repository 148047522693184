import { ImagePublicFileId } from '../image/image-public-file-id'
import { ProgrammeInfoPill } from '../programme-info-pill/programme-info-pill'
import { WorkoutCompletedCheckmark } from '../programmes/workout-completed-checkmark'
import { WorkoutCarouselCardNewBadge } from './workout-carousel-card-new-badge'
import style from './workout-carousel-card.module.scss'
import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SessionContext } from 'src/context/session-context'
import { Workout } from 'src/dao/core-dao'
import { timeUtil } from 'src/util/time-util'

interface WorkoutCarouselCardProps {
  workout: Workout
  isNew: boolean
  showPopup?: boolean
  selectWorkout?: (workout: Workout) => void
  hideDate?: boolean
}

export const WorkoutCarouselCard = ({
  workout,
  isNew,
  showPopup = false,
  selectWorkout,
  hideDate = false,
}: WorkoutCarouselCardProps): React.ReactElement => {
  const {
    getFirstTrainerFromWorkout,
    completedWorkoutIds,
    hustlerConfig: { showWorkoutDates },
  } = useContext(SessionContext)

  const trainer = useMemo(() => {
    return getFirstTrainerFromWorkout(workout)
  }, [workout, getFirstTrainerFromWorkout])

  let availableAt
  if (workout.availableAt) {
    const timestamp = parseInt(workout.availableAt)
    const date = new Date(timestamp).toString()
    availableAt = timeUtil.stringToDateFormat(date)
  }

  const workoutCardContent = (
    <div className={style.container}>
      {isNew && <WorkoutCarouselCardNewBadge />}
      <div className={style.WorkoutCarouselCardWrapper}>
        <div className={style.checkmarkContainer}>
          <WorkoutCompletedCheckmark isVisible={completedWorkoutIds.has(workout.id)} />
        </div>
        <ImagePublicFileId fileId={workout.imagePublicFileId} className={style.cardImage} />
        <div className={style.cardContent}>
          <p className={style.cardDate}>{!hideDate && showWorkoutDates && availableAt?.toString()}</p>
          <div className={style.cardBottom}>
            <h3>{workout.title}</h3>
            <div className={style.cardFooter}>
              <p className={style.trainerName}>{trainer?.firstName}</p>
              <ProgrammeInfoPill text={`${workout.duration} min`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {showPopup ? (
        <div onClick={() => selectWorkout?.(workout)}>{workoutCardContent}</div>
      ) : (
        <Link to={`/workouts/${workout.id}`}>{workoutCardContent}</Link>
      )}
    </>
  )
}

import { CompletedWorkoutFilter, WorkoutFilter } from '../service/core-service-v1'
import { errorUtil } from '../util/error-util'
import { HttpResponse, coreDaoV1Factory } from '@grndhouse/react-common'

// TODO Remove old filters after launch of new filters
const dao = coreDaoV1Factory({
  onError: async (error: Error) => {
    errorUtil.handleHttpError(error)
  },
})
export const coreDao = {
  ...dao,
  getWorkouts: async (filter?: WorkoutFilter): Promise<Workout[]> => {
    const url = '/workouts'
    const result = await dao._http.get<HttpResponse<Workout>>(url, { params: filter })
    return result.data.data
  },
  getForDayWorkouts: async (filter?: WorkoutFilter): Promise<Workout[]> => {
    const url = '/workouts/for-day'
    const result = await dao._http.get<HttpResponse<Workout>>(url, { params: filter })
    return result.data.data
  },
  getForWeekWorkouts: async (filter?: WorkoutFilter): Promise<WorkoutSummary[]> => {
    const url = '/workouts/for-week'
    const result = await dao._http.get<HttpResponse<WorkoutSummary>>(url, { params: filter })
    return result.data.data
  },
  getWorkoutById: async (workoutId: string): Promise<Workout> => {
    const url = `/workouts/${workoutId}`
    const result = await dao._http.get<HttpResponse<Workout>>(url)
    return result.data.data[0]
  },
  completeWorkout: async (workoutId: string): Promise<CompletedWorkout> => {
    const url = `/completed-workouts`
    const result = await dao._http.post<HttpResponse<CompletedWorkout>>(url, { workoutId })
    return result.data.data[0]
  },
  getHustlerMe: async (): Promise<Hustler> => {
    const url = `/hustlers/me`
    const result = await dao._http.get<HttpResponse<Hustler>>(url)
    return result.data.data[0]
  },
  patchHustlerMe: async (params: { profile: HustlerProfile }): Promise<Hustler> => {
    const url = `/hustlers/me`
    const result = await dao._http.patch<HttpResponse<Hustler>>(url, params)
    return result.data.data[0]
  },
  fetchCompletedChallenges: async (params?: { limit?: number }): Promise<CompletedChallenge[]> => {
    const { limit } = params ?? {}
    const url = `/hustlers/me/challenges/completed`
    const result = await dao._http.get<HttpResponse<CompletedChallenge>>(url, { params: { limit: limit } })
    return result.data.data
  },
  createCheckoutSession: async (priceId: string): Promise<string> => {
    const url = `/payments/checkout-session`
    const result = await dao._http.post<HttpResponse<string>>(url, { priceId })
    return result.data.data[0]
  },
  createPortalSession: async (): Promise<string> => {
    const url = `/payments/portal-session`
    const result = await dao._http.post<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getPrices: async (): Promise<Price[]> => {
    const url = `/payments/prices`
    const result = await dao._http.get<HttpResponse<Price>>(url)
    return result.data.data
  },
  getMySubscriptions: async (): Promise<StoreSubscription | undefined> => {
    const url = `/payments/me/subscriptions`
    const result = await dao._http.get<HttpResponse<StoreSubscription | undefined>>(url)
    return result.data.data[0]
  },
  getStripeSubscriptions: async (): Promise<Subscription[]> => {
    const url = `/payments/stores/stripe/subscriptions`
    const result = await dao._http.get<HttpResponse<Subscription[]>>(url)
    return result.data.data[0]
  },
  getTrainers: async (): Promise<Trainer[]> => {
    const url = `/trainers`
    const result = await dao._http.get<HttpResponse<Trainer>>(url)
    return result.data.data
  },
  getTrainer: async (params: { trainerId: string }): Promise<Trainer> => {
    const { trainerId } = params
    const url = `/trainers/${trainerId}`
    const result = await dao._http.get<HttpResponse<Trainer>>(url)
    return result.data.data[0]
  },
  getCompletedWorkouts: async (params?: { filter?: CompletedWorkoutFilter }): Promise<CompletedWorkout[]> => {
    const { filter } = params ?? {}
    const url = '/completed-workouts'
    const result = await dao._http.get<HttpResponse<CompletedWorkout>>(url, { params: filter })
    return result.data.data
  },
  getLegalTermsAndConditions: async (): Promise<string> => {
    const url = '/legal/terms-and-conditions'
    const result = await dao._http.get<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getLegalPrivacyPolicy: async (): Promise<string> => {
    const url = '/legal/privacy-policy'
    const result = await dao._http.get<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getWeekPromoVideo: async (): Promise<WeekPromoVideo> => {
    const url = '/week-promo-video/current'
    const result = await dao._http.get<HttpResponse<WeekPromoVideo>>(url)
    return result.data.data[0]
  },
  getWeeklyImage: async (params: { dayInWeek: number }): Promise<WeeklyImage> => {
    const { dayInWeek } = params
    const url = `/images/weekly/${dayInWeek}`
    const result = await dao._http.get<HttpResponse<WeeklyImage>>(url)
    return result.data.data[0]
  },
}

export enum WorkoutType {
  LIVE = 'live',
  ON_DEMAND = 'on_demand',
}
export interface WorkoutEquipment {
  piece: EquipmentPiece
  optional: boolean
  quantity: number
}

export enum EquipmentPiece {
  BOOTY_BAND = 'booty_band',
  RESISTANCE_BAND = 'resistance_band',
  DUMBBELL = 'dumbbell',
  LIGHT_DUMBBELL = 'light_dumbbell',
  MEDIUM_DUMBBELL = 'medium_dumbbell',
  HEAVY_DUMBBELL = 'heavy_dumbbell',
  LIGHT_MEDIUM_DUMBBELL = 'light_medium_dumbbell',
  MEDIUM_HEAVY_DUMBBELL = 'medium_heavy_dumbbell',
  LIGHT_MEDIUM_HEAVY_DUMBBELLS = 'light_medium_heavy_dumbbell',
}

export enum EquipmentCategory {
  BAND = 'band',
  BODYWEIGHT = 'bodyweight',
  DUMBBELL = 'dumbbell',
}

export enum BodyWorkoutCategory {
  LOWER_BODY = 'lower_body',
  UPPER_BODY = 'upper_body',
  TOTAL_BODY = 'total_body',
  BODYWEIGHT = 'bodyweight',
  HUSTLE = 'hustle',
  EXPRESS = 'express',
  ABS_ASS = 'abs_ass',
  STRETCH = 'stretch',
  ARMS = 'arms',
}

export enum HouseWorkoutCategory {
  HOUSE_STRENGTH = 'house_strength',
  HOUSE_SCULPT = 'house_sculpt',
  HOUSE_SWEAT = 'house_sweat',
}

export type WorkoutCategoryType = BodyWorkoutCategory | HouseWorkoutCategory
export const WorkoutCategory = { ...BodyWorkoutCategory, ...HouseWorkoutCategory }

export enum WorkoutIntensity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum WorkoutDuration {
  TEN = '10',
  FIFTEEN = '15',
  TENORFIFTEEN = '10/15',
  THIRTY = '30',
  FORTY = '40',
}

export type WorkoutSummary = {
  workout: Workout
  date: string
}

export type Workout = {
  id: string
  title: string
  description: string
  type: WorkoutType
  videoUrl?: string
  previewVideoUrl?: string
  imagePublicFileId: string
  equipment: WorkoutEquipment[]
  category: BodyWorkoutCategory
  intensity: WorkoutIntensity
  duration?: WorkoutDuration
  availableAt: string
  playlistUrl?: string
  trainers: Partial<Trainer>[]
}

export type CompletedWorkout = {
  id: string
  workout: Partial<Workout>
  hustler: Partial<Hustler>
  hustleScore: number
  calories: number
  heartRate: number
  completedAt: number
}
export type Hustler = {
  id: string
  createdAt: Date
  updatedAt: Date
  createdById?: string
  updatedById?: string
  name: string
  authUserId: string
  email: string
  phoneNumber?: string
  profile: HustlerProfile
  // subscriptions: Partial<HustlerSubscription>[]
  // stats: Partial<HustlerStats>[]
  // friends: Partial<Hustler>[]
  // posts: Partial<Post>[]
  // awards: Partial<Award>[]
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  NON_BINARY = 'non_binary',
  N_A = 'n_a',
}

export enum WeightUnit {
  KG = 'kg',
  LB = 'lb',
}

export enum HeightUnit {
  CM = 'cm',
  FT = 'ft',
}

export type HustlerProfile = {
  profilePhotoFileId?: string
  username?: string
  gender?: Gender | null
  dateOfBirth?: Date | null
  weight?: { value: number; unit: WeightUnit } | null
  height?: { value: number; unit: HeightUnit } | null
}

export type TrainerSocialProfiles = Record<string, string>

export interface Trainer {
  id: string
  firstName: string
  lastName: string
  description: string
  profilePhotoPublicFileId: string
  mediumProfilePhotoPublicFileId: string
  socialProfiles?: TrainerSocialProfiles
}

export type Price = {
  id: string
  currency: string
  amount?: number
  recurring?: {
    interval: string
    intervalCount: number
  }
  product: {
    id: string
    name: string
    imageUrl: string | undefined
    trialDurationDays: number
  }
}

export type Store = 'stripe' | 'ios' | 'android'
export type SubscriptionType = '$rc_annual' | '$rc_monthly'
export type StoreSubscription = {
  store: Store
  productIdentifier: string
  type: SubscriptionType
}

export type Subscription = {
  id: string
  cancelAt?: number
  canceledAt?: number
  currentPeriodStart: number
  currentPeriodEnd: number
  status: string
  startDate: number
  items: SubscriptionItem[]
}

export type SubscriptionItem = {
  plan: {
    id: string
    amount: number
    currency: string
  }
  price: {
    id: string
    recurring?: {
      interval?: string
      intervalCount?: number
    }
  }
  product: {
    id: string
    name: string
  }
}

export type WeekPromoVideo = {
  title: string
  availableAt: number
  videoUrl: string
}

export enum ChallengeStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  TERMINATED = 'terminated',
}
export type ChallengeWorkoutsFilter = { type: string; category?: string } | { type: 'all' }
export type CompletionCriteria = {
  statistic?: string
  goal?: number
  filter?: ChallengeWorkoutsFilter
  timePeriod?: string
}

export type Challenge = {
  id: string
  title: string
  description: string
  imagePublicFileId: string
  startDate?: number
  endDate?: number
  criteria: CompletionCriteria
  status: ChallengeStatus
  categoryId: string
  autoStarted?: boolean
}

export interface ChallengeCategory {
  id: string
  name: string
  difficulty: number
}

export type CompletedChallenge = { challenge: Challenge; completedAt: number }

export type WeeklyImage = {
  id: string
  dayInWeek: number
  publicFileId: string
}

import { guidUtil } from '../util/guid-util'
import { StringLocalStorage, localStorageService } from './local-storage-service'

export const clientGuidService = {
  get: (): string => {
    return localStorageService.getString(StringLocalStorage.CLIENT_GUID)
  },
  getOrCreate: (): string => {
    try {
      return clientGuidService.get()
    } catch (err) {
      const newClientGuid = guidUtil.generate()
      localStorageService.setString(StringLocalStorage.CLIENT_GUID, newClientGuid)
      return newClientGuid
    }
  },
}

import { WorkoutDescription } from '../../component/workout-description/workout-description'
import { WorkoutDetailAction, WorkoutDetailActionRefObject } from '../../component/workout-detail-action/workout-detail-action'
import { WorkoutDetailInfo } from '../../component/workout-detail-info/workout-detail-info'
import { WorkoutTrainerInfo } from '../../component/workout-trainer-info/workout-trainer-info'
import { WorkoutVideo } from '../../component/workout/workout-video'
import { SessionContext } from '../../context/session-context'
import { Workout } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import style from './workout-screen.module.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'

export const WorkoutScreen = (): React.ReactElement => {
  const { workoutId } = useParams<{ workoutId: string }>()
  const [workout, setWorkout] = useState<Workout>()
  const { getFirstTrainerFromWorkout } = useContext(SessionContext)

  const workoutDetailActionRef = useRef<WorkoutDetailActionRefObject>(null)

  const handleVideoCompleted = (): void => {
    if (workoutDetailActionRef.current) workoutDetailActionRef.current.setWorkoutCompleted()
  }

  useEffect(() => {
    coreService.getWorkoutById({ workoutId }).then((response) => {
      setWorkout(response)
    })
  }, [workoutId, getFirstTrainerFromWorkout])

  if (!workout) {
    return <div>Loading...</div>
  }

  return (
    <PageContainer>
      <Col className="mt-5 mb-2 px-0">
        <WorkoutVideo workout={workout} onVideoCompleted={handleVideoCompleted} />
      </Col>
      <Row className={style.trainerContainer}>
        <Col xs={7} sm={8} md={9} className="mt-3">
          <WorkoutTrainerInfo trainerId={workout.trainers[0].id ?? ''} />
        </Col>
        <Col xs={5} sm={4} md={3} className="mt-3">
          <WorkoutDetailAction workout={workout} ref={workoutDetailActionRef} />
        </Col>
      </Row>
      <WorkoutDetailInfo workout={workout} />
      <WorkoutDescription workout={workout} />
    </PageContainer>
  )
}

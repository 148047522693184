import { WorkoutListPage } from '../../component/workout-list-page/workout-list-page'
import { Workout } from '../../dao/core-dao'
import { WorkoutPreset, coreService } from '../../service/core-service'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const presetToTitle: Partial<Record<WorkoutPreset, string>> = {
  popular: 'Popular Workouts',
  trending_express: 'Trending Express Workouts',
  'new-workouts': 'New Workouts',
  'new-workouts-expanded': 'Workouts This Month',
}
export const WorkoutPresetScreen = (): React.ReactElement => {
  const { preset } = useParams<{ preset: WorkoutPreset }>()
  const history = useHistory()
  const [workouts, setWorkouts] = useState<Workout[]>()

  useEffect(() => {
    if (!presetToTitle[preset]) {
      history.goBack()
    }
    coreService
      .getWorkouts({
        preset,
        page: 1,
        pageSize: 30,
      })
      .then((workouts) => setWorkouts(workouts))
  }, [preset, history])

  return <WorkoutListPage title={presetToTitle[preset]} workouts={workouts} />
}

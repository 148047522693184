import { stringUtil } from '../../util/string-util'
import { ProgrammeInfoPill } from '../programme-info-pill/programme-info-pill'
import { ProgrammeIntensityPill } from '../programme-intensity-pill/programme-intensity-pill'
import styles from './programme-duration-intensity-info.module.scss'
import React from 'react'
import { Intensity, ProgrammeDuration } from 'src/dao/core-dao'

interface ProgrammeDurationIntensityInfoProps {
  duration: ProgrammeDuration | undefined
  intensity: Intensity | undefined
}

export const ProgrammeDurationIntensityInfo = ({
  duration,
  intensity,
}: ProgrammeDurationIntensityInfoProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <ProgrammeInfoPill
        text={`${
          duration
            ? stringUtil.pluralizeIfNeeded({
                itemCount: duration.weeks.count,
                singular: 'week',
              })
            : '?'
        }`}
      />
      <ProgrammeInfoPill
        text={`${
          duration
            ? stringUtil.pluralizeIfNeeded({
                itemCount: duration.classes.count,
                singular: 'class',
                plural: 'classes',
              })
            : '?'
        }`}
      />
      <ProgrammeIntensityPill intensity={intensity} />
    </div>
  )
}

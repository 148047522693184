import InstagramIcon from '../../asset/image/icon/instagram.png'
import { Trainer } from '../../dao/core-dao'
import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './trainer-jumbotron.module.scss'
import React, { useMemo } from 'react'

export enum TrainerJumbotronType {
  PROFILE = 'profile',
  TRAINER_CLASSES = 'trainer-classes',
}

export const TrainerJumbotron = (props: {
  trainer: Trainer
  type: TrainerJumbotronType
  displayInstagramLink?: boolean
}): React.ReactElement => {
  const { trainer, type, displayInstagramLink = false } = props

  const jumbotronTitle = useMemo((): string => {
    switch (type) {
      case TrainerJumbotronType.PROFILE:
        return `Meet ${trainer.firstName} ${trainer.lastName}`
      case TrainerJumbotronType.TRAINER_CLASSES:
        return `Classes with ${trainer.firstName}`
      default:
        return ''
    }
  }, [type, trainer])

  const trainerInstagramUrl = useMemo((): string | undefined => {
    return (trainer.socialProfiles ?? {})['instagram']
  }, [trainer])

  return (
    <div className={`panel panel-radius ${styles.jumbotron}`}>
      <ImagePublicFileId className={styles.profileImage} fileId={trainer.profilePhotoPublicFileId} />
      <h1>{jumbotronTitle.toUpperCase()}</h1>
      {displayInstagramLink && trainerInstagramUrl && (
        <a href={trainerInstagramUrl} target="_blank" rel="noreferrer">
          <img className={styles.instagramIcon} src={InstagramIcon} alt="instagram" />
        </a>
      )}
    </div>
  )
}

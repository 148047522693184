import { messageDaoFactory } from '../../dao'
import { clientGuidService } from '../client-guid-service'
import { StringLocalStorage, localStorageService } from '../local-storage-service'

export const firebaseTokenService = {
  store: async (token: string): Promise<void> => {
    const clientGuid = clientGuidService.getOrCreate()
    let oldToken: string | undefined
    try {
      oldToken = localStorageService.getString(StringLocalStorage.FIREBASE_TOKEN)
    } catch (e) {
      oldToken = undefined
    }
    if (token === oldToken) return
    localStorageService.setString(StringLocalStorage.FIREBASE_TOKEN, token)
    const messageDao = messageDaoFactory() // TODO not sure if this is the best way to get messageDao
    await messageDao.subscribeDevice({ token, oldToken, clientGuid })
  },
  clear: async (): Promise<void> => {
    let token: string | undefined
    try {
      token = localStorageService.getString(StringLocalStorage.FIREBASE_TOKEN)
      localStorageService.remove(StringLocalStorage.FIREBASE_TOKEN)
      const messageDao = messageDaoFactory() // TODO not sure if this is the best way to get messageDao
      await messageDao.unsubscribeDevice({ token })
    } catch (e) {
      //
    }
  },
  get: (): string => {
    return localStorageService.getString(StringLocalStorage.FIREBASE_TOKEN)
  },
}

import {
  AwardDto,
  BodyPart,
  CompletedChallenge,
  CompletedWorkout,
  EducationHubCategory,
  FavWorkout,
  Hustler,
  HustlerConfig,
  HustlerProfile,
  HustlerProgramme,
  Intensity,
  NotCompletedChallenge,
  Price,
  Programme,
  ProgrammeDuration,
  RunningSeries,
  RunningSeriesWithWorkouts,
  StoreSubscription,
  Subscription,
  SubscriptionItem,
  Trainer,
  WeeklyImage,
  Workout,
  WorkoutDurationType,
  WorkoutEquipmentGroup,
  WorkoutEquipmentPiece,
  WorkoutSection,
  WorkoutStats,
  WorkoutStyle,
  WorkoutType,
  coreDao,
} from '../dao/core-dao'

export type WorkoutPreset = 'popular' | 'trending_express' | 'new-workouts' | 'new-workouts-expanded' | 'today'
export interface WorkoutFilter {
  trainerIds?: string[]
  durations?: string[]
  intensities?: string[]
  types?: string[]
  styles?: string[]
  sections?: string[]
  bodyParts?: string[]
  equipment?: string[]
  orderField?: keyof Workout
  orderDirection?: 'ASC' | 'DESC'
  preset?: WorkoutPreset
  page?: number
  pageSize?: number
  // TODO: GRND-901 remove redundant availableAtBefore availableAtAfter once most mobile app users are updated
  availableAtBefore?: number
  availableAtAfter?: number
  date?: string
  view?: string
  pagination?: {
    page: number
    pageSize: number
  }
}

export interface CompletedWorkoutFilter {
  orderField?: string
  orderDirection?: 'ASC' | 'DESC'
  completedAtBefore?: number
  completedAtAfter?: number
  workoutIds?: string[]
}

export const coreService = {
  healthCheck: (): any => {
    return coreDao.healthCheck()
  },
  getWorkouts: async (filter?: WorkoutFilter): Promise<Workout[]> => {
    return coreDao.getWorkouts({ ...filter, view: 'web' })
  },
  getWorkoutById: async ({ workoutId }: { workoutId: string }): Promise<Workout> => {
    return coreDao.getWorkoutById(workoutId)
  },
  getWorkoutTypes: async (): Promise<WorkoutType[]> => {
    return coreDao.getWorkoutTypes()
  },
  completeWorkout: async ({ workoutId }: { workoutId: string }): Promise<CompletedWorkout> => {
    return coreDao.completeWorkout(workoutId)
  },
  getMe: async (): Promise<Hustler> => {
    return coreDao.getHustlerMe()
  },
  getConfig: async (): Promise<HustlerConfig> => {
    return coreDao.getConfig()
  },
  updateMe: async (params: { profile: HustlerProfile }): Promise<Hustler> => {
    return coreDao.patchHustlerMe(params)
  },
  fetchCompletedChallenges: async (params?: { limit?: number }): Promise<CompletedChallenge[]> => {
    return coreDao.fetchCompletedChallenges(params)
  },
  createCheckoutSession: async (priceId: string): Promise<string> => {
    return coreDao.createCheckoutSession(priceId)
  },
  createPortalSession: async (): Promise<string> => {
    return coreDao.createPortalSession()
  },
  getMySubscriptions: async (): Promise<StoreSubscription | undefined> => {
    return coreDao.getMySubscriptions()
  },
  getStripeSubscriptions: async (): Promise<Subscription[]> => {
    return coreDao.getStripeSubscriptions()
  },
  getMyActiveOrTrialingSubscriptionItems: async (): Promise<SubscriptionItem[]> => {
    const mySubscriptions = await coreService.getStripeSubscriptions()
    return mySubscriptions
      .filter((sub) => {
        if (!sub.cancelAt && !sub.canceledAt && sub.status === 'trialing') return true
        return sub.status === 'active'
      })
      .map((sub) => {
        return sub.items
      })
      .flatMap((item: SubscriptionItem[]) => {
        return item
      })
  },
  getPrices: async (): Promise<Price[]> => {
    return coreDao.getPrices()
  },
  getTrainers: async (): Promise<Trainer[]> => {
    return coreDao.getTrainers()
  },
  getTrainer: async (params: { trainerId: string }): Promise<Trainer> => {
    return coreDao.getTrainer(params)
  },
  getCompletedWorkouts: async (params?: { filter?: CompletedWorkoutFilter }): Promise<CompletedWorkout[]> => {
    return coreDao.getCompletedWorkouts(params)
  },
  getLegalTermsAndConditions: async (): Promise<string> => {
    return coreDao.getLegalTermsAndConditions()
  },
  getLegalPrivacyPolicy: async (): Promise<string> => {
    return coreDao.getLegalPrivacyPolicy()
  },
  getWeeklyImage: async (params: { dayInWeek: number }): Promise<WeeklyImage> => {
    return coreDao.getWeeklyImage(params)
  },
  getBodyParts(): Promise<BodyPart[]> {
    return coreDao.getBodyParts()
  },
  getWorkoutEquipmentPieces: (): Promise<WorkoutEquipmentPiece[]> => {
    return coreDao.getWorkoutEquipmentPieces()
  },
  getProgrammes(): Promise<Programme[]> {
    return coreDao.getProgrammes()
  },
  getIntensity: async (params: { id: string }): Promise<Intensity> => {
    return coreDao.getIntensity(params)
  },
  getProgrammeDuration: async (params: { programmeId: string }): Promise<ProgrammeDuration> => {
    return coreDao.getProgrammeDuration(params)
  },
  listProgrammeIntensities(): Promise<Intensity[]> {
    return coreDao.listProgrammeIntensities()
  },
  getProgrammeEquipmentList: async (params: { programmeId: string }): Promise<WorkoutEquipmentPiece[]> => {
    return coreDao.getProgrammeEquipmentList(params)
  },
  getMyStartedProgrammes: async (): Promise<HustlerProgramme[]> => {
    return coreDao.getMyStartedProgrammes()
  },
  startProgramme: async (programmeId: string): Promise<HustlerProgramme> => {
    return coreDao.startProgramme(programmeId)
  },
  listWorkoutIntensities: async (): Promise<Intensity[]> => {
    return coreDao.listWorkoutIntensities()
  },
  listRunningSeries: async (): Promise<RunningSeries[]> => {
    return coreDao.listRunningSeries()
  },
  getTrainingSeriesById: async (trainingSeriesId: string): Promise<RunningSeriesWithWorkouts> => {
    return coreDao.getTrainingSeriesById({ trainingSeriesId })
  },
  getMyCurrentMonthWorkoutStats: async (): Promise<WorkoutStats[]> => {
    return coreDao.getMyCurrentMonthWorkoutStats()
  },
  getFavoriteWorkouts: async (): Promise<FavWorkout[]> => {
    return coreDao.getFavoriteWorkouts()
  },
  addToFavorites: async ({ workoutId }: { workoutId: string }): Promise<FavWorkout> => {
    return coreDao.addToFavorites({ workoutId })
  },
  removeFavoriteWorkout: async ({ id }: { id: string }): Promise<void> => {
    return coreDao.removeFavoriteWorkout({ id })
  },
  getMyProgrammeAwards: async (): Promise<AwardDto[]> => {
    return coreDao.getMyProgrammeAwards()
  },
  getCompletedChallenges: async (): Promise<CompletedChallenge[]> => {
    return coreDao.getCompletedChallenges()
  },
  getNotYetCompletedChallenges: async (): Promise<NotCompletedChallenge[]> => {
    return coreDao.getNotYetCompletedChallenges()
  },
  listWorkoutStyles: async (): Promise<WorkoutStyle[]> => {
    return coreDao.listWorkoutStyles()
  },
  listWorkoutSections: async (): Promise<WorkoutSection[]> => {
    return coreDao.listWorkoutSections()
  },
  getWorkoutStyle: async (workoutStyleId: string): Promise<WorkoutStyle> => {
    return coreDao.getWorkoutStyle({ workoutStyleId })
  },
  listEducationHubCategories: async (): Promise<EducationHubCategory> => {
    return coreDao.listEducationHubCategories()
  },
  listWorkoutDurations: async (): Promise<WorkoutDurationType[]> => {
    return coreDao.listWorkoutDurations()
  },
  listWorkoutEquipmentGroups: async (): Promise<WorkoutEquipmentGroup[]> => {
    return coreDao.listWorkoutEquipmentGroups()
  },
}

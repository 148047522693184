import { accessTokenService } from '../../service/token/access-token-service'
import { AxiosInstance } from 'axios'

export const refreshTokenInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      if (originalRequest._retry) return Promise.reject(error)
      if (error.response?.status !== 401) return Promise.reject(error)

      const { accessToken } = await accessTokenService.refresh().catch((_err) => {
        return { accessToken: accessTokenService.get() }
      })
      originalRequest.headers.Authorization = `Bearer ${accessToken}`
      originalRequest._retry = true
      return axiosInstance(originalRequest)
    },
  )
}

import { BodyPart } from '../../../dao/core-dao'

export type BodyPartFilterModel = {
  id: string
  label: string
  subFilters: BodyPartFilterModel[]
}
export const bodyPartUtil = {
  getSelectedFilters: (roots: BodyPartFilterModel[], selectedIds: string[], toggledId?: string): Record<string, boolean> => {
    return roots.reduce(
      (res, root) => ({
        ...res,
        ...bodyPartUtil.getSelectedFromNode(root, selectedIds, toggledId),
      }),
      {} as Record<string, boolean>,
    )
  },

  getSelectedFromNode: (node: BodyPartFilterModel, selectedIds: string[], toggledId?: string): Record<string, boolean> => {
    const isSelected = selectedIds.includes(node.id)
    const isToggled = toggledId === node.id
    if (isToggled) {
      const newValue = !isSelected
      return newValue ? bodyPartUtil.selectSelfAndAllSubFilters(node) : {}
    }
    const childrenValues = node.subFilters.reduce(
      (acc, sF) => ({ ...acc, ...bodyPartUtil.getSelectedFromNode(sF, selectedIds, toggledId) }),
      {} as Record<string, boolean>,
    )
    const allChildrenSelected = node.subFilters.every((sf) => childrenValues[sf.id])
    return { [node.id]: node.subFilters.length ? allChildrenSelected : isSelected, ...childrenValues }
  },
  selectSelfAndAllSubFilters: (node: BodyPartFilterModel): Record<string, boolean> => {
    return {
      [node.id]: true,
      ...node.subFilters.reduce(
        (acc, sF) => ({ ...acc, ...bodyPartUtil.selectSelfAndAllSubFilters(sF) }),
        {} as Record<string, boolean>,
      ),
    }
  },
  getFiltersDepth: (root: BodyPartFilterModel): number => {
    if (root.subFilters.length < 1) return 0
    return Math.max(...root.subFilters.map((subFilter) => bodyPartUtil.getFiltersDepth(subFilter))) + 1
  },
  toGraph: (bodyPartsList: BodyPart[]): BodyPartFilterModel[] => {
    const partsByParentId = bodyPartsList.reduce(
      (acc, part) => {
        if (part.parentBodyPartId) {
          acc[part.parentBodyPartId] = [...(acc[part.parentBodyPartId] ?? []), part]
        } else {
          acc.roots = [...acc.roots, part]
        }
        return acc
      },
      { roots: [] } as Record<string, BodyPart[]>,
    )

    return partsByParentId.roots.map((root) => bodyPartUtil.buildNode(root, partsByParentId))
  },
  buildNode: (root: BodyPart, partsByParentId: Record<string, BodyPart[]>): BodyPartFilterModel => {
    return {
      id: root.id,
      label: root.name,
      subFilters: (partsByParentId[root.id] ?? []).map((child) => bodyPartUtil.buildNode(child, partsByParentId)),
    }
  },
}

import TrainingSeriesImage from '../../asset/image/training_series_banner.jpg'
import style from './home-training-series-section.module.scss'
import { Button, Container, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const HomeTrainingSeries = ({ link }: { link: string }): React.ReactElement => {
  return (
    <Container className={style.trainingSeriesContainer}>
      <Image src={TrainingSeriesImage} className={style.trainingSeriesImage} />
      <div className={style.trainingSeriesContent}>
        <h2>
          GRNDHOUSE <br /> RUNNING SERIES
        </h2>
        <Link to={link}>
          <Button variant="outline-secondary" className={style.trainingSeriesButton}>
            See All Workouts
          </Button>
        </Link>
      </div>
    </Container>
  )
}

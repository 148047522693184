import TrainingSeriesImage from '../../asset/image/training_series_banner.jpg'
import styles from './training-series-list-screen.module.scss'
import { Image, Row } from 'react-bootstrap'
import { PageContainer } from 'src/component/page-container/page-container'
import { RunningSeriesList } from 'src/component/running-series-list/running-series-list'

export const TrainingSeriesListScreen = (): React.ReactElement => {
  return (
    <div className={`w-100 ${styles.whiteBg} pt-3`}>
      <PageContainer>
        <Row className={`${styles.trainingSeriesContainer} justify-content-between mx-auto my-3 w-100`}>
          <Image src={TrainingSeriesImage} className={`${styles.trainingSeriesImage} px-1`} />
          <div className={styles.trainingSeriesContent}>
            <h2>
              GRNDHOUSE <br /> RUNNING SERIES
            </h2>
          </div>
        </Row>
        <RunningSeriesList />
      </PageContainer>
    </div>
  )
}

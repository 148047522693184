import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './programme-intensity-pill.module.scss'
import { Intensity } from 'src/dao/core-dao'

export const ProgrammeIntensityPill = ({
  intensity,
  showColorIcon = false,
}: {
  intensity?: Intensity
  showColorIcon?: boolean
}): React.ReactElement => {
  if (!intensity) {
    return <div>Loading...</div>
  }

  return (
    <div className={`${styles.ProgrammeIntensityPill} ${styles[intensity.name]}`}>
      <ImagePublicFileId
        fileId={showColorIcon ? intensity.filterActiveIconPublicFileId : intensity.filterIconPublicFileId}
        className={styles.intensityIcon}
      />
      <p>{intensity.label} Intensity</p>
    </div>
  )
}

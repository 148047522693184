import { useFilterContext } from '../../context/filter-context'
import { CloseFilterButtonIcon } from './close-filter-button-icon'
import { FilterButtonIcon } from './filter-button-icon'
import styles from './nav-filter-button.module.scss'
import React from 'react'
import { Button } from 'react-bootstrap'

export const NavFilterButton = (): React.ReactElement => {
  const { isSidebarOpen, submittedFilterCount, toggleIsSidebarOpen } = useFilterContext()

  const toggleSidebar = (): void => {
    toggleIsSidebarOpen()
  }
  return (
    <div className={`${styles.filterButtonContainer} ml-2 my-1 pl-0 ml-lg-0 my-lg-0 pl-lg-0 mr-lg-2 pr-lg-0`}>
      <Button variant={isSidebarOpen ? 'secondary' : 'outline-secondary'} className={styles.filterButton} onClick={toggleSidebar}>
        <div className="d-flex justify-content-between align-items-center">
          {isSidebarOpen ? <CloseFilterButtonIcon /> : <FilterButtonIcon />}
          <span>Filters </span>
          <span>{submittedFilterCount ? ` (${submittedFilterCount})` : ''}</span>
        </div>
      </Button>
    </div>
  )
}

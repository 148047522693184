import { SessionContext } from '../../context/session-context'
import { Trainer } from '../../dao/core-dao'
import { ImagePublicFileId } from '../image/image-public-file-id'
import { ChevronSvgIcon } from '../util/images'
import style from './workout-trainer-info.module.scss'
import React, { useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const WorkoutTrainerInfo = (props: { trainerId: string }): React.ReactElement => {
  const { trainerId } = props
  const [trainer, setTrainer] = React.useState<Trainer | undefined>()
  const { trainerById } = useContext(SessionContext)

  useEffect(() => {
    setTrainer(trainerById(trainerId))
  }, [trainerId, trainerById])

  if (!trainer) {
    return <div>Loading...</div>
  }
  return (
    <Row className={style.container}>
      <Col xs={'auto'}>
        <ImagePublicFileId fileId={trainer?.mediumProfilePhotoPublicFileId} roundedCircle className={style.avatar} />
      </Col>
      <Col className={`${style.actions} pl-0`}>
        <Row>
          <Col className={style.trainerName}>
            {trainer.firstName} {trainer.lastName}
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to={`/trainers/${trainerId}`}>
              View trainer profile <ChevronSvgIcon />
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

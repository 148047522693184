import { useFilterContext } from '../../context/filter-context'
import { WorkoutStyle } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { FilterItemButton } from './filter-item-button'
import { SidebarFilterSection } from './sidebar-filter-section'
import styles from './workout-style-filter.module.scss'
import React, { useEffect, useState } from 'react'

export const WorkoutStyleFilter = (): React.ReactElement => {
  const { workoutFilter, toggleFilterEntry } = useFilterContext()
  const [allStyles, setAllStyles] = useState<WorkoutStyle[]>([])

  useEffect(() => {
    coreService.listWorkoutStyles().then(setAllStyles)
  }, [])

  const handleToggleSelectedStyle = (toggleValue: string): void => {
    toggleFilterEntry('styles', toggleValue)
  }

  const renderHouseCategory = (style: WorkoutStyle, additionalClass?: string): React.ReactElement => {
    const isActive = workoutFilter?.styles?.includes(style.id) ?? false
    return (
      <FilterItemButton
        className={`${additionalClass} ${styles.styleButton}`}
        fullWidth={true}
        isActive={isActive}
        onClick={() => handleToggleSelectedStyle(style.id)}
      >
        {style.label.split(' ').map((l) => (
          <span key={l}>{l}</span>
        ))}
      </FilterItemButton>
    )
  }

  return <SidebarFilterSection title={'Workout Style'} items={allStyles} lineLimit={4} renderItem={renderHouseCategory} />
}

import styles from './favorite-workouts-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { WorkoutCarouselCard } from 'src/component/workout-carousel-card/workout-carousel-card'
import { Workout } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const FavoriteWorkoutsScreen = (): React.ReactElement => {
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)
  const [favoriteWorkouts, setFavoriteWorkouts] = useState<Workout[]>()

  useEffect(() => {
    coreService.getFavoriteWorkouts().then((response) => {
      const favorites = response.map(({ workout }) => workout)
      setFavoriteWorkouts(favorites)
    })
  }, [selectedWorkout])

  return (
    <PageContainer>
      <div className="mt-5">
        <Row className="justify-content-between align-items-center mb-5">
          <BackArrow />
          <h1 className={styles.title}>Favourites</h1>
          <div />
        </Row>
        <Row>
          {favoriteWorkouts ? (
            <FavoriteWorkoutsSection favoriteWorkouts={favoriteWorkouts} selectWorkout={setSelectedWorkout} />
          ) : (
            <p className="text-white">Loading</p>
          )}
        </Row>
      </div>
      <WorkoutPopup
        setOnClose={() => setSelectedWorkout(undefined)}
        selectedWorkout={selectedWorkout}
        startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
      />
    </PageContainer>
  )
}

const FavoriteWorkoutsSection = ({
  favoriteWorkouts,
  selectWorkout,
}: {
  favoriteWorkouts: Workout[]
  selectWorkout?: (workout: Workout) => void
}): React.ReactElement => {
  if (favoriteWorkouts.length === 0) {
    return <p className={styles.noWorkoutsLabel}>No workouts found</p>
  }

  return (
    <>
      {favoriteWorkouts.map((workout: Workout) => (
        <Col key={workout.id} md={6} lg={4} className="px-1">
          <div className="d-flex justify-content-center py-2">
            <WorkoutCarouselCard workout={workout} selectWorkout={selectWorkout} showPopup={true} isNew={false} />
          </div>
        </Col>
      ))}
    </>
  )
}

import styles from './profile-not-completed-challenges.module.scss'
import { ProfileYourAwardsCard } from './profile-your-awards-card'
import React from 'react'
import { Row } from 'react-bootstrap'
import { Challenge, NotCompletedChallenge } from 'src/dao/core-dao'

export const ProfileNotCompletedChallanges = ({
  notCompletedChallenge,
  selectChallenge,
}: {
  notCompletedChallenge: NotCompletedChallenge
  selectChallenge: (challenge: Challenge) => void
}): React.ReactElement => {
  return (
    <div className={styles.container}>
      <h3>{notCompletedChallenge.category.name}</h3>
      <Row>
        <ProfileNotCompletedChallangesSection
          notCompletedChallenges={notCompletedChallenge.challenges}
          selectChallenge={selectChallenge}
        />
      </Row>
    </div>
  )
}

const ProfileNotCompletedChallangesSection = ({
  notCompletedChallenges,
  selectChallenge,
}: {
  notCompletedChallenges: { challenge: Challenge; progress: number }[]
  selectChallenge: (challenge: Challenge) => void
}): React.ReactElement => {
  if (notCompletedChallenges.length === 0) {
    return <span className={'pl-3'}>No awards</span>
  }

  return (
    <>
      {notCompletedChallenges.map((item: { challenge: Challenge; progress: number }) => (
        <ProfileYourAwardsCard
          key={item.challenge.id}
          challenge={item.challenge}
          progress={item.progress}
          selectChallenge={selectChallenge}
          isStarted={typeof item.progress === 'number' || item.challenge.autoStarted}
        />
      ))}
    </>
  )
}

import { TodaysWorkouts } from '../component/todays-workouts/todays-workouts'
import style from './home-screen.module.scss'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ExpressWorkoutsSection } from 'src/component/express-workouts-home-section/express-workouts-home-section'
import { HomeTrainingSeries } from 'src/component/home-training-series-section/home-training-series-section'
import { NewWorkouts } from 'src/component/new-workouts/new-workouts-section'
import { PopularWorkouts } from 'src/component/popular-workouts/popular-workouts'
import { TrainersSection } from 'src/component/trainers-section/trainers-section'

export const HomeScreen = (): React.ReactElement => {
  return (
    <div>
      <Row>
        <Col>
          <TodaysWorkouts />
        </Col>
      </Row>
      <Row className={style.whiteBg}>
        <Col>
          <NewWorkouts />
        </Col>
      </Row>
      <Row>
        <Col>
          <PopularWorkouts />
        </Col>
      </Row>
      <Row>
        <Col>
          <HomeTrainingSeries link="/training-series" />
        </Col>
      </Row>
      <Row className={style.whiteBg}>
        <Col>
          <ExpressWorkoutsSection />
        </Col>
      </Row>
      <Row>
        <Col>
          <TrainersSection />
        </Col>
      </Row>
    </div>
  )
}

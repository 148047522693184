import { HustlerProgramme, Programme } from '../../dao/core-dao'
import React, { useEffect } from 'react'
import { PageContainer } from 'src/component/page-container/page-container'
import { ProgrammeList } from 'src/screen/programme/programme-list'
import { coreService } from 'src/service/core-service'

export const ProgrammeListScreen = (): React.ReactElement => {
  const [programmes, setProgrammes] = React.useState<Programme[]>()
  const [startedProgrammes, setStartedProgrammes] = React.useState<HustlerProgramme[]>([])

  useEffect(() => {
    Promise.all([coreService.getProgrammes(), coreService.getMyStartedProgrammes()]).then(
      ([allProgrammes, startedProgrammes]) => {
        const sortedProgrammes = allProgrammes.filter((r) => !r.isDisabled).sort((a, b) => a.order - b.order)
        setProgrammes(sortedProgrammes)
        setStartedProgrammes(startedProgrammes)
      },
    )
  }, [])

  if (!programmes) {
    return <div>Loading...</div>
  }

  if (programmes.length === 0) {
    return <div>Currently there are no available programmes.</div>
  }

  return (
    <PageContainer>
      <div className="mt-5">
        <ProgrammeList programmes={programmes} startedProgrammes={startedProgrammes} />
      </div>
    </PageContainer>
  )
}

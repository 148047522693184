import React from 'react'

export const ErrorMessageHint = (params: { message?: string }): React.ReactElement => {
  const { message } = params

  if (!message) return <></>

  return (
    <div>
      <b>
        <small className="hint form-text text-danger">{message}</small>
      </b>
    </div>
  )
}

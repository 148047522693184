import { useFilterContext } from '../../context/filter-context'
import { WorkoutDuration } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import styles from './duration-filter.module.scss'
import { FilterItemButton } from './filter-item-button'
import { SidebarFilterSection } from './sidebar-filter-section'
import React, { useEffect, useState } from 'react'

export const DurationFilter = (): React.ReactElement => {
  const { workoutFilter, toggleFilterEntry } = useFilterContext()
  const [availableDurations, setAvailableDurations] = useState<WorkoutDuration[]>([])

  useEffect(() => {
    coreService
      .listWorkoutDurations()
      .then((durations) => setAvailableDurations(durations.filter((duration) => duration.isFilterable)))
  }, [])

  const handleToggleSelectedDuration = ({ id }: WorkoutDuration): void => {
    toggleFilterEntry('durations', id)
  }

  const renderDuration = (duration: WorkoutDuration, additionalClass?: string): React.ReactElement => {
    const isActive = workoutFilter?.durations?.includes(duration.id) ?? false
    return (
      <FilterItemButton
        className={additionalClass}
        fullWidth={true}
        onClick={() => handleToggleSelectedDuration(duration)}
        isActive={isActive}
      >
        <span className={styles.durationValueLabel}>{duration.durationValue}</span>
        <span>min</span>
      </FilterItemButton>
    )
  }
  return <SidebarFilterSection title={'Duration'} items={availableDurations} renderItem={renderDuration} />
}

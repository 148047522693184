import styles from './profile-awards-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { ProfileChallengePopup } from 'src/component/profile/profile-challenge-popup'
import { ProfileNotCompletedChallanges } from 'src/component/profile/profile-not-completed-challenges'
import { ProfileProgrammeAwardsCard } from 'src/component/profile/profile-programme-awards-card'
import { ProfileYourAwardsCard } from 'src/component/profile/profile-your-awards-card'
import { AwardDto, Challenge, CompletedChallenge, NotCompletedChallenge } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

export const ProfileAwardsScreen = (): React.ReactElement => {
  const [programmeAwards, setProgrammeAwards] = useState<AwardDto[]>()
  const [completedChallenges, setCompletedChallenges] = useState<CompletedChallenge[]>()
  const [notYetCompletedChallenges, setNotYetCompletedChallenges] = useState<NotCompletedChallenge[]>()
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | undefined>(undefined)

  useEffect(() => {
    coreService.getMyProgrammeAwards().then((response) => {
      setProgrammeAwards(response)
    })
    coreService.getCompletedChallenges().then((response) => {
      setCompletedChallenges(response)
    })
    coreService.getNotYetCompletedChallenges().then((response) => {
      setNotYetCompletedChallenges(response)
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.programmeAwardsContainer}>
        <h2 className={styles.title}>Programme awards</h2>
        <Row className={styles.programmeAwards}>
          {programmeAwards ? <ProgrammeAwardsSection programmeAwards={programmeAwards} /> : <p className="pl-3">Loading</p>}
        </Row>
      </div>
      <div className={styles.yourAwardsContainer}>
        <h2 className={styles.title}>Your awards</h2>
        <Row className={styles.yourAwards}>
          {completedChallenges ? (
            <CompletedChallengesSection completedChallenges={completedChallenges} selectChallenge={setSelectedChallenge} />
          ) : (
            <p className="pl-3">Loading</p>
          )}
        </Row>
      </div>
      <div className={styles.notYetCompletedContainer}>
        <h2 className={styles.title}>Not yet completed</h2>
        <div className={styles.yourAwards}>
          {notYetCompletedChallenges ? (
            <NotCompletedChallengesSection
              notYetCompletedChallenges={notYetCompletedChallenges}
              selectChallenge={setSelectedChallenge}
            />
          ) : (
            <p className="mt-3">Loading</p>
          )}
        </div>
      </div>
      <ProfileChallengePopup onClose={() => setSelectedChallenge(undefined)} selectedChallenge={selectedChallenge} />
    </div>
  )
}

const ProgrammeAwardsSection = ({ programmeAwards }: { programmeAwards: AwardDto[] | undefined }): React.ReactElement => {
  if (programmeAwards?.length === 0) {
    return <span className={'pl-3'}>No awards yet</span>
  }
  return (
    <>
      {programmeAwards?.map((programmeAward: AwardDto) => (
        <ProfileProgrammeAwardsCard key={programmeAward.award.id} programmeAward={programmeAward} />
      ))}
    </>
  )
}

const CompletedChallengesSection = ({
  completedChallenges,
  selectChallenge,
}: {
  completedChallenges: CompletedChallenge[]
  selectChallenge: (challenge: Challenge) => void
}): React.ReactElement => {
  if (completedChallenges?.length === 0) {
    return <span className={'pl-3'}>No awards yet</span>
  }
  return (
    <>
      {completedChallenges?.map((completedChallenge: CompletedChallenge) => (
        <ProfileYourAwardsCard
          key={completedChallenge.challenge.id}
          challenge={completedChallenge.challenge}
          isCompleted={true}
          selectChallenge={selectChallenge}
        />
      ))}
    </>
  )
}

const NotCompletedChallengesSection = ({
  notYetCompletedChallenges,
  selectChallenge,
}: {
  notYetCompletedChallenges: NotCompletedChallenge[]
  selectChallenge: (challenge: Challenge) => void
}): React.ReactElement => {
  if (notYetCompletedChallenges?.length === 0) {
    return <span>No awards</span>
  }
  return (
    <>
      {notYetCompletedChallenges.map((notCompletedChallenge: NotCompletedChallenge) => (
        <ProfileNotCompletedChallanges
          key={notCompletedChallenge.category.id}
          notCompletedChallenge={notCompletedChallenge}
          selectChallenge={selectChallenge}
        />
      ))}
    </>
  )
}

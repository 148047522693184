import { useFilterContext } from '../../context/filter-context'
import { WorkoutEquipmentGroup } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { FilterIcon } from './filter-icon'
import { FilterItemButton } from './filter-item-button'
import { SidebarFilterSection } from './sidebar-filter-section'
import React, { useEffect, useState } from 'react'

export const EquipmentFilter = (): React.ReactElement => {
  const { workoutFilter, toggleFilterEntry } = useFilterContext()

  const [availableEquipmentGroups, setAvailableEquipmentGroups] = useState<WorkoutEquipmentGroup[]>([])

  useEffect(() => {
    coreService.listWorkoutEquipmentGroups().then((groups) => setAvailableEquipmentGroups(groups))
  }, [])
  const handleToggleSelectedEquipment = ({ name }: WorkoutEquipmentGroup): void => {
    toggleFilterEntry('equipment', name)
  }

  const renderEquipment = (equipment: WorkoutEquipmentGroup, additionalClass?: string): React.ReactElement => {
    const isActive = workoutFilter?.equipment?.includes(equipment.name) ?? false
    return (
      <FilterItemButton
        className={additionalClass}
        onClick={() => handleToggleSelectedEquipment(equipment)}
        isActive={isActive}
        fullWidth={true}
      >
        <FilterIcon height={27} filterEntry={equipment} active={isActive} />
        <span>{equipment.label}</span>
      </FilterItemButton>
    )
  }

  return <SidebarFilterSection title={`Equipment`} items={availableEquipmentGroups} lineLimit={4} renderItem={renderEquipment} />
}

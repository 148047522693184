import { ReactElement } from 'react'

export const FilterButtonIcon = (): ReactElement => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.804688" x2="18.913" y2="0.804688" stroke="currentColor" />
      <line y1="7.32617" x2="18.913" y2="7.32617" stroke="currentColor" />
      <line y1="13.8477" x2="18.913" y2="13.8477" stroke="currentColor" />
      <circle cx="2.28295" cy="0.978261" r="0.978261" fill="currentColor" />
      <circle cx="14.6736" cy="7.49975" r="0.978261" fill="currentColor" />
      <circle cx="7.49975" cy="14.0212" r="0.978261" fill="currentColor" />
    </svg>
  )
}

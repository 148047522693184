import { toasterUtil } from './toaster-util'

export type BackendHTTPError = {
  message: string
  code: number
}

export const errorUtil = {
  parseHttpError: (error: any): BackendHTTPError => {
    return {
      message: error.message ?? error.toString(),
      code: error.code ?? 500,
    }
  },
  handleHttpError: (error: any): void => {
    const err = errorUtil.parseHttpError(error)
    if (err.code >= 500) {
      toasterUtil.error(err.message)
    } else {
      toasterUtil.warn(err.message)
    }
  },
}

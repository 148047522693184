import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './profile-challenge-popup.module.scss'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Challenge } from 'src/dao/core-dao'

interface ProfileChallengePopupProps {
  selectedChallenge?: Challenge
  onClose: () => void
}

export const ProfileChallengePopup = ({ selectedChallenge, onClose }: ProfileChallengePopupProps): React.ReactElement => {
  const selectedChallengeId = selectedChallenge?.id ?? ''

  return (
    <Modal className={styles.container} show={!!selectedChallengeId} onHide={() => onClose()} centered>
      {selectedChallenge && <ProfileChallengePopupBody selectedChallenge={selectedChallenge} />}
    </Modal>
  )
}

export const ProfileChallengePopupBody = ({ selectedChallenge }: { selectedChallenge: Challenge }): React.ReactElement => {
  const imageUrl = selectedChallenge?.imagePublicFileId ?? ''
  const title = selectedChallenge?.title ?? ''
  const goal = selectedChallenge?.criteria.goal ?? ''
  const statistic = selectedChallenge?.criteria.statistic ?? ''

  return (
    <div className={styles.modal}>
      <ImagePublicFileId fileId={imageUrl} className={styles.awardImage} />
      <h4>{title}</h4>
      <h5>
        {goal} {statistic}
      </h5>
    </div>
  )
}

import HeartIconSelected from '../../asset/image/programmes/heart-icon-selected.png'
import HeartIcon from '../../asset/image/programmes/heart-icon.png'
import { coreService } from '../../service/core-service'
import { ImagePublicFileId } from '../image/image-public-file-id'
import { ClockSvgIcon, EquipmentSvgIcon, ProgrammeSpotifySvgIcon } from '../util/images'
import styles from './programme-workout-popup.module.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SessionContext } from 'src/context/session-context'
import { Intensity, Workout, coreDao } from 'src/dao/core-dao'

interface WorkoutPopupProps {
  setOnClose: React.Dispatch<React.SetStateAction<boolean>>
  selectedWorkout?: Workout
  startWorkoutLink?: string
}

export const WorkoutPopup = ({ setOnClose, selectedWorkout, startWorkoutLink }: WorkoutPopupProps): React.ReactElement => {
  const [workoutIntensity, setWorkoutIntensity] = useState<Intensity>()
  const [isFavorite, setIsFavorite] = useState<boolean>(false)

  const selectedWorkoutId = selectedWorkout?.id ?? ''

  const selectedWorkoutIntensityId = selectedWorkout?.intensityId ?? ''

  useEffect(() => {
    Promise.all([
      coreService.getFavoriteWorkouts().then((data) => {
        if (selectedWorkout) {
          const workoutInList = data.find((f) => f.workout.id === selectedWorkout.id)
          if (workoutInList) {
            selectedWorkout.favoriteId = workoutInList.id
            setIsFavorite(true)
          } else {
            setIsFavorite(false)
          }
        }
      }),
      coreDao.listWorkoutIntensities().then((response) => {
        if (selectedWorkoutIntensityId) {
          setWorkoutIntensity(getIntensity(response, selectedWorkoutIntensityId))
        }
      }),
    ])
  }, [selectedWorkout, selectedWorkoutIntensityId])

  const workoutLink = startWorkoutLink

  const addToFavorites2 = (): void => {
    if (selectedWorkout) {
      coreService.addToFavorites({ workoutId: selectedWorkoutId }).then((data) => {
        selectedWorkout.favoriteId = data.id
        setIsFavorite(true)
      })
    }
  }

  const removeFromFavorites2 = (): void => {
    if (selectedWorkout?.favoriteId) {
      coreService.removeFavoriteWorkout({ id: selectedWorkout.favoriteId }).then(() => {
        selectedWorkout.favoriteId = undefined
        setIsFavorite(false)
      })
    }
  }

  return (
    <Modal
      className={styles.workoutPopup}
      contentClassName={styles.workoutPopupContent}
      show={!!selectedWorkoutId}
      onHide={() => setOnClose(false)}
      centered
    >
      {selectedWorkout && (
        <WorkoutPopupBody
          workout={selectedWorkout}
          startWorkoutLink={workoutLink}
          intensity={workoutIntensity}
          addToFavorites={addToFavorites2}
          removeFromFavorites={removeFromFavorites2}
          isFavorite={isFavorite}
        />
      )}
    </Modal>
  )
}
export const WorkoutPopupBody = ({
  workout,
  startWorkoutLink,
  intensity,
  addToFavorites,
  removeFromFavorites,
  isFavorite,
}: {
  workout: Workout
  startWorkoutLink?: string
  intensity?: Intensity
  addToFavorites: () => void
  removeFromFavorites: () => void
  isFavorite: boolean
}): React.ReactElement => {
  const { getFirstTrainerFromWorkout, renderDuration, renderEquipmentPieces } = useContext(SessionContext)

  const { title: workoutTitle, description: workoutDescription, equipment, favoriteId } = workout

  const trainer = getFirstTrainerFromWorkout(workout)?.firstName
  const duration = renderDuration(workout.durationId)
  const title = workoutTitle ?? ''
  const description = workoutDescription ?? ''

  const equipmentPiecesLabel = renderEquipmentPieces(equipment)

  const handleFavoritePress = (): void => {
    if (favoriteId) {
      removeFromFavorites()
    } else {
      addToFavorites()
    }
  }

  return (
    <div className={styles.workoutPopupContainer}>
      <div className={styles.imageContainer}>
        <ImagePublicFileId fileId={workout?.imagePublicFileId} className={styles.coverImage} />
        <div>
          {title && <h2>{title}</h2>}
          {trainer && <h3>{trainer}</h3>}
        </div>
      </div>
      <div className={styles.workoutInfo}>
        {intensity && (
          <div className={`${styles.workoutIntensity} ${styles[workout.intensity]}`}>
            <ImagePublicFileId fileId={intensity.filterActiveIconPublicFileId} className={styles.intensityIcon} />
            <span>{intensity.label} Intensity</span>
          </div>
        )}
        {duration && (
          <div>
            <ClockSvgIcon width={16} height={16} />
            <span>{duration} workout</span>
          </div>
        )}
        {equipmentPiecesLabel && (
          <div>
            <EquipmentSvgIcon width={20} height={9.5} />
            {equipmentPiecesLabel}
          </div>
        )}
      </div>
      <p className={styles.workoutDescription}>{description}</p>
      <div className={styles.workoutLinks}>
        <a href={workout.playlistUrl} target="_blank" rel="noreferrer">
          <ProgrammeSpotifySvgIcon />
        </a>
        <Link to={startWorkoutLink ?? ''} className={styles.startWorkoutBtn}>
          Start workout
        </Link>
        <div onClick={handleFavoritePress} className={styles.favoriteIconContainer}>
          <img src={isFavorite ? HeartIconSelected : HeartIcon} alt="Favorite" />
        </div>
      </div>
    </div>
  )
}

const getIntensity = (intensities: Intensity[] | undefined, intensityId: string | undefined): Intensity | undefined => {
  if (!intensities || !intensityId) {
    return undefined
  }
  return intensities.find((i) => i.id === intensityId)
}

import { Trainer } from '../../dao/core-dao'
import { fileService } from '../../service/file-service'
import styles from './trainer-icon.module.scss'
import React from 'react'

export const TrainerIcon = (props: { trainer: Trainer; active: boolean }): React.ReactElement => {
  const { trainer, active } = props

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={fileService.publicUrlFromFileId(trainer.mediumProfilePhotoPublicFileId)}
        alt={trainer.firstName}
      />
      <div className={`${styles.imageContainer} ${active ? 'active' : ''}`} />
      <p className={`${styles.title} ${active ? 'active' : ''}`}>{trainer.firstName}</p>
    </div>
  )
}

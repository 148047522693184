import { HustlerProgramme, Programme, ProgrammeDuration } from '../dao/core-dao'
import { coreService } from '../service/core-service'
import { promiseUtil } from './promise-util'

type ProgrammeDurationWithId = { programmeId: string; duration: ProgrammeDuration }
export const programmeUtil = {
  isProgrammeStarted: (programmeId: string, startedProgrammes: HustlerProgramme[]): boolean => {
    return startedProgrammes.some(({ programme: { id: startedProgrammeId } }) => startedProgrammeId === programmeId)
  },
  programmeIdDurationsToMap: (durations: ProgrammeDurationWithId[]): Map<string, ProgrammeDuration> =>
    durations.reduce((map, { programmeId, duration }) => {
      map.set(programmeId, duration)
      return map
    }, new Map<string, ProgrammeDuration>()),
  getProgrammeDurations: (programmes: Programme[]): Promise<ProgrammeDurationWithId[]> => {
    const durationPromises = programmes.map(({ id: programmeId }) =>
      coreService.getProgrammeDuration({ programmeId }).then((duration) => ({ programmeId, duration })),
    )

    return Promise.allSettled(durationPromises).then((results) =>
      results.filter(promiseUtil.isPromiseFulfilled).map(({ value }) => value),
    )
  },
}

import { eventBusController } from '../../controller/rxjs-bus/event-bus-controller'
import { logger } from '../../util/logger'
import { Initiate } from '@beecode/msh-node-app'

export class RxjsBusInitiate extends Initiate {
  constructor() {
    super()
    this.Logger = logger
  }

  public get Name(): string {
    return 'RXJS - Event Bus'
  }

  protected async _initFn(): Promise<void> {
    eventBusController().register()
  }

  protected async _destroyFn(): Promise<void> {
    eventBusController().unsubscribe()
  }
}

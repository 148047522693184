import { FilterSection } from './filter-section'
import styles from './sidebar-filter-section.module.scss'
import React, { Fragment, ReactElement } from 'react'

export type SidebarFilterSectionProps<T> = {
  title: string
  items: T[]
  renderItem: (arg: T, additionalClass?: string) => ReactElement
  lineLimit?: number
}
export const SidebarFilterSection = <T extends { id: string }>({
  title,
  items,
  renderItem,
  lineLimit = 5,
}: SidebarFilterSectionProps<T>): ReactElement => {
  const itemGroups = partitionArray(items, lineLimit, items[items.length - 1])

  const renderGroup = (itemGroup: PartitionElement<T>[]): ReactElement => {
    const groupId = `${itemGroup[0]?.element?.id || ''}-${itemGroup.length}`
    return (
      <div key={groupId} className={styles.filterItemsContainer}>
        {itemGroup.map(renderFilterItem)}
      </div>
    )
  }
  const renderFilterItem = (item: PartitionElement<T>, index: number): ReactElement => {
    if (item.isPad) {
      return <Fragment key={`${item.element.id}-${index}`}>{renderItem(item.element, styles.padElement)}</Fragment>
    }
    return <Fragment key={item.element.id}>{renderItem(item.element)}</Fragment>
  }
  return (
    <FilterSection>
      <div>{title}</div>
      {itemGroups.map(renderGroup)}
    </FilterSection>
  )
}
type PartitionElement<T> = {
  element: T
  isPad: boolean
}
const partitionArray = <T,>(array: T[], partitionSize: number, padElement: T): PartitionElement<T>[][] =>
  array
    .map((element) => ({ element, isPad: false }))
    .concat(elementArray(padElement, padSize(array.length, partitionSize)))
    .reduce((acc, item) => {
      if (acc.length < 1) {
        acc[0] = [item]
        return acc
      }
      const current = acc[acc.length - 1]
      if (current.length < partitionSize) {
        current.push(item)
        return acc
      }
      acc.push([item])
      return acc
    }, [] as PartitionElement<T>[][])

const elementArray = <T,>(element: T, size: number): PartitionElement<T>[] => Array(size).fill({ element, isPad: true })

const padSize = (arrayLength: number, partitionSize: number): number =>
  arrayLength > partitionSize && arrayLength % partitionSize ? partitionSize - (arrayLength % partitionSize) : 0

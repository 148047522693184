import { WorkoutDuration } from '../../dao/core-dao-v1'
import React from 'react'

export const DurationIcon = (props: { duration: WorkoutDuration; active: boolean }): React.ReactElement => {
  const { duration, active } = props

  const renderMinutes = (): React.ReactElement => {
    switch (duration) {
      case WorkoutDuration.TEN:
        return (
          <>
            <path
              d="M20.4246 31.749H22.9998V34.5H14.905V31.749H17.4802V22.3799H14.905V19.6289H18.2449C18.8425 19.6289 19.3523 19.8252 19.7742 20.2178C20.2078 20.6162 20.4246 21.0967 20.4246 21.6592V31.749Z"
              fill="#E0E0E0"
            />
            <path
              d="M28.2873 22.3799V31.749H30.4757V22.3799H28.2873ZM31.2404 19.6289C31.838 19.6289 32.3507 19.8252 32.7785 20.2178C33.2121 20.6162 33.4289 21.0967 33.4289 21.6592V32.4697C33.4289 33.0322 33.2121 33.5127 32.7785 33.9111C32.3507 34.3037 31.838 34.5 31.2404 34.5H27.5138C26.922 34.5 26.4123 34.3037 25.9845 33.9111C25.5509 33.5127 25.3341 33.0322 25.3341 32.4697V21.6592C25.3341 21.0967 25.5509 20.6162 25.9845 20.2178C26.4123 19.8252 26.922 19.6289 27.5138 19.6289H31.2404Z"
              fill="#E0E0E0"
            />
          </>
        )
      case WorkoutDuration.FIFTEEN:
        return (
          <>
            <path
              d="M20.4247 31.749H22.9999V34.5H14.9052V31.749H17.4803V22.3799H14.9052V19.6289H18.245C18.8427 19.6289 19.3524 19.8252 19.7743 20.2178C20.2079 20.6162 20.4247 21.0967 20.4247 21.6592V31.749Z"
              fill="#E0E0E0"
            />
            <path
              d="M28.3489 22.3799V25.1396C29.3216 24.6357 30.1741 24.3838 30.9065 24.3838C31.3929 24.3838 31.8264 24.4951 32.2073 24.7178C33.0628 25.2217 33.4905 26.1738 33.4905 27.5742V32.4697C33.4905 33.0322 33.2737 33.5127 32.8401 33.9111C32.4124 34.3037 31.8997 34.5 31.302 34.5H27.5755C26.9837 34.5 26.4739 34.3037 26.0462 33.9111C25.6126 33.5127 25.3958 33.0322 25.3958 32.4697V29.8682H28.3489V31.749H30.5374V27.5742C30.5374 27.2051 30.4729 26.9531 30.344 26.8184C30.221 26.6895 29.9661 26.625 29.5794 26.625C29.1809 26.625 28.8821 26.6865 28.6829 26.8096C28.4602 26.9619 28.3489 27.2168 28.3489 27.5742V28.1543H25.3958V19.6289H33.4905V22.3799H28.3489Z"
              fill="#E0E0E0"
            />
          </>
        )
      case WorkoutDuration.TENORFIFTEEN:
        return (
          <>
            <path
              d="M12.1162 29.666H13.833V31.5H8.43652V29.666H10.1533V23.4199H8.43652V21.5859H10.6631C11.0615 21.5859 11.4014 21.7168 11.6826 21.9785C11.9717 22.2441 12.1162 22.5645 12.1162 22.9395V29.666Z"
              fill="white"
            />
            <path
              d="M17.358 23.4199V29.666H18.817V23.4199H17.358ZM19.3268 21.5859C19.7252 21.5859 20.067 21.7168 20.3521 21.9785C20.6412 22.2441 20.7857 22.5645 20.7857 22.9395V30.1465C20.7857 30.5215 20.6412 30.8418 20.3521 31.1074C20.067 31.3691 19.7252 31.5 19.3268 31.5H16.8424C16.4479 31.5 16.108 31.3691 15.8229 31.1074C15.5338 30.8418 15.3893 30.5215 15.3893 30.1465V22.9395C15.3893 22.5645 15.5338 22.2441 15.8229 21.9785C16.108 21.7168 16.4479 21.5859 16.8424 21.5859H19.3268Z"
              fill="white"
            />
            <path d="M22.1018 31.5L23.8186 21.5859H25.5939L23.8713 31.5H22.1018Z" fill="white" />
            <path
              d="M31.117 29.666H32.8338V31.5H27.4373V29.666H29.1541V23.4199H27.4373V21.5859H29.6639C30.0623 21.5859 30.4021 21.7168 30.6834 21.9785C30.9725 22.2441 31.117 22.5645 31.117 22.9395V29.666Z"
              fill="white"
            />
            <path
              d="M36.3998 23.4199V25.2598C37.0482 24.9238 37.6166 24.7559 38.1049 24.7559C38.4291 24.7559 38.7182 24.8301 38.9721 24.9785C39.5424 25.3145 39.8275 25.9492 39.8275 26.8828V30.1465C39.8275 30.5215 39.683 30.8418 39.3939 31.1074C39.1088 31.3691 38.767 31.5 38.3686 31.5H35.8842C35.4897 31.5 35.1498 31.3691 34.8647 31.1074C34.5756 30.8418 34.4311 30.5215 34.4311 30.1465V28.4121H36.3998V29.666H37.8588V26.8828C37.8588 26.6367 37.8158 26.4687 37.7299 26.3789C37.6479 26.293 37.4779 26.25 37.2201 26.25C36.9545 26.25 36.7553 26.291 36.6225 26.373C36.474 26.4746 36.3998 26.6445 36.3998 26.8828V27.2695H34.4311V21.5859H39.8275V23.4199H36.3998Z"
              fill="white"
            />
            <path
              d="M17.8701 41.9998V36.5215H18.5588L20.4371 40.9902L22.3153 36.5215H23.004V41.9998H22.3153V38.0006L20.711 41.9998H20.1632L18.5588 37.9771V41.9998H17.8701Z"
              fill="white"
            />
            <path d="M24.2594 41.9998V36.5215H24.9716V41.9998H24.2594Z" fill="white" />
            <path
              d="M26.2235 41.9998V36.5215H26.9827L29.4479 40.8102V36.5215H30.1288V41.9998H29.3775L26.9122 37.7111V41.9998H26.2235Z"
              fill="white"
            />
          </>
        )
      case WorkoutDuration.THIRTY:
        return (
          <>
            <path
              d="M19.6776 22.3799H17.4891V24.6475H14.536V21.6592C14.536 21.0967 14.7528 20.6162 15.1864 20.2178C15.6141 19.8252 16.1239 19.6289 16.7157 19.6289H20.4423C21.0399 19.6289 21.5526 19.8252 21.9803 20.2178C22.4139 20.6162 22.6307 21.0967 22.6307 21.6592V24.7266C22.6307 25.6934 22.3466 26.4727 21.7782 27.0645C22.3466 27.6562 22.6307 28.4355 22.6307 29.4023V32.4697C22.6307 33.0322 22.4139 33.5127 21.9803 33.9111C21.5526 34.3037 21.0399 34.5 20.4423 34.5H16.7157C16.1239 34.5 15.6141 34.3037 15.1864 33.9111C14.7528 33.5127 14.536 33.0322 14.536 32.4697V29.4727H17.4891V31.749H19.6776V29.4023C19.6776 29.0332 19.5897 28.7783 19.4139 28.6377C19.2557 28.5088 18.9803 28.4443 18.5878 28.4443H17.1376V25.6846H18.5878C18.9803 25.6846 19.2557 25.6201 19.4139 25.4912C19.5897 25.3506 19.6776 25.0957 19.6776 24.7266V22.3799Z"
              fill="#E0E0E0"
            />
            <path
              d="M28.2874 22.3799V31.749H30.4759V22.3799H28.2874ZM31.2405 19.6289C31.8382 19.6289 32.3509 19.8252 32.7786 20.2178C33.2122 20.6162 33.429 21.0967 33.429 21.6592V32.4697C33.429 33.0322 33.2122 33.5127 32.7786 33.9111C32.3509 34.3037 31.8382 34.5 31.2405 34.5H27.5139C26.9221 34.5 26.4124 34.3037 25.9846 33.9111C25.5511 33.5127 25.3343 33.0322 25.3343 32.4697V21.6592C25.3343 21.0967 25.5511 20.6162 25.9846 20.2178C26.4124 19.8252 26.9221 19.6289 27.5139 19.6289H31.2405Z"
              fill="#E0E0E0"
            />
          </>
        )
      case WorkoutDuration.FORTY:
        return (
          <>
            <path
              d="M19.3261 28.3828V19.6289H22.2792V28.3828H23.4481V31.1338H22.2792V34.5H19.3261V31.1338H16.1093C15.5175 31.1338 15.0077 30.9404 14.58 30.5537C14.1464 30.1553 13.9296 29.6748 13.9296 29.1123L15.2831 19.6289H18.0077L16.9003 28.3828H19.3261Z"
              fill="#E0E0E0"
            />
            <path
              d="M27.9531 21.936V31.9351H30.1416V21.936H27.9531ZM30.9063 19C31.5039 19 32.0166 19.2095 32.4443 19.6285C32.8779 20.0537 33.0947 20.5665 33.0947 21.1668V32.7043C33.0947 33.3046 32.8779 33.8174 32.4443 34.2426C32.0166 34.6616 31.5039 34.8711 30.9063 34.8711H27.1797C26.5879 34.8711 26.0781 34.6616 25.6504 34.2426C25.2168 33.8174 25 33.3046 25 32.7043V21.1668C25 20.5665 25.2168 20.0537 25.6504 19.6285C26.0781 19.2095 26.5879 19 27.1797 19H30.9063Z"
              fill="#E0E0E0"
            />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <svg
      className={active ? 'active' : ''}
      width="48px"
      height="54px"
      viewBox="0 0 48 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 1.5C17.25 0.671573 18.1135 0 19.1786 0H28.8214C29.8866 0 30.75 0.671573 30.75 1.5C30.75 2.32843 29.8866 3 28.8214 3H26.25V6.10408C38.4498 7.23838 48 17.5039 48 30C48 43.2548 37.2548 54 24 54C10.7452 54 0 43.2548 0 30C0 17.5039 9.55022 7.23838 21.75 6.10408V3H19.1786C18.1135 3 17.25 2.32843 17.25 1.5ZM44.25 29.25H45.9875C45.8006 23.6722 43.5374 18.6206 39.9462 14.8434C39.9253 14.9865 39.8598 15.1243 39.7497 15.2344L38.7653 16.2188C38.4935 16.4906 38.0527 16.4906 37.7809 16.2188C37.5091 15.9469 37.5091 15.5062 37.7809 15.2344L38.7653 14.25C38.8754 14.1399 39.0132 14.0744 39.1562 14.0535C35.3792 10.4625 30.3276 8.19939 24.75 8.01254V9.75C24.75 10.1642 24.4142 10.5 24 10.5C23.5858 10.5 23.25 10.1642 23.25 9.75V8.01254C17.6723 8.19939 12.6208 10.4625 8.84368 14.0536C8.98647 14.0746 9.12399 14.1401 9.23389 14.25L10.2183 15.2344C10.4901 15.5062 10.4901 15.9469 10.2183 16.2188C9.94644 16.4906 9.50572 16.4906 9.23389 16.2188L8.24951 15.2344C8.13962 15.1245 8.07415 14.987 8.05311 14.8442C4.46232 18.6212 2.19939 23.6726 2.01254 29.25H3.75C4.16421 29.25 4.5 29.5858 4.5 30C4.5 30.4142 4.16421 30.75 3.75 30.75H2.01254C2.19939 36.3277 4.46251 41.3792 8.05358 45.1563C8.07454 45.0134 8.14003 44.8757 8.25005 44.7657L9.23442 43.7813C9.50625 43.5094 9.94697 43.5094 10.2188 43.7813C10.4906 44.0531 10.4906 44.4938 10.2188 44.7657L9.23442 45.75C9.12441 45.86 8.98672 45.9255 8.84377 45.9465C12.6208 49.5375 17.6724 51.8006 23.25 51.9875V50.25C23.25 49.8358 23.5858 49.5 24 49.5C24.4142 49.5 24.75 49.8358 24.75 50.25V51.9875C30.3276 51.8006 35.3792 49.5375 39.1562 45.9465C39.0132 45.9256 38.8755 45.8601 38.7654 45.75L37.7811 44.7656C37.5092 44.4938 37.5092 44.0531 37.7811 43.7813C38.0529 43.5094 38.4936 43.5094 38.7654 43.7813L39.7498 44.7656C39.8599 44.8757 39.9254 45.0134 39.9463 45.1564C43.5374 41.3793 45.8006 36.3277 45.9875 30.75H44.25C43.8358 30.75 43.5 30.4142 43.5 30C43.5 29.5858 43.8358 29.25 44.25 29.25Z"
        fill="#ffffff"
      />
      {renderMinutes()}
      <path
        d="M17.8699 43.4997V38.0215H18.5586L20.4368 42.4902L22.3151 38.0215H23.0038V43.4997H22.3151V39.5006L20.7107 43.4997H20.1629L18.5586 39.4771V43.4997H17.8699Z"
        fill="#E0E0E0"
      />
      <path d="M24.2591 43.4997V38.0215H24.9713V43.4997H24.2591Z" fill="#E0E0E0" />
      <path
        d="M26.2233 43.4997V38.0215H26.9824L29.4477 42.3102V38.0215H30.1285V43.4997H29.3772L26.912 39.2111V43.4997H26.2233Z"
        fill="#E0E0E0"
      />
    </svg>
  )
}

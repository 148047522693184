import { ImagePublicFileId } from '../image/image-public-file-id'
import { LockedProgrammeSvgIcon } from '../util/images'
import style from './training-series-card.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import { RunningSeries } from 'src/dao/core-dao'

export const RunningSeriesCard = ({ runningSeries }: { runningSeries: RunningSeries }): React.ReactElement => {
  return (
    <CardContentWrapper link={runningSeries.isAvailable ? `/training-series/${runningSeries.id}` : undefined}>
      <div className={style.trainingSeriesCardContainer}>
        <div className={style.trainingSeriesCardImageContainer}>
          {!runningSeries.isAvailable && (
            <div className={style.lockedImageContainer}>
              <h5>COMING SOON</h5>
              <LockedProgrammeSvgIcon />
            </div>
          )}
          <ImagePublicFileId fileId={runningSeries.thumbnailImagePublicFileId} className={style.trainingSeriesCardImage} />
        </div>
        <div className={`${style.trainingSeriesCardContent} p-3`}>
          <h3>{runningSeries.title}</h3>
          <p>{runningSeries.subTitle}</p>
        </div>
      </div>
    </CardContentWrapper>
  )
}
const CardContentWrapper = ({ link, children }: { link?: string; children: React.ReactNode }): React.ReactElement => {
  if (link) {
    return <Link to={link}>{children}</Link>
  }
  return <>{children}</>
}

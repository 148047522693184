import { ProgrammeCard } from '../../../component/programme-card/programme-card'
import { coreService } from '../../../service/core-service'
import { programmeUtil } from '../../../util/programme-util'
import styles from './profile-workouts-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { CompletedWorkoutSection } from 'src/component/profile/completed-workout-section'
import { ProgrammePreviewModal } from 'src/component/programme-preview-modal/programme-preview-modal'
import { CompletedWorkout, HustlerProgramme, Intensity, Programme, ProgrammeDuration, Workout, coreDao } from 'src/dao/core-dao'
import { timeUtil } from 'src/util/time-util'

export const ProfileWorkoutsScreen = (): React.ReactElement => {
  const [completedProgrammes, setCompletedProgrammes] = useState<HustlerProgramme[]>()
  const [startedProgrammes, setStartedProgrammes] = useState<HustlerProgramme[]>([])
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkout[]>()
  const [programmeIntensities, setProgrammeIntensities] = useState<Intensity[]>([])
  const [previewProgramme, setPreviewProgramme] = useState<Programme>()
  const [durations, setDurations] = useState<Map<string, ProgrammeDuration>>(new Map())
  const [programmes, setProgrammes] = useState<Programme[]>()

  const history = useHistory()
  useEffect(() => {
    coreDao.getMyCompletedProgrammes().then((response) => {
      setCompletedProgrammes(response)
    })
    coreDao.getMyStartedProgrammes().then((response) => {
      setStartedProgrammes(response)
    })
    coreDao.getCompletedWorkouts().then((response) => {
      setCompletedWorkouts(response)
    })
    coreDao.listProgrammeIntensities().then((response) => {
      setProgrammeIntensities(response)
    })
    coreDao.getProgrammes().then((response) => {
      setProgrammes(response)
    })
  }, [])

  useEffect(() => {
    if (!programmes?.length) return

    programmeUtil.getProgrammeDurations(programmes).then((durationResults) => {
      setDurations(programmeUtil.programmeIdDurationsToMap(durationResults))
    })
  }, [programmes])

  const getIntensity = (intensityId: string | undefined): Intensity | undefined => {
    return programmeIntensities.find((i) => i.id === intensityId)
  }
  const getDuration = (programmeId: string | undefined): ProgrammeDuration | undefined => {
    if (!programmeId) {
      return undefined
    }
    return durations.get(programmeId)
  }

  const selectProgramme = (p: Programme): void => {
    const isProgrammeStarted = startedProgrammes.some(({ programme: { id: startedProgrammeId } }) => startedProgrammeId === p.id)
    if (isProgrammeStarted) {
      history.push(`/programmes/${p.id}`)
      return
    }
    setPreviewProgramme(p)
  }
  const onStartProgramme = (): void => {
    if (!previewProgramme) {
      return
    }
    const isProgrammeStarted = programmeUtil.isProgrammeStarted(previewProgramme.id, startedProgrammes)
    const startProgrammeRequest = isProgrammeStarted ? Promise.resolve() : coreService.startProgramme(previewProgramme.id)
    startProgrammeRequest.then(() => history.push(`/programmes/${previewProgramme.id}`))
  }
  return (
    <>
      <ProgrammePreviewModal
        programme={previewProgramme}
        toggle={() => setPreviewProgramme(undefined)}
        intensity={getIntensity(previewProgramme?.intensityId)}
        programmeDuration={getDuration(previewProgramme?.id)}
        onStartProgramme={onStartProgramme}
      />
      <div className={styles.container}>
        <div>
          <h2 className={`mt-5 mb-3 ${styles.title}`}>Completed programmes</h2>
          <Row>
            {completedProgrammes ? (
              <CompletedProgrammesSection
                completedProgrammes={completedProgrammes}
                getIntensity={getIntensity}
                getDuration={getDuration}
                selectProgramme={selectProgramme}
              />
            ) : (
              <p className="pl-3">Loading</p>
            )}
          </Row>
        </div>
        <div className={styles.completedWorkouts}>
          <h2 className={`mt-5 mb-3 ${styles.title}`}>Completed workouts [ {completedWorkouts?.length ?? '-'} ]</h2>
          {completedWorkouts ? <CompletedWorkoutsSections completedWorkouts={completedWorkouts} /> : <p>Loading</p>}
        </div>
      </div>
    </>
  )
}

const CompletedWorkoutsSections = ({ completedWorkouts }: { completedWorkouts: CompletedWorkout[] }): React.ReactElement => {
  if (completedWorkouts.length === 0) {
    return <p className={styles.notFound}>No workouts found</p>
  }

  const sectionsRecord = completedWorkouts
    .map((cw) => {
      const completedAtMillis = parseInt(cw.completedAt)
      return {
        month: timeUtil.timestampToMonth(completedAtMillis),
        workout: cw.workout,
      }
    })
    .reduce((acc, cur) => {
      acc[cur.month] = acc[cur.month] ?? []
      acc[cur.month].push(cur.workout)
      return acc
    }, {} as Record<string, Workout[]>)
  const completedWorkoutsSections = Object.entries(sectionsRecord).map(([key, value]) => {
    return { title: key, data: value }
  })
  return (
    <>
      {completedWorkoutsSections.map(({ title, data }) => (
        <CompletedWorkoutSection key={title} title={title} workouts={data} />
      ))}
    </>
  )
}
const CompletedProgrammesSection = ({
  completedProgrammes,
  getIntensity,
  getDuration,
  selectProgramme,
}: {
  completedProgrammes: HustlerProgramme[]
  getIntensity: (intensityId: string) => Intensity | undefined
  selectProgramme: (programme: Programme) => void
  getDuration: (id: string) => ProgrammeDuration | undefined
}): React.ReactElement => {
  if (completedProgrammes.length === 0) {
    return <span className={`${styles.notFound} pl-3`}>Currently there are no completed programmes</span>
  }
  return (
    <>
      {completedProgrammes.map((p) => (
        <Col key={p.id} sm={12} lg={6} xl={4}>
          <div className="d-flex justify-content-center py-2">
            <ProgrammeCard
              title={p.programme.title}
              intensity={getIntensity(p.programme.intensityId)}
              image={p.programme.imagePublicFileId}
              programmeDuration={getDuration(p.programme.id)}
              onClick={() => selectProgramme(p.programme)}
            />
          </div>
        </Col>
      ))}
    </>
  )
}

import { WorkoutCard } from '../programmes/programme-workout-card'
import styles from './completed-workout-list-with-month-section.module.scss'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Workout } from 'src/dao/core-dao'
import { stringUtil } from 'src/util/string-util'

type CompletedWorkoutSectionProps = {
  title: string
  workouts: Workout[]
}

export const CompletedWorkoutSection = ({ title, workouts }: CompletedWorkoutSectionProps): React.ReactElement => {
  const history = useHistory()
  const renderSectionHeader = (): React.ReactElement => {
    const itemCount = workouts.length
    return (
      <h3 className={styles.dateContainer}>
        {title} - {stringUtil.pluralizeIfNeeded({ itemCount, singular: 'Workout' })}
      </h3>
    )
  }

  return (
    <div className={styles.container}>
      {renderSectionHeader()}
      <Row>
        {workouts.map((completedWorkout: Workout) => (
          <Col key={completedWorkout.id} sm={12} lg={6} xl={4} className="mb-4">
            <WorkoutCard
              selectWorkout={() => history.push(`/workouts/${completedWorkout.id}`)}
              workout={completedWorkout}
              isCompleted={false}
              isLocked={false}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

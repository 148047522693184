import { ProgrammeAvailability } from './programme-availability'
import { ProgrammeDurationIntensityInfo } from './programme-duration-intensity-info'
import { ProgrammeDurationTimespan } from './programme-duration-timestan'
import styles from './programme-info-container.module.scss'
import { ProgressReportButton } from './programme-progress-report-button'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ImagePublicFileId } from 'src/component/image/image-public-file-id'
import { HustlerProgramme, Intensity, ProgrammeDuration, WorkoutEquipmentPiece, coreDao } from 'src/dao/core-dao'

interface ProgrammeInfoContainerProps {
  showProgressReport: boolean
  onShowProgressReport: React.Dispatch<React.SetStateAction<boolean>>
  hustlerProgramme: HustlerProgramme
}

export const ProgrammeInfoContainer = ({
  showProgressReport,
  onShowProgressReport,
  hustlerProgramme,
}: ProgrammeInfoContainerProps): React.ReactElement => {
  const [duration, setDuration] = useState<ProgrammeDuration | undefined>(undefined)
  const [equipment, setEquipment] = useState<WorkoutEquipmentPiece[]>([])
  const [programmeIntensity, setProgrammeIntensity] = useState<Intensity>()
  const startedAt = parseInt(hustlerProgramme.startedAt.toString())

  useEffect(() => {
    coreDao.getProgrammeDuration({ programmeId: hustlerProgramme.programme.id }).then((response) => {
      setDuration(response)
    })

    coreDao.getProgrammeEquipmentList({ programmeId: hustlerProgramme.programme.id }).then((response) => {
      setEquipment(response)
    })

    coreDao.listProgrammeIntensities().then((response) => {
      const filteredIntensity = response.find((it) => it.id === hustlerProgramme.programme.intensityId)
      setProgrammeIntensity(filteredIntensity)
    })
  }, [hustlerProgramme.programme.id])

  const renderEquipmentPieces = (): string => {
    if (!equipment) return ''
    if (equipment.length === 0) return 'No equipment is required for this programme'
    return equipment.map((eq) => eq.label).join(', ')
  }

  return (
    <div>
      <ImagePublicFileId fileId={hustlerProgramme.programme.imagePublicFileId} className={styles.coverImage} />
      <div className={`p-0 mx-0 ${styles.titleContainer}`}>
        <Row>
          <Col xs={12} md={8}>
            <h2>{hustlerProgramme.programme.title}</h2>
          </Col>
          <Col xs={12} md={4}>
            <ProgrammeAvailability
              className={styles.time}
              startedAt={startedAt}
              numberOfWeeks={hustlerProgramme.programme.weekDescription.length}
            />
          </Col>
        </Row>
      </div>
      <div className={styles.topDetails}>
        <ProgrammeDurationIntensityInfo duration={duration} intensity={programmeIntensity} />
        <ProgressReportButton showProgressReport={showProgressReport} onShowProgressReport={onShowProgressReport} />
      </div>
      {hustlerProgramme.programme.description !== '' && (
        <p className={styles.description}>{hustlerProgramme.programme.description}</p>
      )}
      <div className={styles.bottomDetails}>
        <div className={styles.leftSection}>
          {equipment && (
            <div>
              <h4 className={styles.detailsTitle}>Equipment</h4>
              <p className={styles.detailsDescription}>{renderEquipmentPieces()}</p>
            </div>
          )}
          {hustlerProgramme.programme.shortDescription !== '' && (
            <div className={styles.detailsContainer}>
              <h4 className={styles.detailsTitle}>Designed for</h4>
              <p className={styles.detailsDescription}>{hustlerProgramme.programme.shortDescription}</p>
            </div>
          )}
        </div>
        <ProgrammeDurationTimespan duration={duration} />
      </div>
    </div>
  )
}

import { coreService } from '../../service/core-service'
import { logger } from '../../util/logger'
import { LoadingSpinner } from '../util/spinner/loading-spinner'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export const TermsAndConditions = (): React.ReactElement => {
  const [termsAndConditions, setTermsAndConditions] = useState<string>()

  useEffect(() => {
    coreService
      .getLegalTermsAndConditions()
      .then((result) => setTermsAndConditions(result))
      .catch((err) => logger.error(err))
  })

  return (
    <LoadingSpinner isLoading={!termsAndConditions}>
      <ReactMarkdown
        className={'markdown-table'}
        remarkPlugins={[remarkGfm]}
        children={termsAndConditions!}
        transformLinkUri={(src: string): string => {
          if (src[0] === '#') return window.location.pathname + src
          return src
        }}
      />
    </LoadingSpinner>
  )
}

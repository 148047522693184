import { timeUtil as bcTimeUtil } from '@beecode/msh-node-util/lib/time-util'
import { addDays, addWeeks, format, parse } from 'date-fns'

export const timeUtil = {
  ...bcTimeUtil,
  customFormatToDate: (dateString: string, customFormat: string): Date => {
    return parse(dateString, customFormat, new Date())
  },
  stringToDate: (dateString: string): Date => {
    return new Date(dateString)
  },
  now: (): Date => {
    return new Date()
  },
  stringToDateFormat: (dateString: string, { dateSep }: { dateSep?: string } = {}): string => {
    const date = timeUtil.stringToDate(dateString)
    return format(date, ['dd', 'MM', 'yyyy'].join(dateSep ?? '/'))
  },
  stringToDateTimeFormat: (
    dateString: string,
    { dateSep, timeSep, dateTimeSep }: { dateSep?: string; timeSep?: string; dateTimeSep?: string } = {},
  ): string => {
    const date = timeUtil.stringToDate(dateString)
    return format(date, [['HH', 'mm'].join(timeSep ?? ':'), ['dd', 'MM', 'yyyy'].join(dateSep ?? '/')].join(dateTimeSep ?? ' '))
  },
  stringToTimeFormat: (dateString: string, { timeSep }: { timeSep?: string } = {}): string => {
    const date = timeUtil.stringToDate(dateString)
    return format(date, ['HH', 'mm'].join(timeSep ?? ':'))
  },
  fromToDateTimeFormat: (
    fromString: string,
    toString: string,
    options: { dateSep?: string; timeSep?: string; dateTimeSep?: string; fromToSep?: string } = {},
  ): string => {
    const fromTime = timeUtil.stringToTimeFormat(fromString, options)
    const toTime = timeUtil.stringToTimeFormat(toString, options)
    const fromDate = timeUtil.stringToDateFormat(fromString, options)
    const toDate = timeUtil.stringToDateFormat(toString, options)
    const from = fromDate === toDate ? fromTime : [fromTime, fromDate].join(' ')
    const to = [toTime, toDate].join(' ')
    return [from, to].join(options.fromToSep ?? ' - ')
  },
  stringToUnixMS: (dateString: string): number => {
    return timeUtil.stringToDate(dateString).getTime()
  },
  localToUtcDateTime: (localDate: Date): Date => {
    const offset = localDate.getTimezoneOffset() * 60 * 1000
    return new Date(localDate.getTime() - offset)
  },
  dateTimeToDateString: (date: Date): string => {
    return date.toISOString().slice(0, 10)
  },
  dateToDayOfWeek: (date: Date): string => {
    return format(date, 'EEEE')
  },
  dateToMonth: (date: Date): string => {
    return format(date, 'MMMM')
  },
  dateToDayOfWeekId: (date: Date): number => {
    return date.getDay()
  },
  formatInDate: (date: Date): string => {
    return format(date, 'yyyy-MM-dd')
  },
  dateToDateFormatWithShortMonth: (date: Date | number): string => {
    return format(date, ['d', 'MMM', 'yyyy'].join(' ')).toLowerCase()
  },
  timestampToDateFormatWithNumericMonth: (date: Date | number): string => {
    return format(date, ['d', 'M', 'yy'].join('/')).toLowerCase()
  },
  addDays: (timestamp: number | Date, numberOfDays: number): Date => {
    return addDays(timestamp, numberOfDays)
  },
  addWeeks: (timestamp: number | Date, numberOfWeeks: number): Date => {
    return addWeeks(timestamp, numberOfWeeks)
  },
  timestampToMonth: (timestamp: number): string => {
    const date = new Date(timestamp)
    return timeUtil.dateToMonth(date)
  },
  dateToMonthYear: (date: Date): string => {
    return date.toLocaleString('en-uk', { month: 'long', year: 'numeric' })
  },
}

import { useFilterContext } from '../../context/filter-context'
import { WorkoutType, coreDao } from '../../dao/core-dao'
import { FilterIcon } from './filter-icon'
import { FilterItemButton } from './filter-item-button'
import { SidebarFilterSection } from './sidebar-filter-section'
import styles from './workout-type-filter.module.scss'
import React, { useEffect, useState } from 'react'

export const WorkoutTypeFilter = (): React.ReactElement => {
  const { workoutFilter, toggleFilterEntry } = useFilterContext()
  const [allTypes, setAllTypes] = useState<WorkoutType[]>([])

  useEffect(() => {
    coreDao.getWorkoutTypes().then(setAllTypes)
  }, [])

  const handleToggleSelectedType = (toggleValue: string): void => {
    toggleFilterEntry('types', toggleValue)
  }

  const renderHouseCategory = (type: WorkoutType, additionalClass?: string): React.ReactElement => {
    const isActive = workoutFilter?.types?.includes(type.id) ?? false
    return (
      <FilterItemButton
        className={`${styles.workoutTypeContainer} ${additionalClass}`}
        isActive={isActive}
        onClick={() => handleToggleSelectedType(type.id)}
      >
        <FilterIcon filterEntry={type} active={isActive} />
        <span>{type.label}</span>
      </FilterItemButton>
    )
  }

  return <SidebarFilterSection title={'Workout Type'} items={allTypes} lineLimit={4} renderItem={renderHouseCategory} />
}

import { Hustler } from '../../dao/core-dao'
import { authService } from '../../service/auth-service'
import { logger } from '../../util/logger'
import { ErrorMessageHint } from '../util/error-message-hint'
import { PasswordToggle } from '../util/password-toggle'
import { OverlaySpinner } from '../util/spinner/overlay-spinner'
import styles from './profile-info-form.module.scss'
import { passwordService } from '@grndhouse/react-common'
import { JoiErrorCode, joiUtil } from '@grndhouse/react-common/lib/util/joi-util'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { coreService } from 'src/service/core-service'

const usernameSchema = Joi.object()
  .keys({
    username: Joi.string().min(1).max(50),
  })
  .required()

const passwordSchema = Joi.object()
  .keys({
    password: passwordService.joiPasswordValidationObject.required(),
    newPassword: passwordService.joiPasswordValidationObject.required().label('New password'),
    confirmNewPassword: Joi.string()
      .valid(Joi.ref('newPassword'))
      .required()
      .label('Confirm new password')
      .error(
        joiUtil.errorMessageMap({
          [JoiErrorCode.ANY_ONLY]: 'Your new password do not match. Please try again.',
          [JoiErrorCode.ANY_EMPTY]: 'Confirm new password is required.',
        }),
      ),
  })
  .required()

type UsernameValues = {
  username?: string
}

type PasswordValues = {
  password: string
  newPassword: string
  confirmNewPassword: string
}

export const ProfileInfoForm = (props: { hustler: Hustler; onSave?: () => void }): React.ReactElement => {
  const { hustler, onSave } = props
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [showPasswordContainer, setShowPasswordContainer] = useState<boolean>(false)

  useEffect(() => {
    setNewPasswordVisible(false)
    resetForm()
  }, [showPasswordContainer])

  const {
    register: usernameRegister,
    handleSubmit: usernameHandleSubmit,
    reset: usernameResetForm,
    formState: { errors: usernameErrors },
  } = useForm<UsernameValues>({
    resolver: joiResolver(usernameSchema),
    defaultValues: {
      username: undefined,
    },
  })

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<PasswordValues>({
    resolver: joiResolver(passwordSchema),
  })

  const onSubmitUsername = async (data: UsernameValues): Promise<void> => {
    try {
      if (setIsLoading) setIsLoading(true)
      setErrorMessage(undefined)
      const { username } = data
      await coreService.updateMe({
        profile: {
          username,
        },
      })
      usernameResetForm()
      setShowPasswordContainer(false)
      if (onSave) onSave()
    } catch (err: any) {
      logger.error(err)
      setErrorMessage(err.message)
    } finally {
      if (setIsLoading) setIsLoading(false)
    }
  }

  const onSubmit = async (data: PasswordValues): Promise<void> => {
    try {
      if (setIsLoading) setIsLoading(true)
      setErrorMessage(undefined)
      const { password, newPassword } = data
      await authService.changePassword({ password, newPassword })
      resetForm()
      setShowPasswordContainer(false)
      if (onSave) onSave()
    } catch (err: any) {
      logger.error(err)
      setErrorMessage(err.message)
    } finally {
      if (setIsLoading) setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!hustler?.profile) return
    const { username } = hustler.profile

    usernameResetForm({
      ...(username && { username }),
    })
  }, [hustler])

  return (
    <div className={`mt-3 ${styles.container}`}>
      <div className="mb-0">
        <Form onSubmit={usernameHandleSubmit(onSubmitUsername)}>
          <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.profileFormGroup}`}>
            <Form.Label column sm="12" className={`p-0 ${styles.profileFormGroupLabel}`}>
              Username
            </Form.Label>
            <Col sm="12" className={styles.profileFormGroupCol}>
              <InputGroup className={`${usernameErrors.username ? 'is-invalid' : ''}`}>
                <Form.Control
                  type="text"
                  {...usernameRegister('username')}
                  className={`p-0 ${usernameErrors.username ? 'is-invalid' : ''}`}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {usernameErrors.username && <p>{usernameErrors.username.message}</p>}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <button type="submit" className={`p-0 mb-4 mt-2 ${styles.saveInfoBtn}`}>
            Save
          </button>
          <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.profileFormGroup}`}>
            <Form.Label column sm="12" className={`p-0 mt-0 ${styles.profileFormGroupLabel}`}>
              Email
            </Form.Label>
            <Col sm="12" className={styles.profileFormGroupCol}>
              <p className="form-control readonly p-0">{hustler?.email}</p>
            </Col>
          </Form.Group>
        </Form>

        {showPasswordContainer && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Col} className={`${styles.changePasswordContainer} px-0 mb-0 pb-0`}>
              <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.profileFormGroup}`}>
                <Form.Label column sm="12" className={`p-0 mt-0 ${styles.profileFormGroupLabel}`}>
                  Current Password
                </Form.Label>
                <Col sm="12" className={styles.profileFormGroupCol}>
                  <PasswordToggle onToggle={setPasswordVisible}>
                    <Form.Control
                      type={passwordVisible ? 'text' : 'password'}
                      {...register('password')}
                      className={`p-0 ${errors.password ? `is-invalid ${styles.isInvalidReset}` : ''}`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password && <p>{errors.password.message}</p>}
                    </Form.Control.Feedback>
                  </PasswordToggle>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.profileFormGroup}`}>
                <Form.Label column sm="12" className={`p-0 mt-0 ${styles.profileFormGroupLabel}`}>
                  New Password
                </Form.Label>
                <Col sm="12" className={styles.profileFormGroupCol}>
                  <PasswordToggle onToggle={setNewPasswordVisible} isVisible={newPasswordVisible}>
                    <Form.Control
                      type={newPasswordVisible ? 'text' : 'password'}
                      {...register('newPassword')}
                      className={`p-0 ${errors.newPassword ? `is-invalid ${styles.isInvalidReset}` : ''}`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword && <p>{errors.newPassword.message}</p>}
                    </Form.Control.Feedback>
                  </PasswordToggle>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className={`mb-3 thin p-0 ${styles.profileFormGroup}`}>
                <Form.Label column sm="12" className={`p-0 mt-0 ${styles.profileFormGroupLabel}`}>
                  Confirm New Password
                </Form.Label>
                <Col sm="12" className={styles.profileFormGroupCol}>
                  <PasswordToggle onToggle={setNewPasswordVisible} isVisible={newPasswordVisible}>
                    <Form.Control
                      type={newPasswordVisible ? 'text' : 'password'}
                      {...register('confirmNewPassword')}
                      className={`p-0 ${errors.confirmNewPassword ? `is-invalid ${styles.isInvalidReset}` : ''}`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmNewPassword && <p>{errors.confirmNewPassword.message}</p>}
                    </Form.Control.Feedback>
                  </PasswordToggle>
                </Col>
              </Form.Group>
              <ErrorMessageHint message={errorMessage} />
              <Form.Group className={`mb-0 pb-0 ${styles.profileInfoBtnContainer}`}>
                <Form.Group className="p-0 m-0">
                  <button
                    onClick={() => {
                      setShowPasswordContainer(false)
                      setErrorMessage(undefined)
                    }}
                    className={`p-0 mb-0 ${styles.cancelInfoBtn}`}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={`p-0 mb-0 ${styles.saveInfoBtn}`}>
                    Save
                  </button>
                </Form.Group>
              </Form.Group>
            </Form.Group>
          </Form>
        )}

        {!showPasswordContainer && (
          <div onClick={() => setShowPasswordContainer(true)} className={styles.changePasswordBtn}>
            Change password
          </div>
        )}
      </div>
      {isLoading && <OverlaySpinner />}
    </div>
  )
}

import { ReactElement } from 'react'

export const CloseFilterButtonIcon = (): ReactElement => {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.710887" y1="13.5921" x2="18.6239" y2="0.896751" stroke="#1F1F1F" />
      <line x1="0.288302" y1="0.591488" x2="19.2013" y2="13.9391" stroke="#1F1F1F" />
    </svg>
  )
}

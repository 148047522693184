import { timeUtil } from '../../util/time-util'
import { ImagePublicFileId } from '../image/image-public-file-id'
import { ProgrammeInfoPill } from '../programme-info-pill/programme-info-pill'
import { LockedProgrammeSvgIcon } from '../util/images'
import styles from './programme-workout-card.module.scss'
import { WorkoutCompletedCheckmark } from './workout-completed-checkmark'
import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { SessionContext } from 'src/context/session-context'
import { Workout } from 'src/dao/core-dao'

interface WorkoutCardProps {
  workout: Workout
  isCompleted: boolean
  showDate?: boolean
  isLocked: boolean
  size?: 'small' | 'large'
  selectWorkout?: () => void
}

export const WorkoutCard = ({
  workout,
  isCompleted,
  isLocked,
  selectWorkout,
  showDate = true,
  size = 'large',
}: WorkoutCardProps): React.ReactElement => {
  const {
    getFirstTrainerFromWorkout,
    renderDuration,
    hustlerConfig: { showWorkoutDates },
  } = useContext(SessionContext)
  const trainer = getFirstTrainerFromWorkout(workout)
  const duration = renderDuration(workout.durationId)

  const title = workout.title

  const onCardClick = (): void => {
    selectWorkout?.()
  }

  if (size === 'small') {
    let availableAt
    if (workout.availableAt) {
      const timestamp = parseInt(workout.availableAt)
      const date = new Date(timestamp).toString()
      availableAt = timeUtil.stringToDateFormat(date)
    }

    return (
      <div onClick={onCardClick} className={`${styles.smallWorkoutCardContainer} p-2`}>
        <Row className="h-100 d-flex justify-content-between">
          <Col xs={4} className="h-100 mr-3">
            <div className="position-relative">
              <div className={styles.checkmarkContainer}>
                <WorkoutCompletedCheckmark isVisible={isCompleted} />
              </div>
              <ImagePublicFileId fileId={workout.imagePublicFileId} className={styles.smallWorkoutCardImage} />
            </div>
          </Col>
          <Col className="w-100 p-0 d-flex flex-column justify-content-between">
            <Row className="w-100 mb-3">
              <h3>{workout.title}</h3>
            </Row>
            <Row className="d-flex justify-content-between align-items-center w-100">
              <p className={styles.trainerName}>{trainer?.firstName}</p>
              <div className={styles.cardInfo}>
                {showWorkoutDates && <p className={styles.cardDate}>{availableAt?.toString()}</p>}
                {duration && <ProgrammeInfoPill text={duration} />}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Row onClick={onCardClick} className={`d-flex flex-row p-2 mx-0 ${styles.container} ${isLocked && styles.isLocked}`}>
      <Col xs={12} sm={4} className={`${styles.imageBox} p-0`}>
        <div className={styles.checkmarkContainer}>
          <WorkoutCompletedCheckmark isVisible={isCompleted} />
        </div>
        {isLocked && <LockedProgrammeSvgIcon className={styles.lockedWorkout} />}
        <ImagePublicFileId
          fileId={workout.imagePublicFileId}
          className={`${styles.thumbnail} ${isLocked && styles.thumbnailOpacity}`}
        />
      </Col>

      <Col xs={12} sm={8} className={`flex-grow-1 d-flex flex-column justify-content-between pl-3 py-2 ${styles.content}`}>
        {!!title && <h3 className={styles.workoutTitle}>{title}</h3>}
        <div className={`d-flex flex-row w-100 justify-content-between align-items-center`}>
          {trainer && <h4 className={styles.trainerName}>{trainer.firstName}</h4>}
          <div className="d-flex flex-row align-items-center">
            {showDate && showWorkoutDates && (
              <div>{timeUtil.timestampToDateFormatWithNumericMonth(parseInt(workout.availableAt))}</div>
            )}
            <div className={styles.workoutDuration}>{!!duration && <span>{duration}</span>}</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

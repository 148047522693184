import { WorkoutPopup } from '../programmes/programme-workout-popup'
import { WorkoutCarouselCard } from '../workout-carousel-card/workout-carousel-card'
import style from './carousel-section.module.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import { Workout } from 'src/dao/core-dao'

export const CarouselSection = ({
  workoutsList,
  isNew = false,
}: {
  workoutsList: Workout[]
  isNew?: boolean
}): React.ReactElement => {
  const [index, setIndex] = useState(0)
  const [workoutChunks, setWorkoutChunks] = useState<Workout[][]>([])
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  const handleSelect = (selectedIndex: number): void => {
    setIndex(selectedIndex)
  }

  const getChunkSize = (): number => {
    if (window.matchMedia('(min-width: 992px)').matches) return 3 // lg+
    if (window.matchMedia('(min-width: 768px)').matches) return 2 // md+
    return 1 // below md
  }

  const chunkArray = (array: Workout[], chunkSize: number): Workout[][] => {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }
    return chunks
  }

  useEffect(() => {
    const updateChunks = (): void => {
      const chunkSize = getChunkSize()
      setWorkoutChunks(chunkArray(workoutsList, chunkSize))
    }

    updateChunks()

    window.addEventListener('resize', updateChunks)

    return () => {
      window.removeEventListener('resize', updateChunks)
    }
  }, [workoutsList])

  return (
    <>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className={style.Carousel}
        interval={null}
        indicators={false}
        keyboard={true}
        touch={true}
      >
        {workoutChunks.map((workouts, chunkIndex) => (
          <Carousel.Item key={chunkIndex}>
            <Row className="mx-n1">
              {workouts.map((w: Workout) => (
                <Col className="px-1" key={w.id} md={6} lg={4}>
                  <div className="d-flex justify-content-center py-2">
                    <WorkoutCarouselCard
                      workout={w}
                      isNew={isNew}
                      showPopup={true}
                      selectWorkout={() => setSelectedWorkout(w)}
                      key={w.id}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
      <WorkoutPopup
        setOnClose={() => setSelectedWorkout(undefined)}
        selectedWorkout={selectedWorkout}
        startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
      />
    </>
  )
}

import { ProfileAwardsSvgIcon, ProfileCaloriesSvgIcon, ProfileHusteSvgIcon, ProfileWorkoutsSvgIcon } from '../util/images'
import styles from './profile-stats-item.module.scss'
import React from 'react'

interface ProfileStatsItemProps {
  statsCount: number
  statsType: StatsType
}

const statsTypeToElements = {
  workouts: { label: 'Workouts', icon: <ProfileWorkoutsSvgIcon /> },
  calories: { label: 'Calories', icon: <ProfileCaloriesSvgIcon /> },
  hustle: { label: 'Hustle', icon: <ProfileHusteSvgIcon /> },
  awards: { label: 'Awards', icon: <ProfileAwardsSvgIcon /> },
}

export type StatsType = keyof typeof statsTypeToElements

export const ProfileStatsItem = ({ statsCount, statsType }: ProfileStatsItemProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      {statsTypeToElements[statsType].icon}
      <h4>{statsCount}</h4>
      <p>{statsTypeToElements[statsType].label}</p>
    </div>
  )
}

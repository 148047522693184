import { config } from '../util/config'
import { EventBusMessage } from '../util/event-bus-util'
import { coreService } from './core-service'
import mixpanel from 'mixpanel-browser'

// TODO: Move to react-common, we have the same in mobile app (removed programmes part since we don't have them yet)
export enum MixpanelEvent {
  WORKOUT_STARTED = 'Workout Started',
  WORKOUT_COMPLETED = 'Workout Completed',
  PAYWALL_OPENED = 'Paywall Opened',
  CHECKOUT_INITIATED = 'Checkout Initiated',
  SUBSCRIPTION_SET = 'Subscription Set',
}

export const mixpanelService = {
  init: async (): Promise<void> => {
    mixpanel.init(config.mixpanel.projectToken, { debug: config.mixpanel.debug, ignore_dnt: true })
    const myInfo = await coreService.getMe()

    mixpanel.identify(myInfo.id)
    mixpanel.people.set({ email: myInfo.email })
    mixpanel.people.set({ gender: myInfo.profile.gender })
    mixpanel.people.set({ dateOfBirth: myInfo.profile.dateOfBirth })
    mixpanel.people.set({ username: myInfo.profile.username })
    mixpanel.people.set({ $email: myInfo.email })
  },
  paywallOpened: (eventMessage: { message: EventBusMessage }): void => {
    mixpanel.track(MixpanelEvent.PAYWALL_OPENED, eventMessage)
  },

  sendCheckoutInitiated: (eventMessage: { message: EventBusMessage }): void => {
    mixpanel.track(MixpanelEvent.CHECKOUT_INITIATED, eventMessage)
  },

  sendSubscriptionSet: (eventMessage: { message: EventBusMessage }): void => {
    mixpanel.track(MixpanelEvent.SUBSCRIPTION_SET, eventMessage)
  },

  sendVideoPlaybackStarted: (eventMessage: { message: EventBusMessage }): void => {
    mixpanel.track(MixpanelEvent.WORKOUT_STARTED, eventMessage)
  },

  sendVideoPlaybackEnded: (eventMessage: { message: EventBusMessage }): void => {
    mixpanel.track(MixpanelEvent.WORKOUT_COMPLETED, eventMessage)
  },
}

import { AuthInitiate } from './init/auth-initiate'
import { MixpanelInitiate } from './init/mixpanel-initiate'
import { RxjsBusInitiate } from './init/rxjs-bus-initiate'
import { App } from '@beecode/msh-node-app'

export class WebApp extends App {
  constructor() {
    super(new RxjsBusInitiate(), new AuthInitiate(), new MixpanelInitiate())
  }
}

import React from 'react'
import { Container } from 'react-bootstrap'
import { PrivacyPolicy } from 'src/component/legal/privacy-policy'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'

export const PrivacyPolicyScreen = (): React.ReactElement => {
  return (
    <Container className="py-5">
      <BackArrow link="/profile/personal" />
      <br />
      <br />
      <PrivacyPolicy />
    </Container>
  )
}

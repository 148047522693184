import styles from './profile-personal-info-screen.module.scss'
import React, { useContext } from 'react'
import { Col, Nav, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { HustlerAvatar, ProfileAvatarSize } from 'src/component/profile/hustler-avatar'
import { PersonalInfoForm } from 'src/component/profile/personal-info-form'
import { ProfileActiveSubscription } from 'src/component/profile/profile-active-subscription'
import { ProfileInfoForm } from 'src/component/profile/profile-info-form'
import { SessionContext } from 'src/context/session-context'

export const ProfilePersonalInfoScreen = (): React.ReactElement => {
  const { me, refreshMyData } = useContext(SessionContext)

  return (
    <div className={`${styles.container} mb-5`}>
      <Row className={`mt-5 ${styles.mainContainer}`}>
        <Col sm={12} lg={4}>
          <h2 className={`${styles.title} m-0 pb-4`}>Personal info</h2>
          <HustlerAvatar hustler={me} size={ProfileAvatarSize.M} />
          <ProfileInfoForm hustler={me!} onSave={() => refreshMyData()} />
          <PersonalInfoForm hustler={me!} onSave={() => refreshMyData()} />
        </Col>
        <Col sm={12} lg={7}>
          <h2 className={`${styles.title} m-0 pb-4`}>Membership</h2>
          <ProfileActiveSubscription me={me} />
        </Col>
      </Row>
      <Nav.Link as={Link} className={styles.logoutBtn} to={'/sign-out'}>
        Log out
      </Nav.Link>
    </div>
  )
}

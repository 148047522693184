export const localStorageDao = {
  set: (key: string, data: string): void => {
    localStorage.setItem(key, data)
  },
  _get: (key: string): string | null => {
    return localStorage.getItem(key)
  },
  get: (key: string): string => {
    if (!localStorageDao.isSet(key)) throw new Error(`[${key}] is not set in local storage`)
    return localStorageDao._get(key)!
  },
  remove: (key: string): void => {
    localStorage.removeItem(key)
  },
  isSet: (key: string): boolean => {
    return localStorageDao._get(key) !== null
  },
}

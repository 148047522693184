import { InterceptorFunction, axiosDaoFactory } from '../axios'
import { authorizationHeaderInterceptor } from '../axios/interceptor/authorization-header-interceptor'
import { exposeErrorInterceptor } from '../axios/interceptor/expose-error-interceptor'
import { refreshTokenInterceptor } from '../axios/interceptor/refresh-token-interceptor'
import { CommonDao } from './common-dao-factory'

// TODO Remove old filters after launch of new filters
export const coreDaoV1Factory = ({
  onError,
  interceptors = [],
}: {
  onError?: (error: Error) => Promise<void>
  interceptors?: InterceptorFunction[]
} = {}): CommonDao => {
  const coreInterceptors = [...interceptors, authorizationHeaderInterceptor, refreshTokenInterceptor]
  const baseURL = `${window.location.origin.toString()}/api/core/`
  if (onError) interceptors.push(exposeErrorInterceptor({ callback: onError }))
  const axiosInstance = axiosDaoFactory({ baseURL, interceptors: coreInterceptors })

  const self = {
    _http: axiosInstance,
    healthCheck: (): unknown => {
      return self._http.get('/')
    },
  }

  return self
}

import { InterceptorFunction, axiosDaoFactory } from '../axios'
import { exposeErrorInterceptor } from '../axios/interceptor/expose-error-interceptor'
import { BackendType } from '../enum/backend-type'
import { AxiosInstance } from 'axios'

export type CommonDao = {
  _http: AxiosInstance
  healthCheck: () => unknown
}

export type CommonDaoFactoryParams = {
  backendType: BackendType
  interceptors?: InterceptorFunction[]
  onError?: (error: Error) => Promise<void>
}
const backendBaseUrl: Record<BackendType, string> = {
  [BackendType.AUTH]: BackendType.AUTH,
  [BackendType.FILE]: BackendType.FILE,
  [BackendType.MESSAGE]: BackendType.MESSAGE,
  [BackendType.CORE]: `${BackendType.CORE}/v2`,
}

export const commonDaoFactory = ({ backendType, interceptors = [], onError }: CommonDaoFactoryParams): CommonDao => {
  const baseURL = `${window.location.origin.toString()}/api/${backendBaseUrl[backendType]}/`
  if (onError) interceptors.push(exposeErrorInterceptor({ callback: onError }))
  const axiosInstance = axiosDaoFactory({ baseURL, interceptors })

  const self = {
    _http: axiosInstance,
    healthCheck: (): unknown => {
      return self._http.get('/')
    },
  }

  return self
}

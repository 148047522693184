import { useLocalPathName } from '../../hook/use-local-path-name'
import style from './profile-settings-nav.module.scss'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ProfileSettingsNav = (): React.ReactElement => {
  const [pathName] = useLocalPathName()

  const renderItem = (params: { title: string; path: string }): React.ReactElement => {
    const { title, path } = params
    return (
      <Nav.Item className={path === pathName ? style.active : ''}>
        <Nav.Link as={Link} to={path}>
          {title}
        </Nav.Link>
      </Nav.Item>
    )
  }

  return (
    <Nav className={`flex-column ${style.container}`}>
      {renderItem({ title: 'Profile info', path: '/profile-settings/profile' })}
      {renderItem({ title: 'Personal info', path: '/profile-settings/personal' })}
      {/* TODO: Removed until page is implemented */}
      {/*{renderItem({ title: 'Connect', path: '/profile-settings/connect' })}*/}
      {renderItem({ title: 'Membership', path: '/profile/personal' })}
      {/* TODO: Removed until page is implemented */}
      {/*{renderItem({ title: 'Notifications', path: '/profile-settings/notifications' })}*/}
      {/*{renderItem({ title: 'Get rewarded', path: '/profile-settings/get-rewarded' })}*/}
      {renderItem({ title: 'Legal', path: '/profile-settings/legal' })}
    </Nav>
  )
}

import MshNodeEnv from '@beecode/msh-node-env'
import { PrefixName } from '@beecode/msh-node-env/lib/naming'

const env = MshNodeEnv({ namingStrategies: [new PrefixName({ prefix: 'REACT_APP' })] })
const sentryEnabled = env('SENTRY_ENABLED').boolean.default(false).required

export const config = Object.freeze({
  logLevel: env('LOG_LEVEL').string.default('error').allowed('error', 'warn', 'info', 'debug').required as
    | 'error'
    | 'warn'
    | 'info'
    | 'debug',
  sentry: sentryEnabled && {
    dsn: env('SENTRY_DSN').string.required,
    sampleRate: env('SENTRY_TRACES_SAMPLE_RATE').number.required,
  },
  cloudfront: {
    mediaBaseUrl: env('CLOUDFRONT_MEDIA_BASE_URL').string.required,
    publicFileUrl: env('CLOUDFRONT_PUBLIC_FILE_URL').string.required,
  },
  featureFlag: {
    filtersEnabled: env('FEATURE_FLAG_REDESIGNED_FILTERS_ENABLED').boolean.default(false).required,
  },
  mixpanel: {
    projectToken: env('MIXPANEL_PROJECT_TOKEN').string.required,
    debug: env('MIXPANEL_DEBUG').boolean.default(false).required,
  },
})

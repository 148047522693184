import { errorUtil } from '../util/error-util'
import { authDaoFactory } from '@grndhouse/react-common'

const dao = authDaoFactory({
  onError: async (error: Error) => {
    errorUtil.handleHttpError(error)
  },
})

export const authDao = {
  ...dao,
  deactivateOwnAccount: async (): Promise<void> => {
    await dao._http.post(`/users/me/deactivate`)
  },
  changePassword: async (params: { password: string; newPassword: string }): Promise<void> => {
    const { password, newPassword } = params
    const url = `/users/me/password`
    await dao._http.patch(url, { password, newPassword })
  },
}

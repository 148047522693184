import { CompletedWorkoutFilter, WorkoutFilter } from '../service/core-service'
import { errorUtil } from '../util/error-util'
import { HttpResponse, coreDaoFactory } from '@grndhouse/react-common'
import Joi from 'joi'
import { validationUtil } from 'src/util/validation-util'

// TODO common-web: move to common-web or we should move it closer to node-core (or new common project between core FE and BE so
// that it's data can be used for testing i both ends
const dao = coreDaoFactory({
  onError: async (error: Error) => {
    errorUtil.handleHttpError(error)
  },
})
export const coreDao = {
  ...dao,
  getWorkouts: async (filter?: WorkoutFilter): Promise<Workout[]> => {
    const url = '/workouts'
    const result = await dao._http.get<HttpResponse<Workout>>(url, { params: filter })
    return result.data.data
  },
  getForDayWorkouts: async (filter?: WorkoutFilter): Promise<Workout[]> => {
    const url = '/workouts/for-day'
    const result = await dao._http.get<HttpResponse<Workout>>(url, { params: filter })
    return result.data.data
  },
  getForWeekWorkouts: async (filter?: WorkoutFilter): Promise<WorkoutSummary[]> => {
    const url = '/workouts/for-week'
    const result = await dao._http.get<HttpResponse<WorkoutSummary>>(url, { params: filter })
    return result.data.data
  },
  getWorkoutById: async (workoutId: string): Promise<Workout> => {
    const url = `/workouts/${workoutId}`
    const result = await dao._http.get<HttpResponse<Workout>>(url)
    return result.data.data[0]
  },
  getWorkoutTypes: async (): Promise<WorkoutType[]> => {
    const url = '/workout-types'
    const result = await dao._http.get<HttpResponse<WorkoutType>>(url)
    return result.data.data
  },
  listWorkoutStyles: async (): Promise<WorkoutStyle[]> => {
    const url = '/workout-styles'
    const result = await dao._http.get<HttpResponse<WorkoutStyle>>(url)
    return result.data.data
  },
  listWorkoutSections: async (): Promise<WorkoutSection[]> => {
    const url = '/workout-sections'
    const result = await dao._http.get<HttpResponse<WorkoutSection>>(url)
    return result.data.data
  },
  getBodyParts: async (): Promise<BodyPart[]> => {
    const url = '/body-parts'
    const result = await dao._http.get<HttpResponse<WorkoutType>>(url)
    return result.data.data
  },
  completeWorkout: async (workoutId: string): Promise<CompletedWorkout> => {
    const url = `/completed-workouts`
    const result = await dao._http.post<HttpResponse<CompletedWorkout>>(url, { workoutId })
    return result.data.data[0]
  },
  getHustlerMe: async (): Promise<Hustler> => {
    const url = `/hustlers/me`
    const result = await dao._http.get<HttpResponse<Hustler>>(url)
    return result.data.data[0]
  },
  getConfig: async (): Promise<HustlerConfig> => {
    const url = `/hustlers/me/config`
    const result = await dao._http.get<HttpResponse<HustlerConfig>>(url)
    return result.data.data[0]
  },
  patchHustlerMe: async (params: { profile: HustlerProfile }): Promise<Hustler> => {
    const url = `/hustlers/me`
    const result = await dao._http.patch<HttpResponse<Hustler>>(url, params)
    return result.data.data[0]
  },
  fetchCompletedChallenges: async (params?: { limit?: number }): Promise<CompletedChallenge[]> => {
    const { limit } = params ?? {}
    const url = `/hustlers/me/challenges/completed`
    const result = await dao._http.get<HttpResponse<CompletedChallenge>>(url, { params: { limit: limit } })
    return result.data.data
  },
  createCheckoutSession: async (priceId: string): Promise<string> => {
    const url = `/payments/checkout-session`
    const result = await dao._http.post<HttpResponse<string>>(url, { priceId })
    return result.data.data[0]
  },
  createPortalSession: async (): Promise<string> => {
    const url = `/payments/portal-session`
    const result = await dao._http.post<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getPrices: async (): Promise<Price[]> => {
    const url = `/payments/prices`
    const result = await dao._http.get<HttpResponse<Price>>(url)
    return result.data.data
  },
  getMySubscriptions: async (): Promise<StoreSubscription | undefined> => {
    const url = `/payments/me/subscriptions`
    const result = await dao._http.get<HttpResponse<StoreSubscription | undefined>>(url)
    return result.data.data[0]
  },
  getStripeSubscriptions: async (): Promise<Subscription[]> => {
    const url = `/payments/stores/stripe/subscriptions`
    const result = await dao._http.get<HttpResponse<Subscription[]>>(url)
    return result.data.data[0]
  },
  getTrainers: async (): Promise<Trainer[]> => {
    const url = `/trainers`
    const result = await dao._http.get<HttpResponse<Trainer>>(url)
    return result.data.data
  },
  getTrainer: async (params: { trainerId: string }): Promise<Trainer> => {
    const { trainerId } = params
    const url = `/trainers/${trainerId}`
    const result = await dao._http.get<HttpResponse<Trainer>>(url)
    return result.data.data[0]
  },
  getCompletedWorkouts: async (params?: { filter?: CompletedWorkoutFilter }): Promise<CompletedWorkout[]> => {
    const { filter } = params ?? {}
    const url = '/completed-workouts'
    const result = await dao._http.get<HttpResponse<CompletedWorkout>>(url, { params: filter })
    return result.data.data
  },
  getLegalTermsAndConditions: async (): Promise<string> => {
    const url = '/legal/terms-and-conditions'
    const result = await dao._http.get<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getLegalPrivacyPolicy: async (): Promise<string> => {
    const url = '/legal/privacy-policy'
    const result = await dao._http.get<HttpResponse<string>>(url)
    return result.data.data[0]
  },
  getWeekPromoVideo: async (): Promise<WeekPromoVideo> => {
    const url = '/week-promo-video/current'
    const result = await dao._http.get<HttpResponse<WeekPromoVideo>>(url)
    return result.data.data[0]
  },
  getWeeklyImage: async (params: { dayInWeek: number }): Promise<WeeklyImage> => {
    const { dayInWeek } = params
    const url = `/images/weekly/${dayInWeek}`
    const result = await dao._http.get<HttpResponse<WeeklyImage>>(url)
    return result.data.data[0]
  },
  getProgrammes: async (): Promise<Programme[]> => {
    const url = `/programmes`
    const result = await dao._http.get<HttpResponse<Programme>>(url)
    return result.data.data
  },
  getIntensity: async (params: { id: string }): Promise<Intensity> => {
    const { id } = params
    const url = `/intensities/${id}`
    const result = await dao._http.get<HttpResponse<Intensity>>(url)
    return result.data.data[0]
  },
  getProgrammeDuration: async (params: { programmeId: string }): Promise<ProgrammeDuration> => {
    const { programmeId } = params
    const url = `/programmes/${programmeId}/duration`
    const result = await dao._http.get<HttpResponse<ProgrammeDuration>>(url)
    return result.data.data[0]
  },
  listProgrammeIntensities: async (): Promise<Intensity[]> => {
    const url = `/programme-intensities`
    const result = await dao._http.get<HttpResponse<Intensity>>(url)
    return result.data.data
  },
  listWorkoutIntensities: async (): Promise<Intensity[]> => {
    const url = `/workout-intensities`
    const result = await dao._http.get<HttpResponse<Intensity>>(url)
    return result.data.data
  },
  getMyStartedProgrammes: async (): Promise<HustlerProgramme[]> => {
    const result = await dao._http.get<HttpResponse<HustlerProgramme>>('/hustlers/me/programmes')
    return result.data.data.map((hp) => {
      return validationUtil.validate(hp, hustlerProgrammeSchema)
    })
  },
  getMyCompletedProgrammes: async (): Promise<HustlerProgramme[]> => {
    const result = await dao._http.get<HttpResponse<HustlerProgramme>>('/hustlers/me/completed-programmes')
    return result.data.data.map((hp) => {
      return validationUtil.validate(hp, hustlerProgrammeSchema)
    })
  },
  startProgramme: async (programmeId: string): Promise<HustlerProgramme> => {
    const result = await dao._http.post('/hustlers/me/programmes', { programmeId })
    const data = result.data.data[0]
    if (!data) throw new Error('Unable to start programme')
    return validationUtil.validate(data, hustlerProgrammeSchema)
  },
  getProgrammeById: async (programmeId: string): Promise<Programme> => {
    const url = `/programmes/${programmeId}`
    const result = await dao._http.get<HttpResponse<Programme>>(url)
    const data = result.data.data[0]
    if (!data) throw new Error('Programme not found')
    return data
  },
  getProgrammeWorkouts: async (programmeId: string): Promise<ProgrammeWorkout[]> => {
    const url = `/programmes/${programmeId}/programme-workouts`
    const result = await dao._http.get<HttpResponse<ProgrammeWorkout>>(url)
    return result.data.data
  },
  getProgrammeWorkoutsAvailability: async (params: { programmeId: string }): Promise<ProgrammeWorkoutsAvailability[]> => {
    const { programmeId } = params
    const url = `/programmes/${programmeId}/programme-workouts-availability`
    const result = await dao._http.get<HttpResponse<ProgrammeWorkoutsAvailability>>(url)
    return result.data.data
  },
  completeProgrammeWorkout: async (params: {
    programmeId: string
    programmeWorkoutId: string
    maxHeartRate: number
    calories: number
    averageHeartRate: number
    hustleScore: number
  }): Promise<CompletedProgrammeWorkout> => {
    const { programmeId, programmeWorkoutId, maxHeartRate, calories, averageHeartRate, hustleScore } = params
    const url = `/hustlers/me/programmes/${programmeId}/programme-workouts/${programmeWorkoutId}/complete`
    const result = await dao._http.post<HttpResponse<CompletedProgrammeWorkout>>(url, {
      maxHeartRate,
      calories,
      averageHeartRate,
      hustleScore,
    })
    const data = result.data.data[0]
    if (!data) throw new Error('Unable to complete programme workout')
    return data
  },
  getMyCompletedProgrammeWorkoutsFromProgramme: async (programmeId: string): Promise<CompletedProgrammeWorkout[]> => {
    const url = `/hustlers/me/programmes/${programmeId}/completed-programme-workouts`
    const result = await dao._http.get<HttpResponse<CompletedProgrammeWorkout>>(url)
    return result.data.data
  },
  getAllAvailableProgrammeAwards: async (params: { programmeId: string }): Promise<Award[]> => {
    const { programmeId } = params
    const url = `programmes/${programmeId}/awards`
    const result = await dao._http.get<HttpResponse<Award>>(url)
    return result.data.data
  },
  getMyProgrammeAwardsByProgramme: async (programmeId: string): Promise<AwardDto[]> => {
    const url = `/hustlers/me/awards/programme/${programmeId}`
    const result = await dao._http.get<HttpResponse<AwardDto>>(url)
    return result.data.data
  },
  getHustlerProgramme: async (params: { programmeId: string }): Promise<HustlerProgramme> => {
    const { programmeId } = params
    const url = `/hustlers/me/programmes/${programmeId}`
    const result = await dao._http.get<HttpResponse<HustlerProgramme>>(url)
    return result.data.data[0]
  },
  addToFavorites: async (params: { workoutId: string }): Promise<FavWorkout> => {
    const url = `/favorite-workouts`
    const result = await dao._http.post<HttpResponse<FavWorkout>>(url, params)
    const data = result.data.data[0]
    if (!data) throw new Error('Unable to add to favorites')
    const { workout, id } = data
    workout.favoriteId = id
    return { ...data, workout }
  },
  removeFavoriteWorkout: async (params: { id: string }): Promise<void> => {
    const { id } = params
    const url = `/favorite-workouts/${id}`
    await dao._http.delete<HttpResponse<void>>(url)
  },
  getProgrammeEquipmentList: async (params: { programmeId: string }): Promise<WorkoutEquipmentPiece[]> => {
    const { programmeId } = params
    const url = `/programmes/${programmeId}/equipment-list`
    const result = await dao._http.get<HttpResponse<WorkoutEquipmentPiece>>(url)
    return result.data.data
  },
  getWorkoutEquipmentPieces: async (): Promise<WorkoutEquipmentPiece[]> => {
    const url = `/workout-equipment-pieces`
    const result = await dao._http.get<HttpResponse<WorkoutEquipmentPiece>>(url)
    return result.data.data
  },
  listRunningSeries: async (): Promise<RunningSeries[]> => {
    const url = `/running-series`
    const result = await dao._http.get<HttpResponse<RunningSeries>>(url)
    return result.data.data
  },
  getTrainingSeriesById: async (params: { trainingSeriesId: string }): Promise<RunningSeriesWithWorkouts> => {
    const { trainingSeriesId } = params
    const url = `/running-series/${trainingSeriesId}`
    const result = await dao._http.get<HttpResponse<RunningSeriesWithWorkouts>>(url)
    return result.data.data[0]
  },
  getFavoriteWorkouts: async (): Promise<FavWorkout[]> => {
    const url = '/favorite-workouts'
    const result = await dao._http.get<HttpResponse<FavWorkout>>(url)
    return result.data.data.map((item) => {
      const { workout, id } = item
      workout.favoriteId = id
      return { ...item, workout }
    })
  },
  getMyCurrentMonthWorkoutStats: async (): Promise<WorkoutStats[]> => {
    const url = 'hustlers/me/stats-breakdown/current-month'
    const result = await dao._http.get<HttpResponse<WorkoutStats>>(url)
    return result.data.data
  },
  getMyProgrammeAwards: async (): Promise<AwardDto[]> => {
    const url = '/hustlers/me/awards/programme-awards'
    const result = await dao._http.get<HttpResponse<AwardDto>>(url)
    return result.data.data
  },
  getCompletedChallenges: async (): Promise<CompletedChallenge[]> => {
    const url = '/hustlers/me/challenges/completed'
    const result = await dao._http.get<HttpResponse<CompletedChallenge>>(url)
    return result.data.data
  },
  getNotYetCompletedChallenges: async (): Promise<NotCompletedChallenge[]> => {
    const url = '/hustlers/me/challenges/not-completed'
    const result = await dao._http.get<HttpResponse<NotCompletedChallenge>>(url)
    return result.data.data
  },
  listWorkoutEquipmentGroups: async (): Promise<WorkoutEquipmentGroup[]> => {
    const url = '/workout-equipment-groups'
    const result = await dao._http.get<HttpResponse<WorkoutEquipmentGroup>>(url)
    return result.data.data
  },
  getWorkoutStyle: async (params: { workoutStyleId: string }): Promise<WorkoutStyle> => {
    const { workoutStyleId } = params
    const url = `/workout-styles/${workoutStyleId}`
    const result = await dao._http.get<HttpResponse<WorkoutStyle>>(url)
    return result.data.data[0]
  },
  listEducationHubCategories: async (): Promise<EducationHubCategory> => {
    const url = `/education-hub-categories`
    const result = await dao._http.get<HttpResponse<EducationHubCategory>>(url)
    return result.data.data[0]
  },
  listWorkoutDurations: async (): Promise<WorkoutDurationType[]> => {
    const url = `/workout-durations`
    const result = await dao._http.get<HttpResponse<WorkoutDurationType>>(url)
    return result.data.data
  },
}

export interface WorkoutEquipment {
  piece: EquipmentPiece
  equipmentPieceId: string
  optional: boolean
  quantity: number
}

export enum EquipmentPiece {
  BOOTY_BAND = 'booty_band',
  RESISTANCE_BAND = 'resistance_band',
  DUMBBELL = 'dumbbell',
  LIGHT_DUMBBELL = 'light_dumbbell',
  MEDIUM_DUMBBELL = 'medium_dumbbell',
  HEAVY_DUMBBELL = 'heavy_dumbbell',
  LIGHT_MEDIUM_DUMBBELL = 'light_medium_dumbbell',
  MEDIUM_HEAVY_DUMBBELL = 'medium_heavy_dumbbell',
  LIGHT_MEDIUM_HEAVY_DUMBBELLS = 'light_medium_heavy_dumbbell',
}

export enum EquipmentCategory {
  BAND = 'band',
  BODYWEIGHT = 'bodyweight',
  DUMBBELL = 'dumbbell',
}

export enum WorkoutIntensity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export type WorkoutDuration = {
  id: string
  name: string
  label: string
  durationValue: number
  durationUnit: string
  filterIconPublicFileId?: string
  filterActiveIconPublicFileId?: string
  isExpressDuration: boolean
  isFilterable: boolean
}

export type WorkoutSummary = {
  workout: Workout
  date: string
}

export type Workout = {
  id: string
  title: string
  description?: string
  typeId: string
  bodyPartIds: string[]
  videoUrl?: string
  previewVideoUrl?: string
  imagePublicFileId: string
  equipment: WorkoutEquipment[]
  intensity: WorkoutIntensity
  durationId: string
  duration?: WorkoutDuration
  availableAt: string
  favoriteId?: string
  intensityId?: string
  playlistUrl?: string
  trainers: Partial<Trainer>[]
}
export type WorkoutType = {
  id: string
  name: string
  label: string
  filterIconPublicFileId?: string
  filterActiveIconPublicFileId?: string
}
export type WorkoutStyle = {
  id: string
  name: string
  label: string
  filterIconPublicFileId?: string
  filterActiveIconPublicFileId?: string
}
export type WorkoutSection = {
  id: string
  label: string
}

export type BodyPart = {
  id: string
  name: string
  filterIconPublicFileId?: string
  filterActiveIconPublicFileId?: string
  parentBodyPartId?: string
}

export type CompletedWorkout = {
  id: string
  workout: Workout
  hustler: Partial<Hustler>
  hustleScore: number
  calories: number
  heartRate: number
  completedAt: string
}
export type Hustler = {
  id: string
  createdAt: Date
  updatedAt: Date
  createdById?: string
  updatedById?: string
  name: string
  authUserId: string
  email: string
  phoneNumber?: string
  profile: HustlerProfile
  // subscriptions: Partial<HustlerSubscription>[]
  // stats: Partial<HustlerStats>[]
  // friends: Partial<Hustler>[]
  // posts: Partial<Post>[]
  // awards: Partial<Award>[]
}
export type HustlerConfig = {
  showWorkoutDates: boolean
  newWorkoutsTitle: string
  showNewMonthlyWorkouts: boolean
}
export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  NON_BINARY = 'non_binary',
  N_A = 'n_a',
}

export enum WeightUnit {
  KG = 'kg',
  LB = 'lb',
}

export enum HeightUnit {
  CM = 'cm',
  FT = 'ft',
}

export type HustlerProfile = {
  profilePhotoFileId?: string
  username?: string
  gender?: Gender | null
  dateOfBirth?: Date | null
  weight?: { value: number; unit: WeightUnit } | null
  height?: { value: number; unit: HeightUnit } | null
}

export type TrainerSocialProfiles = Record<string, string>

export interface Trainer {
  id: string
  firstName: string
  lastName: string
  description: string
  profilePhotoPublicFileId: string
  mediumProfilePhotoPublicFileId: string
  socialProfiles?: TrainerSocialProfiles
}

export type Price = {
  id: string
  currency: string
  amount?: number
  recurring?: {
    interval: string
    intervalCount: number
  }
  product: {
    id: string
    name: string
    imageUrl: string | undefined
    trialDurationDays: number
  }
}

export type Store = 'stripe' | 'ios' | 'android'
export type SubscriptionType = '$rc_annual' | '$rc_monthly'
export type StoreSubscription = {
  store: Store
  productIdentifier: string
  type: SubscriptionType
}

export type Subscription = {
  id: string
  cancelAt?: number
  canceledAt?: number
  currentPeriodStart: number
  currentPeriodEnd: number
  status: string
  startDate: number
  items: SubscriptionItem[]
}

export type SubscriptionItem = {
  plan: {
    id: string
    amount: number
    currency: string
  }
  price: {
    id: string
    recurring?: {
      interval?: string
      intervalCount?: number
    }
  }
  product: {
    id: string
    name: string
  }
}

export type WeekPromoVideo = {
  title: string
  availableAt: number
  videoUrl: string
}

export enum ChallengeStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  TERMINATED = 'terminated',
}
export type ChallengeWorkoutsFilter = { type: string; category?: string } | { type: 'all' }
export type CompletionCriteria = {
  statistic?: string
  goal?: number
  filter?: ChallengeWorkoutsFilter
  timePeriod?: string
}

export type Challenge = {
  id: string
  title: string
  description: string
  imagePublicFileId: string
  startDate?: number
  endDate?: number
  criteria: CompletionCriteria
  status: ChallengeStatus
  categoryId: string
  autoStarted?: boolean
}

export interface ChallengeCategory {
  id: string
  name: string
  difficulty: number
}

export type CompletedChallenge = {
  challenge: Challenge
  completedAt?: number
}

export type WeeklyImage = {
  id: string
  dayInWeek: number
  publicFileId: string
}

export type ProgrammeWeekDescription = {
  order: number
  title: string
  description: string
}

export type Programme = {
  id: string
  title: string
  description: string
  shortDescription: string
  weekDescription: ProgrammeWeekDescription[]
  intensityId: string
  isDisabled: boolean
  availableAt: string
  imagePublicFileId: string
  order: number
}

export type Intensity = {
  id: string
  name: string
  label: string
  filterIconPublicFileId: string
  filterActiveIconPublicFileId: string
  intensityGroup: string
}

export type InfoTabItem = {
  order: number
  title: string
  description: string
}

export interface Award {
  id: string
  name: string
  imagePublicFileId: string
}

export type ProgrammeDuration = {
  weeks: {
    count: number
  }
  classes: {
    count: number
    shortestDuration: string
    longestDuration: string
  }
}

export type ProgrammeWorkoutsAvailability = {
  onWeek: number
  isWeekLocked: boolean
  areProgrammeWorkoutsInWeekLocked: boolean
}

export interface ProgrammeWorkout {
  id: string
  order: number
  onWeek: number
  isDisabled: boolean
  isCompleted?: boolean
  workout: Workout
  workoutId: string
}

export type MotivationalVideo = {
  videoUrl: string
  order: number
  isDisabled: boolean
}

export type MotivationalImage = {
  imagePublicFileId: string
  order: number
  isDisabled: boolean
}

export interface HustlerProgramme {
  id: string
  startedAt: string
  programme: Programme
  hustler: Hustler
}

export interface CompletedProgrammeWorkout {
  id: string
  hustlerProgramme: Partial<HustlerProgramme>
  programme: Partial<Programme>
  programmeWorkout: Partial<ProgrammeWorkout>
  completedWorkout: Partial<CompletedWorkout>
  workout: Partial<Workout>
}

export type WorkoutEquipmentPiece = {
  piece: string
  quantity: number
  optional: boolean
  id: string
  name: string
  label?: string
  workoutEquipmentGroupId?: string
  workoutEquipmentGroup?: Partial<WorkoutEquipmentGroup>
}

export type WorkoutEquipmentGroup = {
  id: string
  name: string
  label: string
  order: number
  filterIconPublicFileId?: string
  filterActiveIconPublicFileId?: string
  isNoEquipmentGroup: boolean
}

export interface AwardDto {
  id: string
  count: number
  award: Award
}

export const hustlerProgrammeSchema = Joi.object({
  startedAt: Joi.number().optional().allow(null),
  stoppedAt: Joi.number().optional().allow(null),
  terminatedAt: Joi.number().optional().allow(null),
}).options({ allowUnknown: true })

export interface FavWorkout {
  id: string
  workout: Workout
  addedAt: number
}

export type ProgrammeWorkoutDuration = {
  id: string
  name: string
  label: string
  durationValue: number
  durationUnit: string
  filterIconPublicFileId: string
  filterActiveIconPublicFileId: string
  isExpressDuration?: boolean
  isFilterable?: boolean
}

export type RunningSeries = {
  id: string
  createdAt: string
  updatedAt: string
  isAvailable: boolean
  title: string
  subTitle: string
  description: string
  coverImagePublicFileId: string
  thumbnailImagePublicFileId: string
}

export type EducationHubCategory = {
  id: string
  createdAt: string
  updatedAt: string
  title: string
  order: number
  workouts: Workout[]
}

export type RunningSeriesWithWorkouts = RunningSeries & { workouts: Workout[] }

export interface WorkoutStats {
  completedWorkoutsCount: number
  awardsCount: number
  totalHustle: number
  totalCalories: number
}

export interface NotCompletedChallenge {
  category: ChallengeCategory
  challenges: {
    challenge: Challenge
    progress: number
  }[]
}

export type WorkoutDurationType = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  label: string
  order: number
  durationValue: number
  durationUnit: string
  filterIconPublicFileId: string
  filterActiveIconPublicFileId: string
  isExpressDuration: boolean
  isFilterable: boolean
}

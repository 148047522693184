import { SessionContext } from '../../context/session-context'
import { coreService } from '../../service/core-service'
import style from './training-series-overview.module.scss'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ImagePublicFileId } from 'src/component/image/image-public-file-id'
import { PageContainer } from 'src/component/page-container/page-container'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'
import { WorkoutCard } from 'src/component/programmes/programme-workout-card'
import { WorkoutPopup } from 'src/component/programmes/programme-workout-popup'
import { RunningSeriesWithWorkouts, Workout } from 'src/dao/core-dao'

export const TrainingSeriesOverviewScreen = (): React.ReactElement => {
  const { completedWorkoutIds } = useContext(SessionContext)
  const { trainingSeriesId } = useParams<{ trainingSeriesId: string }>()
  const [selectedTrainingSeries, setSelectedTrainingSeries] = useState<RunningSeriesWithWorkouts>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | undefined>(undefined)

  useEffect(() => {
    setIsLoading(true)
    coreService
      .getTrainingSeriesById(trainingSeriesId)
      .then((response) => {
        setSelectedTrainingSeries(response)
      })
      .then(() => setIsLoading(false))
  }, [trainingSeriesId])

  if (isLoading) return <p>Loading...</p>
  if (!selectedTrainingSeries) return <p>No training series to show</p>

  const workouts = selectedTrainingSeries.workouts

  return (
    <PageContainer>
      <Row className={`${style.trainingSeriesHeader} justify-content-between mx-auto my-3 w-100`}>
        <ImagePublicFileId
          fileId={selectedTrainingSeries.thumbnailImagePublicFileId}
          className={`${style.trainingSeriesImage} p-2 w-100`}
        />
        <div className={`${style.trainingSeriesHeaderContent} p-5 justify-content-between d-flex flex-column`}>
          <BackArrow />
          <div>
            <h1>{selectedTrainingSeries.title}</h1>
            <p>{selectedTrainingSeries.subTitle}</p>
          </div>
        </div>
      </Row>
      <Row className={`justify-content-between mx-auto w-100 my-5 p-2`}>
        <p className={style.description}>{selectedTrainingSeries.description}</p>
      </Row>
      <Row className={`justify-content-between mx-auto w-100 mb-5 p-0`}>
        {selectedTrainingSeries &&
          workouts.map((w) => (
            <Col key={w.id} md={6} className="p-0">
              <div className={`d-flex justify-content-center p-2 ${style.workoutCard}`}>
                <WorkoutCard
                  workout={w}
                  isCompleted={completedWorkoutIds.has(w.id)}
                  isLocked={false}
                  selectWorkout={() => setSelectedWorkout(w)}
                />
              </div>
            </Col>
          ))}
        <WorkoutPopup
          setOnClose={() => setSelectedWorkout(undefined)}
          selectedWorkout={selectedWorkout}
          startWorkoutLink={selectedWorkout ? `/workouts/${selectedWorkout?.id}` : undefined}
        />
      </Row>
    </PageContainer>
  )
}

import styles from './awards-by-programme-list.module.scss'
import { ProgrammeAwardIcon } from './programme-award-icon'
import React, { useCallback, useEffect, useState } from 'react'
import { Award, AwardDto, coreDao } from 'src/dao/core-dao'

export const AwardsByProgrammeList = ({ programmeId }: { programmeId: string }): React.ReactElement => {
  const [allAvailableAwards, setAllAvailableAwards] = useState<Award[]>([])
  const [awardsByProgramme, setAwardsByProgramm] = useState<AwardDto[]>()

  useEffect(() => {
    coreDao.getAllAvailableProgrammeAwards({ programmeId }).then((response) => {
      setAllAvailableAwards(response)
    })

    coreDao.getMyProgrammeAwardsByProgramme(programmeId).then((response) => {
      setAwardsByProgramm(response)
    })
  }, [programmeId])

  const isAwardCompleted = useCallback(
    (award): boolean => {
      const awards = awardsByProgramme?.find((aw) => aw?.award?.name === award?.name)
      return !!awards
    },
    [awardsByProgramme],
  )

  return (
    <div className={styles.awardsList}>
      {allAvailableAwards.map((item) => (
        <ProgrammeAwardIcon key={item.id} award={item} isCompleted={isAwardCompleted(item)} />
      ))}
    </div>
  )
}

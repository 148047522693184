import { Price } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import styles from './profile-choose-plan.module.scss'
import { browserUtil } from '@grndhouse/react-common'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'

export const ProfileChoosePlan = (): React.ReactElement => {
  const [prices, setPrices] = useState<Price[]>()

  useEffect(() => {
    coreService.getPrices().then((result) => setPrices(result))
  }, [])

  const renderPrice = (price: Price): string => {
    const formatter = new Intl.NumberFormat('en-UK', {
      style: 'currency',
      currency: price.currency,
    })
    return formatter.format(price.amount ?? 0)
  }

  return (
    <Row className="mb-5">
      {prices &&
        prices.map((price) => {
          return (
            <Col key={price.id} sm={12} md={6} lg={5}>
              <Card className={`${styles.container} border-0 mt-5`}>
                <img src={price?.product.imageUrl} className={styles.membershipCardImage} />
                <Card.Body>
                  <h3>{price?.product.name}</h3>
                  <h4>
                    {renderPrice(price)} per{' '}
                    {price.recurring?.intervalCount && price.recurring?.intervalCount > 1 ? price.recurring?.intervalCount : ''}{' '}
                    {price.recurring?.interval}
                    <br />
                  </h4>
                  <div
                    className={`${styles.membershipCardBtn} mt-5`}
                    onClick={async () => {
                      eventBusUtil().emit({
                        action: EventBusActionType.CREATE,
                        entity: EventBusEntityType.CHECKOUT,
                        meta: { price },
                      })
                      browserUtil.redirect(await coreService.createCheckoutSession(price.id))
                    }}
                  >
                    {price.product?.trialDurationDays === 0 ? 'START NOW' : 'FREE TRIAL'}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

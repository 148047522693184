import { Price } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import style from './choose-plan.module.scss'
import { browserUtil } from '@grndhouse/react-common'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'

export const ChoosePlan = (): React.ReactElement => {
  const [prices, setPrices] = useState<Price[]>([])

  useEffect(() => {
    coreService.getPrices().then((result) => setPrices(result))
  }, [])

  const renderPrice = (price: Price): string => {
    const formatter = new Intl.NumberFormat('en-UK', {
      style: 'currency',
      currency: price.currency,
    })

    return formatter.format(price.amount ?? 0)
  }
  return (
    <Row className={style.container}>
      <Col>
        <h1>Choose plan</h1>
        <Row xs={1} md={2} className="g-4">
          {prices.map((price) => {
            return (
              <Col key={price.id}>
                <Card className={style.card}>
                  <Card.Img variant="top" src={price.product.imageUrl} />
                  <Card.Body>
                    <Card.Title>{price.product.name}</Card.Title>
                    <Card.Text>
                      {renderPrice(price)} per{' '}
                      {price.recurring?.intervalCount && price.recurring?.intervalCount > 1 ? price.recurring?.intervalCount : ''}{' '}
                      {price.recurring?.interval}
                      <br />
                    </Card.Text>
                    <Button
                      onClick={async () => {
                        eventBusUtil().emit({
                          action: EventBusActionType.CREATE,
                          entity: EventBusEntityType.CHECKOUT,
                          meta: { price },
                        })
                        browserUtil.redirect(await coreService.createCheckoutSession(price.id))
                      }}
                    >
                      {price.product?.trialDurationDays === 0 ? 'START NOW' : 'FREE TRIAL'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

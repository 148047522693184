import { ImagePublicFileId } from '../image/image-public-file-id'
import styles from './profile-your-awards-card.module.scss'
import React from 'react'
import { Col } from 'react-bootstrap'
import { Challenge } from 'src/dao/core-dao'

interface ProfileYourAwardsCardProps {
  challenge: Challenge
  progress?: number
  isStarted?: boolean
  isCompleted?: boolean
  selectChallenge?: (challenge: Challenge) => void
}

export const ProfileYourAwardsCard = ({
  challenge,
  progress,
  isStarted = false,
  isCompleted = false,
  selectChallenge,
}: ProfileYourAwardsCardProps): React.ReactElement => {
  return (
    <Col xs={12} sm={4} lg={3} xl={2}>
      <div className={styles.container} onClick={() => selectChallenge?.(challenge)}>
        <ImagePublicFileId
          fileId={challenge.imagePublicFileId}
          className={`${styles.awardImage} ${!isCompleted && styles.disabled}`}
        />
        <div>
          <h4>{challenge?.title}</h4>
          {isStarted && (
            <h5>
              {progress}/{challenge.criteria?.goal}
            </h5>
          )}
          {isCompleted && (
            <h5>
              {challenge.criteria?.goal}/{challenge.criteria?.goal}
            </h5>
          )}
        </div>
      </div>
    </Col>
  )
}

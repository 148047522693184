import { EventBusMessage } from '../util/event-bus-util'
import { logger } from '../util/logger'
import { coreService } from './core-service'

export enum MetaPixelMethod {
  TRACK = 'track',
  TRACK_CUSTOM = 'trackCustom',
}

export enum MetaPixelEvent {
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PURCHASE = 'Purchase',
  WORKOUT_VIDEO_START = 'WorkoutVideoStarted',
  WORKOUT_VIDEO_FINISH = 'WorkoutVideoFinished',
}

export type MetaPixelMessage = {
  methodName: MetaPixelMethod
  eventName?: MetaPixelEvent
  properties?: { [key: string]: string | number }
}

export const metaPixelService = {
  _push: (params: { metaPixelMessage: MetaPixelMessage }): void => {
    const { metaPixelMessage } = params
    const { methodName, eventName, properties } = metaPixelMessage
    const payload = [methodName, eventName, properties].filter(Boolean)
    window.fbq(...payload)
  },

  sendCheckoutInitiated: (_: { message: EventBusMessage }): void => {
    const metaPixelMessage = {
      methodName: MetaPixelMethod.TRACK,
      eventName: MetaPixelEvent.INITIATE_CHECKOUT,
    }
    metaPixelService._push({ metaPixelMessage })
  },

  sendSubscriptionSet: async (_: { message: EventBusMessage }): Promise<void> => {
    const subscriptionItems = await coreService.getMyActiveOrTrialingSubscriptionItems()
    const subscriptionItem = subscriptionItems[0]

    if (subscriptionItem) {
      const { amount, currency } = subscriptionItem.plan

      const metaPixelMessage = {
        methodName: MetaPixelMethod.TRACK,
        eventName: MetaPixelEvent.PURCHASE,
        properties: { currency: currency, value: amount },
      }
      metaPixelService._push({ metaPixelMessage })
    }
  },

  sendVideoPlaybackStarted: (_: { message: EventBusMessage }): void => {
    const metaPixelMessage = {
      methodName: MetaPixelMethod.TRACK_CUSTOM,
      eventName: MetaPixelEvent.WORKOUT_VIDEO_START,
    }
    metaPixelService._push({ metaPixelMessage })
  },

  sendVideoPlaybackEnded: (_: { message: EventBusMessage }): void => {
    const metaPixelMessage = {
      methodName: MetaPixelMethod.TRACK_CUSTOM,
      eventName: MetaPixelEvent.WORKOUT_VIDEO_FINISH,
    }
    metaPixelService._push({ metaPixelMessage })
  },
}

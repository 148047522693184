import { ImagePublicFileId } from '../image/image-public-file-id'
import style from './trainers-section.module.scss'
import { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SessionContext } from 'src/context/session-context'
import { Trainer } from 'src/dao/core-dao'

const TrainerCard = ({ trainer }: { trainer: Trainer }): React.ReactElement => {
  return (
    <Link to={`/trainers/${trainer.id}`} className={style.trainerCard}>
      <ImagePublicFileId fileId={trainer.mediumProfilePhotoPublicFileId} className={style.trainerImage} />
      <h5 className={style.trainerName}>{trainer.firstName}</h5>
    </Link>
  )
}

export const TrainersSection = (): React.ReactElement => {
  const { trainers } = useContext(SessionContext)

  return (
    <Container className={style.trainersSectionContainer}>
      <Row className="d-flex justify-content-center align-items-center">
        <h2 className={style.title}>Grndhouse trainers</h2>
      </Row>
      <Row>
        {trainers &&
          trainers.map((trainer, index) => (
            <Col key={index} xs={12} sm={6} md={4} className={`d-flex justify-content-center px-2 py-3 ${style.largeScreenCol}`}>
              <TrainerCard trainer={trainer} />
            </Col>
          ))}
      </Row>
    </Container>
  )
}

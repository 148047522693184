import { StringLocalStorage, localStorageService } from '../local-storage-service'

export const refreshTokenService = {
  store: (token: string): void => {
    localStorageService.setString(StringLocalStorage.REFRESH_TOKEN, token)
  },
  clear: (): void => {
    localStorageService.remove(StringLocalStorage.REFRESH_TOKEN)
  },
  get: (): string => {
    return localStorageService.getString(StringLocalStorage.REFRESH_TOKEN)
  },
}

import styles from './programme-duration-timespan.module.scss'
import { ClockSvgIcon } from 'src/component/util/images'
import { ProgrammeDuration } from 'src/dao/core-dao'

interface ProgrammeDurationTimespanProps {
  duration: ProgrammeDuration | undefined
}

export const ProgrammeDurationTimespan = ({ duration }: ProgrammeDurationTimespanProps): React.ReactElement => {
  const renderDurationSpanText = (): string => {
    const shortest = duration?.classes.shortestDuration
    const longest = duration?.classes.longestDuration

    return `${shortest}-${longest} min workouts`
  }

  return (
    <>
      <div className={styles.container}>
        <ClockSvgIcon width={15} height={14} />
        {renderDurationSpanText()}
      </div>
    </>
  )
}

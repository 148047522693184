import { authDao } from '../dao/auth-dao'
import { jwtService } from '@grndhouse/react-common/lib/service/jwt-service'
import { accessTokenService } from '@grndhouse/react-common/lib/service/token/access-token-service'

export const authService = {
  deactivateOwnAccount: async (): Promise<void> => {
    return authDao.deactivateOwnAccount()
  },
  changePassword: async (params: { password: string; newPassword: string }): Promise<void> => {
    return authDao.changePassword(params)
  },
  hasUserAcceptedLegalAgreements: (): boolean => {
    try {
      const accessToken = accessTokenService.get()
      const jwtPayload = jwtService.decode(accessToken)
      return jwtPayload.meta.legalAgreementVerified
    } catch (err) {
      return false
    }
  },
}

import { EquipmentPiece, WorkoutEquipment, WorkoutIntensity } from '../dao/core-dao'

export const renderUtil = {
  renderIntensity: (intensity: WorkoutIntensity): string => {
    switch (intensity) {
      case WorkoutIntensity.LOW:
        return 'Low'
      case WorkoutIntensity.MEDIUM:
        return 'Medium'
      case WorkoutIntensity.HIGH:
        return 'High'
      default:
        return ''
    }
  },
  renderEquipmentPiece: (piece: EquipmentPiece): string => {
    switch (piece) {
      case EquipmentPiece.BOOTY_BAND:
        return 'Booty Band'
      case EquipmentPiece.RESISTANCE_BAND:
        return 'Resistance Band'
      case EquipmentPiece.DUMBBELL:
        return 'Dumbbell'
      case EquipmentPiece.LIGHT_DUMBBELL:
        return 'Light Dumbbell'
      case EquipmentPiece.LIGHT_MEDIUM_DUMBBELL:
        return 'Light/Medium Dumbbell'
      case EquipmentPiece.MEDIUM_DUMBBELL:
        return 'Medium Dumbbell'
      case EquipmentPiece.HEAVY_DUMBBELL:
        return 'Heavy Dumbbell'
      case EquipmentPiece.LIGHT_MEDIUM_HEAVY_DUMBBELLS:
        return 'Light/Medium/Heavy Dumbbells'
      case EquipmentPiece.MEDIUM_HEAVY_DUMBBELL:
        return 'Medium/Heavy Dumbbell'
      default:
        return ''
    }
  },
  renderEquipment: (equipment: WorkoutEquipment[]): string => {
    return (
      equipment
        .filter((e) => !!e.equipmentPieceId)
        .map((e) => `${e.quantity} x ${renderUtil.renderEquipmentPiece(e.piece)}`)
        .join(', ') || 'No equipment required'
    )
  },
}

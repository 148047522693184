import { coreService } from '../service/core-service'
import { browserUtil } from '@grndhouse/react-common/lib'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'

export const CheckoutScreen = (): React.ReactElement => {
  const location = useLocation()
  useEffect(() => {
    const priceIdFromUrl = new URLSearchParams(location.search).get('priceId')
    const priceId$ = priceIdFromUrl ? Promise.resolve(priceIdFromUrl) : coreService.getPrices().then((prices) => prices[0].id)
    priceId$.then((priceId) => coreService.createCheckoutSession(priceId)).then((url) => browserUtil.redirect(url))
  }, [location.search])
  return (
    <PageContainer>
      <h2>Please wait while we redirect you to the checkout page</h2>
    </PageContainer>
  )
}

import style from './main-nav-item.module.scss'
import React, { ReactNode } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export enum PathNameMatchType {
  EXACT = 'exact',
  STARTS_WITH = 'starts-with',
}

export enum NavItemVariant {
  DEFAULT = 'default',
  SUB_MENU = 'subMenu',
}

export const MainNavItem = (props: {
  path: string
  currentPath?: string | null
  title: string
  icon: React.ReactElement
  machType?: PathNameMatchType
  children?: ReactNode | ReactNode[]
  variant?: NavItemVariant
  onClick?: () => void
}): React.ReactElement => {
  const {
    title,
    icon,
    currentPath,
    path,
    machType = PathNameMatchType.STARTS_WITH,
    children,
    variant = NavItemVariant.DEFAULT,
    onClick,
  } = props

  const isPathMach = (): boolean => {
    switch (machType) {
      case PathNameMatchType.EXACT:
        return currentPath === path
      case PathNameMatchType.STARTS_WITH:
        return !!currentPath?.startsWith(path)
      default:
        return false
    }
  }

  const renderItemIcon = (): React.ReactElement => {
    return (
      <div className={`${style.container} ${style[variant]} ${isPathMach() ? style.active : ''}`}>
        <div className={style.itemImage}>{icon}</div>
        <div className={style.title}>{title}</div>
      </div>
    )
  }

  if (children) {
    return (
      <NavDropdown
        id="profile-drop-down"
        className={`${style.navDropDown}`}
        title={<div>{renderItemIcon()}</div>}
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        {children}
      </NavDropdown>
    )
  }

  return (
    <Nav.Link
      as={Link}
      to={path}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      {renderItemIcon()}
    </Nav.Link>
  )
}

import educationImage from '../../asset/image/louis-barbell-grnd-101.jpg'
import style from './workout-screen.module.scss'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { RunningSeriesCard } from 'src/component/running-series-list/running-series-card'
import { WorkoutThemedSection } from 'src/component/workout-themed-section/workout-themed-section'
import { RunningSeries, WorkoutSection } from 'src/dao/core-dao'
import { coreService } from 'src/service/core-service'

const RunningSeriesSection = (): React.ReactElement => {
  const [trainingSeries, setTrainingSeries] = useState<RunningSeries[]>([])
  useEffect(() => {
    coreService.listRunningSeries().then((response) => {
      setTrainingSeries(response)
    })
  }, [])
  const cardsInRow = trainingSeries.length % 3 === 0 ? 3 : 2

  return (
    <Container className={style.trainingSeriesContainer}>
      <Row noGutters={true}>
        <Col className="d-flex justify-content-center align-items-center">
          <h2 className={style.trainingSeriesTitle}>RUNNING SERIES</h2>
        </Col>
      </Row>
      <Row className="mx-n1">
        {trainingSeries.map((t) => (
          <Col key={t.id} md={12 / cardsInRow} className="px-1 py-2 py-lg-4">
            <RunningSeriesCard runningSeries={t} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export const WorkoutListScreen = (): React.ReactElement => {
  const [workoutSections, setWorkoutSections] = useState<WorkoutSection[]>([])

  useEffect(() => {
    coreService.listWorkoutSections().then((response) => {
      setWorkoutSections(response)
    })
  }, [])

  return (
    <div>
      {!workoutSections && <p className={style.noWorkoutStyles}>Loading...</p>}
      {workoutSections.map((workoutSection, idx) => (
        <div key={workoutSection.id} className={idx % 2 === 0 ? style.darkBg : style.whiteBg}>
          <WorkoutThemedSection
            theme={idx % 2 === 0 ? 'dark' : 'light'}
            label={workoutSection.label}
            seeAllLink={`/workouts/section/${workoutSection.id}`}
            workoutFilter={{ sections: [workoutSection.id] }}
          />
        </div>
      ))}
      <div>
        <RunningSeriesSection />
      </div>
      <div>
        <Container className={`${style.educationContainer} my-5`}>
          <Row noGutters={true}>
            <Col>
              <Image src={educationImage} className={style.educationImage} />
              <div className={`${style.educationContent} py-5`}>
                <div />
                <div className="d-flex flex-column align-items-center justify-content-end pt-5">
                  <h2>EDUCATION GRND 101</h2>
                  <Link to="/education">
                    <Button variant="outline-secondary" className={style.educationButton}>
                      See All Workouts
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

import { ProgrammeDayComplete } from '../util/images'
import styles from './programme-week-pill.module.scss'
import React from 'react'

type ProgrammeWeekPillProps = {
  order: number
  isCompleted?: boolean
}

export const ProgrammeWeekPill = ({ order, isCompleted }: ProgrammeWeekPillProps): React.ReactElement => {
  return (
    <div className={`${styles.container} ${!isCompleted && styles.notCompleted}`}>
      <span>{order}</span>
      {isCompleted && <ProgrammeDayComplete />}
    </div>
  )
}

import { Observable, Subject } from 'rxjs'

export class EventBus<T> {
  protected readonly _eventSubject = new Subject<T>()

  public emit(message: T): void {
    this._eventSubject.next(message)
  }

  public get Observable(): Observable<T> {
    return this._eventSubject
  }
}

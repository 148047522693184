import ImagePlaceholder from '../../asset/image/image-placeholder.png'
import { fileService } from '../../service/file-service'
import React, { useState } from 'react'
import { Image, ImageProps } from 'react-bootstrap'

export const ImagePublicFileId = (
  props: { fileId: string; alt?: string; className?: string } & ImageProps,
): React.ReactElement => {
  const { alt, fileId, className, ...imageProps } = props

  const [isImageLoading, setIsImageLoading] = useState(true)

  return (
    <>
      {isImageLoading && <Image src={ImagePlaceholder} alt={alt ?? ''} className={className} {...imageProps} />}
      <Image
        src={fileService.publicUrlFromFileId(fileId)}
        alt={alt ?? ''}
        onLoad={() => {
          console.log('image loaded', fileId) // eslint-disable-line no-console
          setIsImageLoading(false)
        }}
        className={className}
        {...imageProps}
        style={{ ...(isImageLoading && { display: 'none' }) }}
      />
    </>
  )
}

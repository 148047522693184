import { Subscription } from '../dao/core-dao'
import { ReactElement, useEffect, useMemo } from 'react'

export const ConversionTrackingTag = ({ subscription }: { subscription: Subscription }): ReactElement => {
  useScript(subscription)
  return <></>
}

function useScript(subscription: Subscription): void {
  const {
    id: subId,
    items: [
      {
        plan: { amount, currency },
      },
    ],
  } = subscription
  const imageEl = useMemo(() => {
    const el = document.createElement('img')
    el.setAttribute(
      'src',
      `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=80431&amount=${amount}&cr=${currency.toUpperCase()}&ref=${subId}&ch=aw&parts=DEFAULT:${amount}&vc=&customeracquisition=NEW`,
    )
    el.setAttribute('border', '0')
    el.setAttribute('width', '0')
    el.setAttribute('height', '0')
    return el
  }, [subId, amount, currency])

  const scriptEl = useMemo(() => {
    const el = document.createElement('script')
    el.setAttribute('type', 'text/javascript')
    el.innerHTML = scriptInnerHtml(subId, amount, currency)
    return el
  }, [subId, amount, currency])

  useEffect(() => {
    const image = imageEl
    const scr = scriptEl
    document.body.appendChild(image)
    document.body.appendChild(scr)

    return () => {
      document.body.removeChild(image)
      document.body.removeChild(scr)
    }
  }, [imageEl, scriptEl])
}

const scriptInnerHtml = (subId: string, amount: number, currency: string): string => `
if (typeof AWIN != "undefined" && typeof AWIN.Tracking != "undefined") {
    AWIN.Tracking.Sale = {};
    AWIN.Tracking.Sale.amount = "${amount}";
    AWIN.Tracking.Sale.channel = "aw";
    AWIN.Tracking.Sale.orderRef = "${subId}";
    AWIN.Tracking.Sale.parts = "DEFAULT:${amount}";
    AWIN.Tracking.Sale.currency = "${currency.toUpperCase()}";
    AWIN.Tracking.Sale.voucher = "";
    AWIN.Tracking.Sale.customerAcquisition = "NEW";
    AWIN.Tracking.Sale.test = "0";
    AWIN.Tracking.run();
}
`

import axios, { AxiosInstance } from 'axios'

export type InterceptorFunction = (axios: AxiosInstance) => void

export type AxiosDaoFactoryParams = {
  baseURL: string
  interceptors?: InterceptorFunction[]
}

export const axiosDaoFactory = ({ baseURL, interceptors }: AxiosDaoFactoryParams): AxiosInstance => {
  const api = axios.create({
    baseURL,
    responseType: 'json',
  })
  if (interceptors) interceptors.forEach((i) => i(api))
  return api
}

import { SessionContext } from '../../context/session-context'
import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import { ChoosePlan } from './choose-plan'
import style from './choose-plan-modal.module.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Modal, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ChoosePlanModal = (): React.ReactElement => {
  const { showSubscriptionModal } = useContext(SessionContext)
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(showSubscriptionModal)
    if (showSubscriptionModal) {
      eventBusUtil().emit({
        action: EventBusActionType.CREATE,
        entity: EventBusEntityType.PAYWALL,
        meta: {},
      })
    }
  }, [showSubscriptionModal])

  return (
    <Modal backdrop="static" contentClassName={style.modal} show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <ChoosePlan />
      </Modal.Body>
      <Modal.Footer className={style.footer}>
        <Nav.Link as={Link} className={style.logoutBtn} to={'/sign-out'}>
          Log out
        </Nav.Link>
      </Modal.Footer>
    </Modal>
  )
}

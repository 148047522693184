import { ProgrammeWorkout } from 'src/dao/core-dao'

export const selectedWeekWorkouts = (
  selectedWeek: { order: number },
  programmeWorkouts: ProgrammeWorkout[],
): ProgrammeWorkout[] => {
  if (!programmeWorkouts) return []
  return programmeWorkouts
    .filter((w) => w.onWeek === selectedWeek.order)
    .sort((aWorkout, bWorkout) => aWorkout.order - bWorkout.order)
}

export const browserUtil = {
  location: (): string => window.location.toString(),
  redirect: (redirectUrl: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = redirectUrl
  },
  _urlToFile: (url: string, filename: string): Promise<File> =>
    fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename)),
  downloadBase64File: ({ base64, mimeType, fileName }: { base64: string; mimeType: string; fileName: string }): void => {
    const fileUrl = `data:${mimeType};base64,${base64}`
    browserUtil._urlToFile(fileUrl, fileName).then((file) => {
      const blob = new Blob([file], { type: mimeType })
      const blobURL = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobURL
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  },
}

import React from 'react'
import { Container } from 'react-bootstrap'
import { TermsAndConditions } from 'src/component/legal/terms-and-conditions'
import { BackArrow } from 'src/component/programmes/programme-back-arrow'

export const TermsConditionsScreen = (): React.ReactElement => {
  return (
    <Container className="py-5">
      <BackArrow link="/profile/personal" />
      <br />
      <br />
      <TermsAndConditions />
    </Container>
  )
}

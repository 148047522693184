import CheckIcon from '../../asset/image/workout-detail-action/check-icon.png'
import CheckedIcon from '../../asset/image/workout-detail-action/checked-icon.png'
import { Workout } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { logger } from '../../util/logger'
import { MusicSvgIcon } from '../util/images'
import style from './workout-detail-action.module.scss'
import React, { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

export type WorkoutDetailActionRefObject = {
  setWorkoutCompleted: () => void
}

export const WorkoutDetailAction = forwardRef(
  (props: { workout: Workout }, ref: Ref<WorkoutDetailActionRefObject>): React.ReactElement => {
    const { workout } = props
    const [isCompletedWorkout, setIsCompletedWorkout] = useState(false)

    useImperativeHandle(ref, () => ({
      setWorkoutCompleted: (): void => setIsCompletedWorkout(true),
    }))

    const fetchCompletedWorkoutStatus = useCallback(async () => {
      coreService
        .getCompletedWorkouts({
          filter: {
            workoutIds: [workout.id],
          },
        })
        .then((result) => {
          setIsCompletedWorkout(result.length > 0)
        })
    }, [])

    useEffect(() => {
      fetchCompletedWorkoutStatus().catch((err) => logger.error(err))
    }, [workout])

    return (
      <Row className={style.container}>
        <Col>
          {!isCompletedWorkout ? (
            <img className={style.icons} src={CheckIcon} />
          ) : (
            <img className={style.icons} src={CheckedIcon} />
          )}
        </Col>
        {/* <Col>
          <AddToCalendarSvgIcon />
        </Col> */}
        <Col>
          <a href={props.workout.playlistUrl} target={'_blank'} rel="noreferrer">
            <MusicSvgIcon />
          </a>
        </Col>
      </Row>
    )
  },
)

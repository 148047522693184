import { TrainerWorkoutList } from '../../component/trainer/trainer-workout-list'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from 'src/component/page-container/page-container'

export const TrainerWorkoutsScreen = (): React.ReactElement => {
  const { trainerId } = useParams<{ trainerId: string }>()

  return (
    <PageContainer>
      <TrainerWorkoutList trainerId={trainerId} />
    </PageContainer>
  )
}

import { Hustler, StoreSubscription, Subscription } from '../../dao/core-dao'
import { coreService } from '../../service/core-service'
import { EventBusActionType, EventBusEntityType, eventBusUtil } from '../../util/event-bus-util'
import { ConversionTrackingTag } from '../conversion-tracking-tag'
import { LoadingSpinner } from '../util/spinner/loading-spinner'
import { ManageBilling } from './manage-billing'
import styles from './profile-active-subscription.module.scss'
import { ProfileChoosePlan } from './profile-choose-plan'
import { StoreSubscriptionInfo } from './store-subscription-info'
import { SubscriptionList } from './subscription-list'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const ProfileActiveSubscription = ({ me }: { me?: Hustler }): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [storeSubscription, setStoreSubscription] = useState<StoreSubscription>()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const { membershipStatus } = useParams<{ membershipStatus: 'success' | 'cancel' }>()

  useEffect(() => {
    setIsLoading(true)
    coreService
      .getMySubscriptions()
      .then((response) => {
        if (response) {
          setStoreSubscription(response)
          coreService.getStripeSubscriptions().then((subs) => setSubscriptions(subs))
        }
      })
      .finally(() => setIsLoading(false))
  }, [me])

  useEffect(() => {
    if (membershipStatus === 'success') {
      // TODO check if we need to trigger user-me.create here, looking at the code `me` is not being changed
      eventBusUtil().emit({
        action: EventBusActionType.CREATE,
        entity: EventBusEntityType.SUBSCRIPTION,
        meta: { user: me },
      })
    }
  }, [me, membershipStatus])

  const hasSubscription = useMemo((): boolean => {
    return subscriptions.length > 0
  }, [subscriptions])

  const activeSubscription = subscriptions.find((s) => s.status === 'active' || s.status === 'trialing')
  return (
    <LoadingSpinner isLoading={isLoading}>
      {(hasSubscription || storeSubscription?.store === 'stripe') && (
        <>
          <h2 className={styles.title}>Your subscriptions</h2>
          <SubscriptionList mySubscriptions={subscriptions} />
          <hr />
          <ManageBilling />
        </>
      )}
      {storeSubscription && storeSubscription?.store !== 'stripe' && <StoreSubscriptionInfo store={storeSubscription?.store} />}
      {!hasSubscription && !storeSubscription && (
        <>
          <p>No active subscription, choose a plan below</p>
          <hr />
          <ProfileChoosePlan />
        </>
      )}
      {membershipStatus === 'success' && activeSubscription !== undefined ? (
        <ConversionTrackingTag subscription={activeSubscription} />
      ) : null}
    </LoadingSpinner>
  )
}

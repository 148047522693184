import { Workout } from '../../dao/core-dao'
import { WorkoutDuration } from '../../dao/core-dao-v1'
import { renderUtil } from '../../util/render-util'
import { WorkoutEquipmentSvgIcon } from '../util/images'
import { DurationIcon } from '../workout-filter/duration-icon'
import { IntensityIcon } from '../workout-filter/intensity-icon'
import styles from './workout-detail-info.module.scss'
import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { SessionContext } from 'src/context/session-context'

export const WorkoutDetailInfo = ({ workout }: { workout: Workout }): React.ReactElement => {
  const { renderDuration, renderEquipmentPieces } = useContext(SessionContext)
  const duration = renderDuration(workout.durationId)
  const equipment = renderEquipmentPieces(workout.equipment)

  return (
    <Row className="py-3">
      <Col xs={12} sm={6} md={4} className={styles.container}>
        <DurationIcon duration={workout.duration! as unknown as WorkoutDuration} active={false} />
        <Col className="pl-0">
          <h3 className={styles.title}>Duration</h3>
          <h4 className={styles.value}>{duration}</h4>
        </Col>
      </Col>
      <Col xs={12} sm={6} md={4} className={styles.container}>
        <WorkoutEquipmentSvgIcon />
        <Col className="pl-0">
          <h3 className={styles.title}>Equipment</h3>
          <h4 className={styles.value}>{equipment}</h4>
        </Col>
      </Col>
      <Col xs={12} sm={6} md={4} className={styles.container}>
        <IntensityIcon intensity={workout.intensity} active={false} />
        <Col className="pl-0">
          <h3 className={styles.title}>Intensity</h3>
          <h4 className={styles.value}>{renderUtil.renderIntensity(workout.intensity)}</h4>
        </Col>
      </Col>
    </Row>
  )
}

import { WorkoutFilter as WorkoutFilterDto } from '../service/core-service'
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

export type WorkoutFilterAttributes = Pick<
  WorkoutFilterDto,
  'bodyParts' | 'equipment' | 'intensities' | 'trainerIds' | 'types' | 'durations' | 'styles' | 'sections'
>
export type FilterData = {
  workoutFilter?: WorkoutFilterAttributes
  submittedWorkoutFilter?: WorkoutFilterAttributes
  submittedFilterCount: number
  pendingFilterCount: number
  isSidebarOpen: boolean
  setIsSidebarOpen: (isSidebarOpen: boolean) => void
  submitRedirectPath: string
  toggleIsSidebarOpen: () => void
  submit: () => void
  reset: () => void
  clear: (clearSubmitted: boolean) => void
  toggleFilterEntry: (entryKey: keyof WorkoutFilterAttributes, value: string) => void
  setFilterEntry: (entryKey: keyof WorkoutFilterAttributes, values: string[]) => void
  subFilterData: SubFilterData | undefined
  setSubFilterData: (subFilter?: SubFilterData) => void
}

export const FilterContext = createContext<FilterData>({} as FilterData)

export function useFilterContext(): FilterData {
  return useContext(FilterContext)
}

export type SubFilterData = {
  filter: WorkoutFilterAttributes
  label: string
}
type FilterState = {
  workoutFilter?: WorkoutFilterAttributes
  submittedWorkoutFilter?: WorkoutFilterAttributes
  submittedFilterCount?: number
  redirectTo?: string
  subFilterData?: SubFilterData
  isSidebarOpen: boolean
}
type FilterAction =
  | { type: 'submit' }
  | { type: 'clear'; clearSubmitted: boolean }
  | { type: 'reset' }
  | { type: 'toggleEntry'; entryKey: keyof WorkoutFilterAttributes; value: string }
  | { type: 'setEntry'; entryKey: keyof WorkoutFilterAttributes; values: string[] }
  | { type: 'set-sub-filter'; subFilterData: SubFilterData | undefined }
  | { type: 'set-is-sidebar-open'; isSidebarOpen: boolean }
  | { type: 'toggle-is-sidebar-open' }
const filterReducer = (currentState: FilterState, action: FilterAction): FilterState => {
  const { isSidebarOpen, subFilterData, submittedWorkoutFilter, workoutFilter } = currentState
  switch (action.type) {
    case 'submit':
      return {
        ...currentState,
        submittedWorkoutFilter: { ...workoutFilter, ...(subFilterData ? subFilterData.filter : {}) },
        submittedFilterCount: filterCount(workoutFilter),
        isSidebarOpen: false,
      }
    case 'clear':
      return {
        ...currentState,
        workoutFilter: {},
        ...(action.clearSubmitted ? { submittedWorkoutFilter: {}, submittedFilterCount: 0, redirectTo: undefined } : {}),
      }
    case 'reset':
      return {
        ...currentState,
        workoutFilter: submittedWorkoutFilter,
      }
    case 'toggleEntry':
      return {
        ...currentState,
        workoutFilter: toggleEntry(workoutFilter, action.entryKey, action.value),
      }
    case 'setEntry':
      return {
        ...currentState,
        workoutFilter: {
          ...workoutFilter,
          [action.entryKey]: action.values,
        },
      }
    case 'set-sub-filter':
      return {
        ...currentState,
        subFilterData: action.subFilterData,
      }
    case 'set-is-sidebar-open':
      return {
        ...currentState,
        isSidebarOpen: action.isSidebarOpen,
      }
    case 'toggle-is-sidebar-open':
      return {
        ...currentState,
        isSidebarOpen: !isSidebarOpen,
      }
  }
}
const toggleEntry = (
  currentFilters: WorkoutFilterAttributes | undefined,
  entryKey: keyof WorkoutFilterAttributes,
  value: string,
): WorkoutFilterAttributes => {
  const currentForKey = currentFilters?.[entryKey] ?? []
  if (!currentFilters || !currentForKey.length) {
    return {
      ...currentFilters,
      [entryKey]: [value],
    }
  }
  const isValuePresent = currentForKey.includes(value)
  const filterAfterToggle = isValuePresent ? currentForKey.filter((entry) => entry !== value) : [...currentForKey, value]
  return {
    ...currentFilters,
    [entryKey]: filterAfterToggle,
  }
}
export const FilterContextProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [{ workoutFilter, submittedWorkoutFilter, submittedFilterCount = 0, subFilterData, isSidebarOpen }, dispatch] =
    useReducer(filterReducer, { isSidebarOpen: false })
  const submitRedirectPath = `/workouts-filter-result${getResultsQueryParams(subFilterData)}`

  useEffect(() => {
    if (!isSidebarOpen) {
      dispatch({ type: 'reset' })
    }
  }, [isSidebarOpen, submittedWorkoutFilter])
  const { clear, submit, reset, setFilterEntry, toggleFilterEntry, setIsSidebarOpen, toggleIsSidebarOpen, setSubFilterData } =
    useMemo(() => {
      return {
        clear: (clearSubmitted: boolean) => {
          dispatch({ type: 'clear', clearSubmitted })
        },
        toggleFilterEntry: (entryKey: keyof WorkoutFilterAttributes, value: string) => {
          dispatch({ type: 'toggleEntry', entryKey, value })
        },
        setFilterEntry: (entryKey: keyof WorkoutFilterAttributes, values: string[]) => {
          dispatch({ type: 'setEntry', entryKey, values })
        },
        reset: () => {
          dispatch({ type: 'reset' })
        },
        submit: () => {
          dispatch({ type: 'submit' })
        },
        setIsSidebarOpen: (isSidebarOpen: boolean) => {
          dispatch({ type: 'set-is-sidebar-open', isSidebarOpen })
        },
        toggleIsSidebarOpen: () => {
          dispatch({ type: 'toggle-is-sidebar-open' })
        },
        setSubFilterData: (subFilterData: SubFilterData | undefined) => {
          dispatch({ type: 'set-sub-filter', subFilterData })
        },
      }
    }, [dispatch])

  const pendingFilterCount = filterCount(workoutFilter)

  return (
    <FilterContext.Provider
      value={{
        workoutFilter,
        toggleFilterEntry,
        setFilterEntry,
        submittedWorkoutFilter,
        submittedFilterCount,
        pendingFilterCount,
        isSidebarOpen,
        submitRedirectPath,
        setIsSidebarOpen,
        toggleIsSidebarOpen,
        submit,
        clear,
        reset,
        subFilterData,
        setSubFilterData,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
const filterCount = (filter: WorkoutFilterAttributes | undefined): number =>
  Object.values(filter ?? {}).filter((filterArray) => filterArray.length > 0).length
const getResultsQueryParams = (subFilterData: SubFilterData | undefined): string => {
  if (!subFilterData) return ''
  const { label } = subFilterData
  const query = new URLSearchParams()
  query.set('label', label)
  return `?${query.toString()}`
}
